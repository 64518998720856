import DefaultThumbnail from '../../assets/default-category-thumbnail.png';
import * as Style from './styles';

interface CategoryDefaultThumbnailProps {
  title: string;
}

const CategoryDefaultThumbnail: React.FC<CategoryDefaultThumbnailProps> = ({
  title,
}) => {
  return (
    <Style.Container>
      <img src={DefaultThumbnail} alt={title} />
      <p>{title}</p>
    </Style.Container>
  );
};

export default CategoryDefaultThumbnail;
