import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  :root {
    --background: var(--neutralLight20);
    --black: #1D1D1B;
    --gray: #878787;
    --purple: #31348B;
    --darkPurple: #131263;
    --blue: #1072B9;
    --altBlue: #0173B5;
    --lightBlue: #31ADE4;
    --button: #5075CD;
    --yellow: #FCC201;
    --green: #ACC32B;
    --red: #E52629;
    --inputForm: rgba(155, 153, 175, 0.31);
    --neutralLight10: #FAFBFC;
    --neutralLight20: #F0F3F6;
    --neutralLight30: #E5E8EB;
    --neutralLight40: #D3D8DD;
    --neutralMid80: #97A0AF;
    --neutralMid400: #505F79;
    --neutralDark500: #42526E;
    --neutralDark800: #172B4D;
    --neutralDark900: #061938;
    --blueGradient: radial-gradient(57.45% 101.51% at 50% 50%, #131263 0%, #0C0C33 100%);
    --purpleGradient: linear-gradient(180deg, #31348B 0%, #2D1B5A 100%);
    --text: #444444;
    --white: #ffffff;
    --white65: #ffffffa6;
    --purple10: #31348b1a;
    --purple91: #31348be8;
    --purple98: #31348bfa;
    --gray15: #87878726;
    --gray25: #87878740;
    --gray50: #87878780;
    --dark100:#0E0E0E;
  }

  *, *:before, *:after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  html {
    font-size: 62.5%;
    box-sizing: border-box;
  }

  #root {
    display: flex;
    flex-direction: column;
  }

  html, body, #root {
  }
  
  /* Make the background fill the entire screen */
  #root > div {
    min-height: 100vh;
  }

  #root > div > div {
    z-index: 9;
  }

  body {
    overflow-x: hidden;
    font-family: 'Roboto', sans-serif;
    color: var(--text);
  }

  label, button, input, textarea {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
  }

  ul {
    list-style: none;
  }

  & .swal2-popup {
    font-size: 1.75rem;
  }

  @media (max-width: 595px) {
   .swal2-popup  {
      width: 26rem;
      font-size: 1.40rem;
    }
  }
`;
