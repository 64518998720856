import styled, { css } from 'styled-components';

interface UpperContentProps {
  opened: boolean;
}

interface CollapseIndicatorContainerProps {
  opened: boolean;
}

interface ContainerProps {
  opened: boolean;
  showMore: boolean;
  isFlex: boolean;
}

export const Container = styled.div<ContainerProps>`
  margin-bottom: 10px;
  position: relative;

  ${({ opened }) =>
    opened &&
    css`
      flex: 1;
      overflow: hidden;
    `}

  flex: ${({ isFlex, opened }) => (isFlex && opened ? 1 : 'none')};

  ${({ opened, showMore }) =>
    opened &&
    showMore &&
    css`
      flex: none;
    `}
`;

export const SeeAll = styled.button`
  position: absolute;
  bottom: 0;
  z-index: 9;
  height: 80px;
  border: none;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(12, 12, 51, 0.07) 0%,
    rgba(12, 12, 51, 0.91) 54.48%,
    #0c0c33 91.46%
  );

  font-size: 16px;
  letter-spacing: 0.5px;
  color: var(--lightBlue);
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  transition: all ease 0.2s;
  padding: 16px;

  :hover {
    text-decoration: underline;
  }
`;

export const LowerContent = styled.div`
  background: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const UpperContent = styled.div<UpperContentProps>`
  width: 100%;
  padding: 14px 16px;
  background: var(--purple);
  color: white;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: var(--blue);
    }
  }
  ${({ opened }) =>
    opened &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}
`;

export const CollapseIndicatorContainer = styled.div<CollapseIndicatorContainerProps>`
  font-size: 2.5rem;
  transition: all 0.3s;
  transform: ${({ opened }) => (opened ? 'rotate(180deg)' : 'rotate(0deg)')};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Duration = styled.div`
  font-size: 1.6rem;
  margin: 0 10px;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    line-height: 16px;
    letter-spacing: 0.4px;
  }
`;

export const Title = styled.h4`
  font-size: 2rem;
  font-weight: 700;
  line-height: 24px;
  flex: 1;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    line-height: 16px;
    letter-spacing: 0.4px;
  }
`;

export const ContainerStartButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 18px 16px 20px;
  background: #0c0c33;
  cursor: pointer;
  min-height: 60px;
`;

export const StartButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px 0;
  width: 100%;
  border-radius: 5px;
  background: var(--blue);
  border: none;
  outline: none;
  font-size: 1.8rem;
  color: white;

  & > span {
    margin-right: 5px;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: var(--purple);
    }
  }

  @media (max-width: 768px) {
    font-size: 1.4rem;
    line-height: 16px;
    letter-spacing: 1.25px;
  }
`;

export const StartButtonContainer = styled.div`
  width: 100%;
  padding: 18px 16px;
`;
