import styled from 'styled-components';

interface BookmarkButtonProps {
  isAddedToList: boolean;
}

export const Container = styled.div`
  width: 95%;
  height: 290px;
  margin: auto;
  position: relative;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  @media (max-width: 768px) {
    min-height: auto;
    width: 98%;
    height: 200px;
    margin: 0;

    img {
      border-radius: 8px;
    }
  }
`;

export const CourseDetails = styled.div`
  width: 50%;
  max-width: 700px;
  height: 100%;

  padding: 40px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;

  background: rgba(0, 0, 38, 0.96);

  @media (max-width: 768px) {
    padding: 22px 18px;
    width: 100%;

    background: var(--purple);
    opacity: 0.92;
    border-radius: 8px;
  }
`;

export const CourseTitle = styled.h3`
  font-size: 3.2rem;
  font-weight: 700;
  color: white;
  line-height: 40px;

  @media (max-width: 768px) {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 24px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const BookmarkButton = styled.button<BookmarkButtonProps>`
  cursor: pointer;
  border: none;
  background: none;
  margin-bottom: 20px;

  svg path {
    fill-rule: ${({ isAddedToList }) =>
      isAddedToList ? 'inherit' : 'evenodd'};
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      svg path {
        fill-rule: inherit;
      }
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

export const CourseData = styled.div`
  display: flex;
  margin-top: auto;
  font-weight: 300;
  color: white;
`;

export const CourseInfo = styled.div`
  display: flex;
  align-items: center;
  padding-right: 16px;
  font-size: 1.8rem;

  & + & {
    border-left: 1px solid white;
    padding-left: 16px;
    padding-right: 0;
  }

  svg {
    margin-right: 8px;
  }

  @media (max-width: 768px) {
    font-size: 1.2rem;

    padding-right: 8px;

    & + & {
      padding-left: 8px;
    }
  }
`;

export const CourseLikes = styled.div`
  color: var(--neutralLight40);
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  margin-left: 16px;
  padding-left: 16px;
  border-left: 1px solid var(--white);

  svg {
    margin-right: 10px;
    fill: var(--white);
  }
`;
