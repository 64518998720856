import { useContext, useState } from 'react';
import { BiEdit, BiTrash, BiSave } from 'react-icons/bi';
import { GrClose } from 'react-icons/gr';
import { useHistory } from 'react-router';
import Swal from 'sweetalert2';
import Loading from '../../../../../../components/Loading';
import { Mobile, NotMobile } from '../../../../../../components/Responsive';
import { LanguageContext } from '../../../../../../contexts/LanguageContext';
import {
  AnnotationFromAllAnnotation,
  ContentAnnotation,
} from '../../../../../../models/all-annotation';
import {
  deleteAnnotation as deleteAnnotationService,
  updateAnnotation as updateAnnotationService,
} from '../../../../../../services/annotations';
import * as Style from './styles';

interface AnnotationProps {
  annotation: AnnotationFromAllAnnotation;
  content?: ContentAnnotation;
  refreshAnnotations: () => void;
  trailId: string;
}

const Annotation: React.FC<AnnotationProps> = ({
  annotation,
  refreshAnnotations,
  content,
  trailId,
}) => {
  const { language, dictionary } = useContext(LanguageContext);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const history = useHistory();

  const updateAnnotation = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (title.length > 0 && description.length > 0) {
      await updateAnnotationService(annotation.annotation_id, {
        title,
        description,
      });
      setIsEditing(false);
      setLoading(true);
      history.go(0);
    } else if (!title) {
      Swal.fire({
        icon: 'error',
        text: dictionary.ANNOTATION.MODAL.UPDATE_TITLE.ERROR,
      });
    } else if (!description) {
      Swal.fire({
        icon: 'error',
        text: dictionary.ANNOTATION.MODAL.UPDATE_DESCRIPTION.ERROR,
      });
    }
  };

  const cancelEditAnnotation = () => {
    setIsEditing(false);
    clearInputs();
  };

  const editAnnotation = () => {
    setIsEditing(true);
    setTitle(annotation.title);
    setDescription(annotation.description);
  };

  const clearInputs = () => {
    setTitle('');
    setDescription('');
  };

  const deleteAnnotation = async () => {
    Swal.fire({
      title: dictionary.ANNOTATION.CONFIRMATION,
      text: dictionary.ANNOTATION.DELETE_ANNOTATION,
      showCancelButton: true,
      cancelButtonText: dictionary.ANNOTATION.CANCEL,
      focusConfirm: false,
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deleteAnnotationService(annotation.annotation_id);
          await refreshAnnotations();
          Swal.fire({
            icon: 'success',
            text: dictionary.ANNOTATION.MODAL.REMOVE.SUCCESS,
          });
        } catch (error) {
          Swal.fire({
            title: 'Erro',
            text: dictionary.ANNOTATION.MODAL.REMOVE.ERROR,
          });
        }
      }
    });
  };

  const formatDate = (date: Date) => {
    return new Intl.DateTimeFormat(language).format(date);
  };

  const goToContent = () => {
    if (!content) {
      return;
    }
    history.push(
      `/trails/${trailId}/modules/${'unknown'}/contents/${content.content_id}`,
    );
  };

  return (
    <Style.Container>
      {loading ? <Loading /> : null}
      {isEditing ? (
        <Style.EditionContainer onSubmit={e => updateAnnotation(e)}>
          <input value={title} onChange={e => setTitle(e.target.value)} />
          <textarea
            value={description}
            onChange={e => setDescription(e.target.value)}
          >
            {description}
          </textarea>
          <Style.ButtonGroup>
            <button type="button" onClick={cancelEditAnnotation}>
              <GrClose size={15} />{' '}
              {dictionary.MY_COURSES.ANNOTATION_PAGE.CANCEL}
            </button>
            <button type="submit">
              <BiSave size={17} /> {dictionary.MY_COURSES.ANNOTATION_PAGE.SAVE}
            </button>
          </Style.ButtonGroup>
        </Style.EditionContainer>
      ) : (
        <>
          <Style.Header>
            <Style.Info>
              <Style.Title>{annotation.title}</Style.Title>
              <NotMobile>
                <Style.Actions>
                  <Style.ActionButton onClick={editAnnotation}>
                    <BiEdit size={20} />
                  </Style.ActionButton>
                  <Style.ActionButton onClick={deleteAnnotation}>
                    <BiTrash size={20} />
                  </Style.ActionButton>
                </Style.Actions>
              </NotMobile>
            </Style.Info>
            <Style.Date>
              {formatDate(new Date(annotation.created_at))}
            </Style.Date>
          </Style.Header>
          <Style.AnnotationContent>
            {annotation.description}
          </Style.AnnotationContent>

          <Mobile>
            <Style.Actions>
              <Style.ButtonRedirectContent onClick={goToContent}>
                {dictionary.ANNOTATION.GO_TO_COURSE}
              </Style.ButtonRedirectContent>
              <div>
                <Style.ActionButton onClick={editAnnotation}>
                  <BiEdit size={20} />
                </Style.ActionButton>
                <Style.ActionButton onClick={deleteAnnotation}>
                  <BiTrash size={20} />
                </Style.ActionButton>
              </div>
            </Style.Actions>
          </Mobile>
        </>
      )}
    </Style.Container>
  );
};

export default Annotation;
