import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  font-family: 'Nunito Sans', 'Roboto', sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 19px;

  display: flex;
  align-items: center;

  & + & {
    margin-top: 10px;
  }

  input {
    margin-right: 9px;
  }

  span.active {
    color: var(--lightBlue);
  }
`;
