import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Swal from 'sweetalert2';
import {
  AiOutlineClockCircle,
  AiOutlinePlayCircle,
  AiOutlineUser,
} from 'react-icons/ai';

import Content from '../../../../models/content';
import Trail from '../../../../models/trail';

import { secondsToTimeText } from '../../../../helpers/time';

import TrailContentRate from './components/TrailContentRate';
import TrailContentLike from './components/TrailContentLike';
import TrailContentExtraMaterials from './components/TrailContentExtraMaterials';
import TrailContentAnnotations from './components/TrailContentAnnotations';
import PlayerSambaVideos from './components/PlayerSambaVideos';

import { updateContentProgress as updateContentProgressService } from '../../../../services/trail';

import { BsBookmark } from 'react-icons/bs';
import {
  addTrailBookmark,
  removeTrailBookmark,
} from '../../../../services/bookmark';
import * as Style from './styles';
import { Mobile, NotMobile } from '../../../../components/Responsive';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import RatingDisabled from '../../../../components/RatingDisabled';
import { PlayScorm } from '../../../../components/PlayScorm';

interface TrailContentProps {
  currentContent: Content;
  trail: Trail;
  getEventListeners: (player: PlayerEventListener) => void;
  progress: number;
  startCurrentContent: () => Promise<void>;
  finishContent: () => Promise<void>;
  goToNextContent: () => void;
}

export interface PlayerEventListener {
  event: string;
  eventParam?: any;
  duration?: any;
}

const TrailContent: React.FC<TrailContentProps> = ({
  currentContent,
  trail,
  getEventListeners,
  progress,
  startCurrentContent,
  finishContent,
  goToNextContent,
}) => {
  const { dictionary } = useContext(LanguageContext);
  const [previousCurrentContent, setPreviousCurrentContent] = useState(
    {} as Content,
  );
  const [shouldShowPlayer, setShouldShowPlayer] = useState(true);
  const [tabToBeShown, setTabToBeShown] = useState('overview-tab');
  const [isAddedToList, setIsAddedToList] = useState(trail.addedToList);
  const [lastWatchedTime, setLastWatchedTime] = useState<number>(0);

  const displayTab = (tabToBeDisplayed: string) => {
    setTabToBeShown(tabToBeDisplayed);
  };

  

  const resumeTime = useMemo(() => {
    if (!currentContent) {
      return false;
    }

    if (currentContent.alreadyFinished) {
      return false;
    }

    if (currentContent.contentView) {
      setLastWatchedTime(currentContent.contentView);
      return currentContent.contentView;
    }

    return false;
  }, [currentContent]);

  const addToList = async () => {
    if (trail) {
      try {
        if (isAddedToList) {
          await removeTrailBookmark(trail.id);
        } else {
          await addTrailBookmark(trail.id);
        }
        setIsAddedToList(!isAddedToList);
      } catch (error) {
        Swal.fire({
          title: dictionary.ERROR.BOOKMARK,
          text: error.message,
          icon: 'error',
        });
      }
    }
  };

  const updateContentProgress = useCallback(
    async (time: number | null) => {
      await updateContentProgressService(
        currentContent.trailId || '',
        currentContent.courseId || '',
        currentContent.id,
        time,
      );

      return;
    },
    [currentContent.trailId, currentContent.courseId, currentContent.id],
  );

  useEffect(() => {
    const currentContentIsDifferent =
      currentContent.id !== previousCurrentContent.id ||
      currentContent.courseId !== previousCurrentContent.courseId ||
      currentContent.trailId !== previousCurrentContent.trailId;
    if (currentContentIsDifferent) {
      setShouldShowPlayer(false);
      process.nextTick(() => setShouldShowPlayer(true));
    }

    setPreviousCurrentContent(currentContent);
    setTabToBeShown('overview-tab');
  }, [trail, currentContent]);

  useEffect(() => {
    if (progress >= lastWatchedTime + 60) {
      setLastWatchedTime(progress);
      updateContentProgress(progress);
      return;
    }
    if (progress < lastWatchedTime) {
      if (progress > 1) {
        setLastWatchedTime(progress);
        updateContentProgress(progress);
        return;
      }
      setLastWatchedTime(0);
      updateContentProgress(null);
    }
  }, [progress, lastWatchedTime, updateContentProgress]);

  return (
    <Style.Container>
      <Style.TopContent>
        <Style.CourseInfo>
          <Style.CourseAuthor>
            <AiOutlineUser size={20} color={'#061938'} />
            {dictionary.COMMON.THEME}: {trail.author}
          </Style.CourseAuthor>
          <Style.CourseTime>
            <AiOutlineClockCircle size={20} color={'#061938'} />
            {dictionary.COMMON.DURATION}: {secondsToTimeText(trail.duration)}
          </Style.CourseTime>
        </Style.CourseInfo>
        <NotMobile>
          <Style.BookmarkButton
            onClick={addToList}
            isAddedToList={isAddedToList}
          >
            <BsBookmark
              size={20}
              color={`${isAddedToList ? '#FFF' : 'var(--purple)'}`}
            />
            {isAddedToList
              ? dictionary.COMMON.BOOKMARKED
              : dictionary.COMMON.BOOKMARK}
          </Style.BookmarkButton>
        </NotMobile>
      </Style.TopContent>
      <Style.PlayerContainer>
        {shouldShowPlayer ? (
          <>
            {currentContent.type &&
            currentContent.type.toUpperCase() === 'SCORM' ? (
              <PlayScorm
                selectedContent={currentContent}
                startCourseOrTrail={startCurrentContent}
                finishCourseOrTrail={finishContent}
                goToNextContent={goToNextContent}
              />
            ) : (
              <PlayerSambaVideos
                contentUrl={currentContent.contentUrl}
                finishContent={currentContent.alreadyFinished}
                getEventListeners={getEventListeners}
                resume={resumeTime || false}
              />
            )}
            <Mobile>
              <Style.BookmarkButton
                onClick={addToList}
                isAddedToList={isAddedToList}
              >
                <BsBookmark
                  size={20}
                  color={`${isAddedToList ? '#FFF' : 'var(--purple)'}`}
                />
              </Style.BookmarkButton>
            </Mobile>
          </>
        ) : (
          <Style.UnavailableVideo>
            <Style.Icon>
              <AiOutlinePlayCircle color="#FFF" size={200} />
            </Style.Icon>
          </Style.UnavailableVideo>
        )}
      </Style.PlayerContainer>
      <Style.CourseInfoContainer>
        <Style.CourseSocialStats>
          {trail.alreadyStarted ? (
            <TrailContentRate trailId={trail.id} stars={trail.stars} />
          ) : (
            <RatingDisabled />
          )}

          <TrailContentLike
            trailId={trail.id}
            userLiked={trail.userLiked}
            numberOfLikes={trail.numberOfLikes}
          />
        </Style.CourseSocialStats>
        <Style.Title>{currentContent.title}</Style.Title>
        <Style.TabsContainer>
          <Style.TabButton
            type="button"
            className={`
                overview-tab-link
                ${tabToBeShown === 'overview-tab' ? 'active' : ''}
              `}
            onClick={() => {
              displayTab('overview-tab');
            }}
          >
            {dictionary.COURSE.OVERVIEW}
          </Style.TabButton>

          <Style.TabButton
            type="button"
            className={`
                support-material-tab-link
                ${tabToBeShown === 'support-material-tab' ? 'active' : ''}
              `}
            onClick={() => {
              displayTab('support-material-tab');
            }}
          >
            {dictionary.COURSE.EXTRA_MATERIALS.EXTRA_MATERIALS}
          </Style.TabButton>

          <Style.TabButton
            type="button"
            className={`
                    annotations-tab-link
                    ${tabToBeShown === 'annotations-tab' ? 'active' : ''}
                  `}
            onClick={() => {
              displayTab('annotations-tab');
            }}
          >
            {dictionary.COMMON.ANNOTATIONS}
          </Style.TabButton>
        </Style.TabsContainer>
        <Style.TabContents>
          {(() => {
            switch (tabToBeShown) {
              case 'overview-tab':
                return (
                  <Style.TabContent>
                    {currentContent?.description}
                  </Style.TabContent>
                );

              case 'support-material-tab':
                return (
                  <Style.TabContent>
                    <TrailContentExtraMaterials
                      extraMaterials={currentContent.extraMaterials}
                    />
                  </Style.TabContent>
                );

              case 'annotations-tab':
                return (
                  currentContent && (
                    <TrailContentAnnotations
                      trailId={trail.id}
                      contentId={currentContent.id}
                    />
                  )
                );
            }
          })()}
        </Style.TabContents>
      </Style.CourseInfoContainer>
    </Style.Container>
  );
};

export default TrailContent;
