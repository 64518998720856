import styled from 'styled-components';

export const Container = styled.div`
  min-height: 30vw;
  height: 100%;

  margin: auto;
  max-width: 1424px;
  width: 100%;

  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;

export const SkeletonContainer = styled.div`
  .hd-skeleton,
  .semi-hd-skeleton,
  .medium-skeleton,
  .tablet-skeleton,
  .mobile-skeleton {
    display: none;
    margin-top: 140px;
  }

  @media (min-width: 1300px) {
    .hd-skeleton {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (min-width: 1200px) and (max-width: 1300px) {
    .semi-hd-skeleton {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (min-width: 800px) and (max-width: 1200px) {
    .medium-skeleton {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (min-width: 550px) and (max-width: 800px) {
    .tablet-skeleton {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (min-width: 350px) and (max-width: 550px) {
    .mobile-skeleton {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const OtherCourses = styled.div`
  margin-bottom: 180px;
`;

export const OtherCoursesTitle = styled.h2`
  color: #ffffff;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 40px;
  margin-bottom: 36px;
`;

export const OtherCoursesList = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));

  @media (max-width: 768px) {
    justify-items: center;
  }
`;

export const CourseContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 54px 0;
`;
