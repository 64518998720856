import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import CourseCard from '../../../../components/CourseCard';
import { Mobile, NotMobile } from '../../../../components/Responsive';
import CourseCardSkeleton from '../../../../components/Skeleton/CourseCardSkeleton';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import ContentCard from '../../../../models/content-card';
import { getContentCards } from '../../../../services/content';

import * as Style from './styles';

const NewCourses: React.FC = () => {
  const history = useHistory();
  const { dictionary } = useContext(LanguageContext);
  const [newContents, setNewContents] = useState<ContentCard[]>([]);
  const [shouldShowSkeleton, setShouldShowSkeleton] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const newContentsFilters = {
          limit: 4,
          most_recent: true,
        };
        const contents = await getContentCards(newContentsFilters);

        if (contents && contents.length) {
          setNewContents(contents);
        } else {
          setShouldShowSkeleton(false);
        }
      } catch (e) {
        toast.error(dictionary.ERROR.LOAD_CONTENTS);
        setShouldShowSkeleton(false);
      }
    })();
  }, []);

  return (
    <Style.Container>
      <Style.Header>
        <Style.Title>{dictionary.HOME.NEW}</Style.Title>
        <NotMobile>
          <Style.ViewMoreButton onClick={() => history.push(`/courses`)}>
            <NotMobile>{dictionary.HOME.SEE_ALL_CONTENTS}</NotMobile>
            <Mobile>{dictionary.HOME.SEE_ALL}</Mobile>
          </Style.ViewMoreButton>
        </NotMobile>
      </Style.Header>

      <Style.CardList>
        {newContents && newContents.length ? (
          newContents.map(content => (
            <CourseCard key={content.id} content={content} />
          ))
        ) : shouldShowSkeleton ? (
          Array(4)
            .fill('')
            .map((i, index) => <CourseCardSkeleton key={index} />)
        ) : (
          <Style.NotFound>{dictionary.HOME.NOT_FOUND_NEW}</Style.NotFound>
        )}
      </Style.CardList>
    </Style.Container>
  );
};
export default NewCourses;
