import httpClient from '../../http-client';

const addContentBookmark = async (courseId: string) => {
  const response = (
    await httpClient.post(`/content-book-marks`, {
      content_id: courseId,
    })
  ).data;

  return response;
};

const removeContentBookmark = async (courseId: string) => {
  const response = (await httpClient.delete(`/content-book-marks/${courseId}`))
    .data;

  return response;
};

const addTrailBookmark = async (trailId: string) => {
  const response = (
    await httpClient.post(`/book-marks`, {
      trail_id: trailId,
    })
  ).data;

  return response;
};

const removeTrailBookmark = async (trailId: string) => {
  const response = (await httpClient.delete(`/book-marks/${trailId}`)).data;

  return response;
};

export {
  addContentBookmark,
  removeContentBookmark,
  addTrailBookmark,
  removeTrailBookmark,
};
