import styled from 'styled-components';

export const Container = styled.div`
  min-height: 30vw;
  height: 100%;

  margin: auto;
  max-width: 1424px;
  width: 100%;
  position: relative;
  z-index: 2;

  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;

export const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 46px 0 180px;

  @media (max-width: 768px) {
    padding-top: 32px;
  }
`;

export const Title = styled.h1`
  color: #ffffff;
  font-size: 4rem;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 22px;

  span {
    color: var(--blue);
  }

  @media (max-width: 768px) {
    font-size: 2rem;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 8px;
  }
`;

export const Subtitle = styled.p`
  color: var(--neutralLight40);
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 88px;

  span {
    font-weight: 700;

    &.verify-message {
      display: block;
    }
  }

  @media (max-width: 768px) {
    font-size: 1.4rem;
    margin-bottom: 48px;
  }
`;

export const Results = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));

  @media (max-width: 768px) {
    justify-items: center;
  }
`;

export const NotFound = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;

  div {
    margin-top: 64px;
    background-color: var(--blue);
    border-radius: 100px;
    padding: 8px 32px;
    font-family: 'Nunito Sans', 'Roboto', sans-serif;
    font-weight: 800;
    font-size: 1.8rem;
    color: white;
    cursor: pointer;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: var(--purple);
      }
    }

    @media (max-width: 768px) {
      font-size: 1.6rem;
    }
  }
`;
