import styled from 'styled-components';

export const ContainerMobileCertificate = styled.div`
  flex: 1;
  margin-bottom: 32px;
  min-width: 47%;
`;

export const CardMobileCertificate = styled.div`
  border: 1px solid rgba(66, 82, 110, 0.64);
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const ThumbnailContainer = styled.div`
  max-height: 94px;
  margin-bottom: 8px;
  overflow: hidden;
  border-radius: 4px;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const AuthorCardCertificate = styled.span`
  font-size: 1rem;
  line-height: 12px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: var(--lightBlue);
  margin: 8px 0;
`;

export const NameCardCertificate = styled.h1`
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: var(--white);
  margin-top: 8px;
`;

export const DateCardCertificate = styled.span`
  display: flex;
  align-items: center;

  font-weight: 500;
  font-size: 1rem;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: var(--neutralLight40);
  margin: 16px 0;
`;

export const ButtonCardCertificate = styled.button`
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  width: 100%;
  border: 0;
  background: var(--lightBlue);
  justify-content: space-between;
  align-items: center;
  > span {
    font-weight: 500;
    font-size: 1rem;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: var(--dark100);
  }
`;
