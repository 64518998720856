import React, { createContext, useCallback, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import ContentCard from '../models/content-card';
import { searchContents } from '../services/content';

interface ISeachProviderProps {
  searchParam: string;
  setSearchParam: React.Dispatch<React.SetStateAction<string>>;
  searchResults: ContentCard[];
  isSearching: boolean;
  resultsCount: number;
  totalPages: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

export const SearchContext = createContext<ISeachProviderProps>(
  {} as ISeachProviderProps,
);

export const SearchProvider: React.FC = ({ children }) => {
  const [searchParam, setSearchParam] = useState(' ');
  const [searchResults, setSearchResults] = useState<ContentCard[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [resultsCount, setResultsCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const contentsPerPage = 8;

  const searchRequest = useCallback(async () => {
    if (searchParam !== '') {
      try {
        setSearchResults([]);
        setIsSearching(false);
        setResultsCount(0);
        setPage(1);
        const allResults = await searchContents(searchParam);
        setTotalPages(Math.ceil(allResults.length / contentsPerPage));

        setResultsCount(allResults.length);
      } catch (e) {
        toast.error('Erro ao concluir a busca. Tente novamente.');
      }
    }
  }, [searchParam]);

  const populatePages = useCallback(async () => {
    setIsSearching(true);
    const filters = {
      limit: contentsPerPage,
      offset: page > 1 ? (page - 1) * contentsPerPage : undefined,
    };
    const response = await searchContents(searchParam, filters);
    setIsSearching(false);
    setSearchResults(response);
  }, [page, searchParam]);

  useEffect(() => {
    searchRequest();
  }, [searchRequest]);

  useEffect(() => {
    populatePages();
  }, [populatePages]);

  return (
    <SearchContext.Provider
      value={{
        searchParam,
        setSearchParam,
        searchResults,
        isSearching,
        resultsCount,
        setPage,
        totalPages,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
