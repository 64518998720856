import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: var(--blueGradient);
  z-index: 3;
  padding: 38px;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-in-out;
`;

export const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  display: block;
  margin-left: auto;
`;

export const Body = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const Title = styled.div`
  color: var(--purple);
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 24px;
  margin-bottom: 32px;

  @media (max-width: 768px) {
    color: var(--white);
  }
`;
