import React, { createContext, useState } from 'react';

interface ISeachOverlayProviderProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SearchOverlayContext = createContext<ISeachOverlayProviderProps>(
  {} as ISeachOverlayProviderProps,
);

export const SearchOverlayProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <SearchOverlayContext.Provider
      value={{
        isOpen,
        setIsOpen,
      }}
    >
      {children}
    </SearchOverlayContext.Provider>
  );
};
