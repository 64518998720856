import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { LanguageContext } from '../../contexts/LanguageContext';
import { MenuContainer } from './styles';

const Menu: React.FC = () => {
  const location = useLocation();
  const { dictionary } = useContext(LanguageContext);

  return (
    <MenuContainer>
      <Link
        to="/courses"
        className={`
          ${location.pathname === '/courses' ? 'active' : ''}
        `}
      >
        {dictionary.COMMON.CHANNELS}
      </Link>
    </MenuContainer>
  );
};

export default Menu;
