import { Dispatch, SetStateAction } from 'react';
import BottomTab from './components/BottomTab';
import * as Style from './styles';

interface BottomTabNavigationProps {
  tabs: BottomTabProps[];
  setIsSidebarOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedTab: Dispatch<SetStateAction<string>>;
}

interface BottomTabProps {
  name: string;
  value: string;
  path: string;
  icon: string;
}

const BottomTabNavigation: React.FC<BottomTabNavigationProps> = ({
  tabs,
  setIsSidebarOpen,
  setSelectedTab,
}) => {
  return (
    <Style.Container>
      {tabs.map((tab, index) => (
        <BottomTab
          key={index}
          tab={tab}
          setIsSidebarOpen={setIsSidebarOpen}
          setSelectedTab={setSelectedTab}
        />
      ))}
    </Style.Container>
  );
};

export default BottomTabNavigation;
