import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Courses from '../pages/Courses';
import Course from '../pages/Course';
import Trail from '../pages/Trail';
import Channel from '../pages/Channel';
import Search from '../pages/Search';
import MyCourses from '../pages/MyCourses';
import FAQ from '../pages/FAQ';
import Home from '../pages/Home';
import Lives from '../pages/Lives';
import ScrollToTop from '../helpers/ScrollToTop';
import Contact from '../pages/Contact';
import SearchOverlay from '../components/SearchOverlay';
import Channels from '../pages/Channels';

const Routes: React.FC = () => {
  return (
    <>
      <ScrollToTop />
      <Switch>
        <Route path="/home" component={Home} />
        <Route path="/contact" component={Contact} />
        <Route path="/my-courses" component={MyCourses} />
        <Route path="/faq" exact component={FAQ} />
        <Route path="/lives" exact component={Lives} />
        <Route path="/search" exact component={Search} />
        <Route path="/search-mobile" exact component={SearchOverlay} />
        <Route path="/channels-mobile" exact component={Channels} />

        <Route path="/channels/:channelId" exact component={Channel} />

        <Route path="/courses" exact component={Courses} />
        <Route path="/courses/:courseId" exact component={Course} />

        <Route path="/trails/:trailId" exact component={Trail} />
        <Route
          path="/trails/:trailId/modules/:moduleId/contents/:contentId"
          component={Trail}
        />
      </Switch>
    </>
  );
};

export default Routes;
