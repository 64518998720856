import React, { useContext } from 'react';
import { BiEdit, BiTrash } from 'react-icons/bi';
import Swal from 'sweetalert2';
import { LanguageContext } from '../../../../../../../../contexts/LanguageContext';
import Annotation from '../../../../../../../../models/annotation';
import { deleteAnnotation } from '../../../../../../../../services/annotations';
import {
  TrailContentAnnotationActionButtons,
  TrailContentAnnotationButton,
  TrailContentAnnotationContainer,
  TrailContentAnnotationDate,
  TrailContentAnnotationDescription,
  TrailContentAnnotationLowerContent,
  TrailContentAnnotationTitle,
  TrailContentAnnotationUpperContent,
} from './style';

interface TrailContentAnnotationProps {
  annotation: Annotation;
  refreshAnnotations: () => void;
  edit: (annotation: Annotation) => void;
}

const TrailContentAnnotation: React.FC<TrailContentAnnotationProps> = ({
  annotation,
  refreshAnnotations,
  edit,
}) => {
  const { dictionary } = useContext(LanguageContext);

  const removeAnnotation = async () => {
    try {
      await deleteAnnotation(annotation.id);

      refreshAnnotations();
    } catch (error) {
      Swal.fire({
        title: dictionary.ANNOTATION.MODAL.REMOVE.ERROR,
        text: error.message,
        icon: 'error',
      });
    }
  };

  return (
    <TrailContentAnnotationContainer>
      <TrailContentAnnotationUpperContent>
        <TrailContentAnnotationTitle>
          {annotation.title || '-'}
        </TrailContentAnnotationTitle>

        <TrailContentAnnotationActionButtons>
          <TrailContentAnnotationButton
            type="button"
            onClick={() => edit(annotation)}
          >
            <BiEdit size={18} />
          </TrailContentAnnotationButton>
          <TrailContentAnnotationButton
            type="button"
            onClick={removeAnnotation}
          >
            <BiTrash size={18} />
          </TrailContentAnnotationButton>
        </TrailContentAnnotationActionButtons>
      </TrailContentAnnotationUpperContent>
      <TrailContentAnnotationLowerContent>
        <TrailContentAnnotationDate>
          {new Intl.DateTimeFormat('pt-BR').format(annotation.date)}
        </TrailContentAnnotationDate>
        <TrailContentAnnotationDescription>
          {annotation.description}
        </TrailContentAnnotationDescription>
      </TrailContentAnnotationLowerContent>
    </TrailContentAnnotationContainer>
  );
};

export default TrailContentAnnotation;
