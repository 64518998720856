import { useContext } from 'react';
import Live from '../../../../models/lives';
import { AiOutlineCalendar, AiOutlineClockCircle } from 'react-icons/ai';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import DefaultThumbnail from '../../../../assets/default-card-thumbnail.png';
import * as Style from './styles';

interface LiveCardProps {
  live: Live;
}

const LiveCard: React.FC<LiveCardProps> = ({ live }) => {
  const { language } = useContext(LanguageContext);

  const convertDate = (date: string) => {
    const formattedDate = new Date(date);
    const intlFortmat = new Intl.DateTimeFormat(language, {
      weekday: 'short',
      month: '2-digit',
      day: '2-digit',
    }).format(formattedDate);

    const newDate = (
      intlFortmat[0].toUpperCase() + intlFortmat.slice(1)
    ).replace('.', '');

    return newDate;
  };

  const convertTime = (date: string) => {
    const formattedDate = new Date(date);
    const intlFortmat = new Intl.DateTimeFormat(language, {
      hour: 'numeric',
      minute: 'numeric',
    }).format(formattedDate);

    const newTime = intlFortmat.replace(':', 'h');

    return newTime;
  };

  return (
    <Style.Container>
      <Style.ImageContainer>
        <img src={live.thumbnail_url || DefaultThumbnail} alt="Live" />
      </Style.ImageContainer>
      <Style.Body>
        <Style.Title>{live.name}</Style.Title>
        <Style.LowerContent>
          <Style.LiveInfo>
            <AiOutlineCalendar />
            {convertDate(live.start)}
          </Style.LiveInfo>
          <Style.LiveInfo>
            <AiOutlineClockCircle />
            {convertTime(live.start)}
          </Style.LiveInfo>
        </Style.LowerContent>
      </Style.Body>
    </Style.Container>
  );
};

export default LiveCard;
