import styled from 'styled-components';

export const MenuContainer = styled.nav`
  display: flex;

  align-items: center;
  margin: 0 32px;

  height: 100%;

  a {
    position: relative;
    text-decoration: none;

    font-size: 2.2rem;
    line-height: 22px;
    color: white;
    font-weight: 500;

    & + a {
      margin-left: 40px;
    }

    &:after {
      content: '';
      width: 0%;
      opacity: 0;
      height: 2px;
      bottom: -10px;
      left: 0;
      position: absolute;
      transition: all 0.3s ease-in-out;
      background: white;
    }
  }

  a:hover,
  a.active {
    font-weight: 500;
    max-width: 100%;

    &:after {
      width: 100%;
      opacity: 1;
    }
  }
`;
