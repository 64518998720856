import convertFaq from '../../converters/convert-faq';
import httpClient from '../../http-client';
import Faq from '../../models/faq';
import FaqFromResponse from '../../models/from-api/faq';

const getQuestions = async (): Promise<Faq[]> => {
  const faqs = (await httpClient.get<FaqFromResponse[]>('/faqs')).data;

  let convertedFaqs = [] as Faq[];
  if (faqs && faqs.length) {
    convertedFaqs = faqs.map(convertFaq);
  }

  return convertedFaqs;
};

export { getQuestions };
