import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Breadcrumb from '../../components/Breadcrumb';
import Loading from '../../components/Loading';
import TrailContent, { PlayerEventListener } from './components/TrailContent';
import TrailCourses from './components/TrailCourses';
import CourseCard from '../../components/CourseCard';

import {
  startContent as startContentService,
  finishContent as finishContentService,
  getContentCards,
} from '../../services/content';
import {
  startCourse as startCourseService,
  finishCourse as finishCourseService,
} from '../../services/course';
import {
  startTrail as startTrailService,
  finishTrail as finishTrailService,
  getTrail as getTrailService,
} from '../../services/trail';

import Content from '../../models/content';
import { default as ITrail } from '../../models/trail';
import ContentCard from '../../models/content-card';

import { LanguageContext } from '../../contexts/LanguageContext';

import * as Style from './styles';
import Course from '../../models/course';
import { postCertificate } from '../../services/certificate';

export interface CourseParams {
  trailId: string;
  moduleId?: string;
  contentId?: string;
}

const Trail: React.FC = () => {
  const history = useHistory();
  const { dictionary } = useContext(LanguageContext);
  const { trailId, moduleId, contentId } = useParams() as CourseParams;

  const [trail, setTrail] = useState({} as ITrail);

  const [currentModule, setCurrentModule] = useState({} as Course);
  const [moduleList, setModuleList] = useState<Course[]>([]);

  const [currentContent, setCurrentContent] = useState({} as Content);
  const [contentList, setContentList] = useState<Content[]>([]);

  const [trailRelatedCourses, setTrailRelatedCourses] = useState(
    [] as ContentCard[],
  );

  const [trailProgress, setTrailProgress] = useState<number>(0);
  const [contentToFinish, setContentToFinish] = useState({} as Content);
  const [finishVideo, setFinishVideo] = useState<boolean>(false);
  const isBlockFinishRef = useRef<boolean>(false);
  const isBlockStartRef = useRef<boolean>(false);
  const isStartingContentRef = useRef<boolean>(false);

  const crumbs = [
    { name: 'Home', path: '/home' },
    { name: dictionary.COMMON.CHANNELS, path: `/courses` },
    { name: trail.category, path: `/channels/${trail.categoryId}` },
    { name: trail.name, path: '' },
  ];

  const isLoading = useMemo(() => {
    return !trail || !trail.id;
  }, [trail]);

  const redirectURLOnOpen = useCallback(() => {
    if (!contentList.length || !moduleList.length) return;

    const firstNonWatchedContent =
      contentList.find(content => !content.alreadyFinished) || contentList[0];
    const selectedModule =
      moduleList.find(
        module => module.id === firstNonWatchedContent.courseId,
      ) || moduleList[0];

    history.push(
      `/trails/${firstNonWatchedContent.trailId}/modules/${firstNonWatchedContent.courseId}/contents/${firstNonWatchedContent.id}`,
    );

    setCurrentModule(selectedModule);
    setCurrentContent(firstNonWatchedContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentList.length]);

  const getcurrentContent = useCallback(() => {
    const foundModule = moduleList.find(module => module.id === moduleId);
    const foundContent = contentList.find(
      content =>
        content.id === contentId &&
        content.courseId === moduleId &&
        content.trailId === trailId,
    );

    if (foundContent && foundContent.id && foundModule && foundModule.id) {
      setCurrentModule(foundModule);
      setCurrentContent(foundContent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentId, moduleId]);

  const startTrail = async () => {
    if (
      !trail.alreadyStarted ||
      (!trail.alreadyStarted && currentContent.type === 'SCORM')
    ) {
      try {
        await startTrailService(trailId);
        setTrail({ ...trail, alreadyStarted: true });
        setTimeout(() => startModule(), 500);
      } catch (error) {
        setTrail({ ...trail, alreadyStarted: false });
      }
    } else {
      startModule();
    }
  };

  const startModule = async () => {
    if (!currentModule.alreadyStarted) {
      try {
        await startCourseService(
          currentContent.courseId || '',
          currentContent.trailId || '',
        );
        setModuleList(
          moduleList.map(module => {
            if (module.id === currentModule.id) {
              module.alreadyStarted = true;
            }
            return module;
          }),
        );
        setTimeout(() => startCurrentContent(), 500);
      } catch (error) {
        console.log(error);
      }
    } else {
      startCurrentContent();
    }
  };

  const startCurrentContent = async () => {
    if (!currentContent.alreadyStarted) {
      try {
        await startContentService(
          currentContent.id,
          currentContent.courseId,
          currentContent.trailId,
        );

        setContentList(
          contentList.map(item => {
            if (
              item.id === currentContent.id &&
              item.courseId === currentContent.courseId
            ) {
              item.alreadyStarted = true;
            }

            return item;
          }),
        );
        if (contentToFinish.id) {
          setContentToFinish({ ...contentToFinish, alreadyStarted: true });
        }
        setCurrentContent({ ...currentContent, alreadyStarted: true });
      } catch (error) {
        console.error(error);
      }
    }

    isStartingContentRef.current = false;
  };

  const getEventListeners = async (player: PlayerEventListener) => {
    if (
      window.location.href.endsWith(
        `trails/${currentContent.trailId}/modules/${currentContent.courseId}/contents/${currentContent.id}`,
      )
    ) {
      if (currentContent && currentContent.id) {
        switch (player.event) {
          case 'onProgress':
            if (
              hasReachedCompletionTime(player.duration, player.eventParam, true)
            ) {
              setContentToFinish(currentContent);
            }
            updateTrailWatchTime(player);
            break;
          case 'onStart':
            startAction();
            break;
          case 'onFinish':
            handleOnFinish();

            break;
          default:
            break;
        }
      }
    }
  };

  const handleOnFinish = () => {
    setFinishVideo(true);
  };

  const hasReachedCompletionTime = (
    duration: number,
    currentTime: number,
    isSec = false,
  ) => {
    const totalDurationInSecs = isSec ? duration : duration / 1000;
    const completionRate = 0.9;

    const completionTime = totalDurationInSecs * completionRate;
    return currentTime >= completionTime;
  };

  const goToNextContent = () => {
    if (currentContent && currentContent.type.toUpperCase() === 'SCORM') {
      const indexOfCurrentContent = contentList.indexOf(currentContent);
      if (
        indexOfCurrentContent > -1 &&
        indexOfCurrentContent <= contentList.length - 2
      ) {
        const nextContent = contentList[indexOfCurrentContent + 1];

        history.push(
          `/trails/${nextContent.trailId}/modules/${nextContent.courseId}/contents/${nextContent.id}`,
        );
      }
    }

    if (contentToFinish && contentToFinish.type.toUpperCase() !== 'SCORM') {
      const indexOfCurrentContent = contentList.indexOf(contentToFinish);
      if (
        indexOfCurrentContent > -1 &&
        indexOfCurrentContent <= contentList.length - 2
      ) {
        const nextContent = contentList[indexOfCurrentContent + 1];

        history.push(
          `/trails/${nextContent.trailId}/modules/${nextContent.courseId}/contents/${nextContent.id}`,
        );
      }
    }
  };

  const finishContent = async () => {
    if (currentContent.type.toUpperCase() === 'SCORM') {
      if (!currentContent.alreadyFinished) {
        try {
          await finishContentService(
            currentContent.id,
            currentContent.courseId || '',
            currentContent.trailId || '',
          );
          setContentList(
            contentList.map(item => {
              if (
                item.id === currentContent.id &&
                item.courseId === currentContent.courseId
              ) {
                item.alreadyFinished = true;
              }

              return item;
            }),
          );

          setContentToFinish({ ...currentContent, alreadyFinished: true });
          setCurrentContent({ ...currentContent, alreadyFinished: true });
        } catch (error) {
          console.error(error);
        }
      }
    } else if (!contentToFinish.alreadyFinished) {
      try {
        await finishContentService(
          contentToFinish.id,
          contentToFinish.courseId || '',
          contentToFinish.trailId || '',
        );
        setContentList(
          contentList.map(item => {
            if (
              item.id === contentToFinish.id &&
              item.courseId === contentToFinish.courseId
            ) {
              item.alreadyFinished = true;
            }

            return item;
          }),
        );

        setContentToFinish({ ...contentToFinish, alreadyFinished: true });
        setCurrentContent({ ...currentContent, alreadyFinished: true });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const finishModule = async (id = '', courseId = '', trailId = '') => {
    try {
      await finishCourseService(courseId, trailId);

      setModuleList(
        moduleList.map(module => {
          if (module.id === id) {
            module.alreadyFinished = true;
          }
          return module;
        }),
      );
    } catch (error) {
      console.error(error);
    }
  };

  const finishTrail = async () => {
    try {
      await finishTrailService(currentContent.trailId || '');
      setTrail({ ...trail, alreadyFinished: true });
      await postCertificate(trailId);
    } catch (error) {
      console.error(error);
    }
  };

  const startAction = async () => {
    if (isStartingContentRef.current) {
      return;
    }

    isStartingContentRef.current = true;

    await startTrail();
  };

  const finishAction = async () => {
    if (isBlockFinishRef.current) {
      return;
    }

    if (isStartingContentRef.current) {
      return;
    }

    isBlockFinishRef.current = true;

    await finishContent();
    return;
  };

  const updateTrailWatchTime = useCallback((player: PlayerEventListener) => {
    const { event, eventParam } = player;

    if (event === 'onProgress') {
      setTrailProgress(eventParam);
    }
    return null;
  }, []);

  const getTrail = useCallback(async () => {
    setTrail({} as ITrail);
    setModuleList([] as Course[]);
    setContentList([] as Content[]);

    const localTrail = await getTrailService(trailId);
    if (localTrail && localTrail.id) {
      const allModules = localTrail.courses;
      setTrail(localTrail);
      setModuleList(allModules);
      setContentList(allModules.map(course => course.contents).flat());
    }
  }, [trailId]);

  useEffect(() => {
    getTrail();
  }, [getTrail]);

  useEffect(() => {
    redirectURLOnOpen();
  }, [redirectURLOnOpen]);

  useEffect(() => {
    getcurrentContent();
  }, [getcurrentContent]);

  useEffect(() => {
    (async () => {
      setTrailRelatedCourses([]);
      if (trail.categoryId) {
        const localTrails = await getContentCards({
          limit: 4,
          category_id: trail.categoryId,
        });
        if (localTrails && localTrails.length) {
          setTrailRelatedCourses(
            localTrails.filter(localTrail => trail.id !== localTrail.id),
          );
        }
      }
    })();
  }, [trail.categoryId, trail.id]);

  useEffect(() => {
    isBlockFinishRef.current = false;
    isBlockStartRef.current = false;
    setFinishVideo(false);
    setContentToFinish({
      ...contentToFinish,
      id: '',
      alreadyFinished: false,
      alreadyStarted: false,
    });
  }, [moduleId, contentId]);

  const getNumberContentsToFinishTrail = () => {
    return contentList.filter(content => !content.alreadyFinished).length;
  };

  const getNumberModulesToFinishTrail = () => {
    return moduleList.filter(content => !content.alreadyFinished).length;
  };

  const verifyModuleToFinish = () => {
    return moduleList.filter(module => {
      if (module.alreadyFinished) {
        return false;
      }

      if (!module.alreadyStarted) {
        return false;
      }

      return module.contents.every(module => module.alreadyFinished);
    })[0];
  };

  useEffect(() => {
    const actionStart = async () => {
      //Finish Modules that all contets are finish
      const module = verifyModuleToFinish();
      if (module) {
        finishModule(module.id, module.contents[0].courseId, module.trailId);
      }

      // Finish trail when all contents are finish
      if (
        getNumberContentsToFinishTrail() === 0 &&
        getNumberModulesToFinishTrail() === 0 &&
        !trail.alreadyFinished
      ) {
        await finishTrail();
      }

      // Finish content when has state
      if (
        contentToFinish.id &&
        contentToFinish.alreadyStarted &&
        !contentToFinish.alreadyFinished
      ) {
        finishAction();
      }

      // Go to next when all actions is done
      if (
        contentToFinish.id &&
        contentToFinish.alreadyStarted &&
        contentToFinish.alreadyFinished &&
        finishVideo
      ) {
        goToNextContent();
        setFinishVideo(false);
      }

      if (
        contentToFinish.id &&
        contentToFinish.alreadyStarted &&
        !contentToFinish.alreadyFinished
      ) {
        console.log(
          'Está ocorrendo o save do finish do conteúdo, nesse momento, ações do usuário podem quebrar o save, necessário block',
        );
      }
    };

    if (trail.id && contentList.length) {
      actionStart();
    }
  }, [trail, moduleList, contentList, contentToFinish, finishVideo]);

  return (
    <Style.Container>
      {!isLoading ? (
        <>
          <Breadcrumb crumbs={crumbs} />
          <Style.TrailContainer>
            <Style.UpperContent>
              <Style.Category>{trail.category}</Style.Category>
              <Style.Title>{trail.name}</Style.Title>
              <Style.Description>{trail.description}</Style.Description>
            </Style.UpperContent>
            <Style.LowerContent>
              {currentContent.id && (
                <>
                  <TrailContent
                    trail={trail}
                    currentContent={currentContent}
                    getEventListeners={getEventListeners}
                    progress={trailProgress}
                    goToNextContent={goToNextContent}
                    startCurrentContent={startAction}
                    finishContent={finishContent}
                  />
                  <TrailCourses
                    trail={trail}
                    courses={moduleList}
                    currentContent={currentContent}
                    contentList={contentList}
                  />
                </>
              )}
            </Style.LowerContent>
            {trailRelatedCourses && trailRelatedCourses.length ? (
              <Style.OtherTrails>
                <Style.OtherTrailsTitle>
                  {dictionary.COURSE.OTHER_CONTENTS}
                </Style.OtherTrailsTitle>
                <Style.OtherTrailsList>
                  {trailRelatedCourses.map(trail => (
                    <CourseCard key={trail.id} content={trail} />
                  ))}
                </Style.OtherTrailsList>
              </Style.OtherTrails>
            ) : (
              <></>
            )}
          </Style.TrailContainer>
        </>
      ) : (
        <Loading size={200} />
      )}
    </Style.Container>
  );
};

export default Trail;
