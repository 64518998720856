import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { AiOutlineClose } from 'react-icons/ai';
import * as Style from './styles';

interface MenuProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: Dispatch<SetStateAction<boolean>>;
}

const Menu: React.FC<MenuProps> = ({
  isSidebarOpen,
  setIsSidebarOpen,
  children,
}) => {
  const isSafari = navigator.userAgent.indexOf('Safari') !== -1;
  const isIphone = navigator.userAgent.indexOf('iPhone') !== -1;
  const isMobileIosSafari = isSafari && isIphone;
  const sidebarRef: any = useRef(null);

  useEffect(() => {
    if (!isMobileIosSafari) {
      isSidebarOpen
        ? disableBodyScroll(sidebarRef.current)
        : enableBodyScroll(sidebarRef.current);
    }
  }, [isSidebarOpen]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsSidebarOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sidebarRef]);

  return (
    <Style.Container ref={sidebarRef} isSidebarOpen={isSidebarOpen}>
      <Style.CloseButton
        id="close-menu-button"
        onClick={() => setIsSidebarOpen(false)}
      >
        <AiOutlineClose size={20} color={'var(--white)'} />
      </Style.CloseButton>
      {children}
    </Style.Container>
  );
};

export default Menu;
