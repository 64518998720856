import { useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import Annotation from '../Annotation';
import { ContentAnnotation } from '../../../../../../models/all-annotation';
import { Mobile, NotMobile } from '../../../../../../components/Responsive';

import { ReactComponent as Line } from '../../../../../../assets/svgs/lineDashed.svg';
import * as Style from './styles';

interface ContentCardProps {
  content: ContentAnnotation;
  refreshAnnotations: () => void;
  trailId: string;
}

const ContentCard: React.FC<ContentCardProps> = ({
  content,
  refreshAnnotations,
  trailId,
}) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <Style.Container>
      <Style.Header isActive={isActive} onClick={() => setIsActive(!isActive)}>
        <NotMobile>
          <BsChevronDown size={14} className={'chevron'} />
        </NotMobile>
        <Style.Title>{content.title}</Style.Title>
        <Mobile>
          <BsChevronDown
            size={14}
            className={'chevron'}
            color={'var(--lightBlue)'}
          />
        </Mobile>
      </Style.Header>

      {isActive && (
        <Style.List>
          <Style.ContainerLineDashed>
            <Line />
          </Style.ContainerLineDashed>
          {content.annotations.map(annotation => (
            <Annotation
              key={annotation.annotation_id}
              annotation={{ ...(annotation || {}) }}
              refreshAnnotations={refreshAnnotations}
              content={content}
              trailId={trailId}
            />
          ))}
        </Style.List>
      )}
    </Style.Container>
  );
};

export default ContentCard;
