import { useHistory } from 'react-router-dom';

import Category from '../../models/category';
import useDragDetection from '../../hooks/useDragDetection';
import CategoryDefaultThumbnail from '../CategoryThumbnail';
import * as Style from './styles';

interface CategoryCardProps {
  category: Category;
}

const CategoryCard: React.FC<CategoryCardProps> = ({ category }) => {
  const history = useHistory();
  const { handleMouseDown, dragging } = useDragDetection();

  function handleChildClick(
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ): void {
    if (dragging) {
      e.stopPropagation();
    }
  }

  const goToCategory = () => {
    history.push(`/channels/${category.id}`);
  };

  return (
    <Style.Container
      onClick={goToCategory}
      onMouseDownCapture={handleMouseDown}
      onClickCapture={handleChildClick}
    >
      {category.thumbnail ? (
        <Style.CategoryThumbnail
          src={category.thumbnail}
          alt={category.title}
        />
      ) : (
        <CategoryDefaultThumbnail title={category.title} />
      )}
    </Style.Container>
  );
};

export default CategoryCard;
