import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';
import { ReactComponent as Ring } from '../../assets/svgs/category-ring.svg';
import { CategoriesContext } from '../../contexts/CategoriesContext';
import { LanguageContext } from '../../contexts/LanguageContext';
import * as Style from './styles';

const Channels: React.FC = () => {
  const { categories } = useContext(CategoriesContext);
  const { dictionary } = useContext(LanguageContext);

  const history = useHistory();

  const goToChannel = (id: string) => {
    history.push(`/channels/${id}`);
  };

  const goToCourses = () => {
    history.push(`/courses`);
  };

  return (
    <Style.Container>
      <Style.CategoriesTitle>
        {dictionary.COMMON.CHANNELS}
        <Style.CloseButton
          id="close-search-button"
          onClick={() => history.goBack()}
        >
          <AiOutlineClose size={20} color={'var(--white)'} />
        </Style.CloseButton>
      </Style.CategoriesTitle>
      <Style.CategoriesNav>
        <Style.CategoriesList>
          {categories.map(category => (
            <Style.Category
              key={category.id}
              onClick={() => goToChannel(category.id)}
              color={category.info.color}
            >
              <Ring />
              {category.title}
            </Style.Category>
          ))}
          <Style.AllCoursesButton onClick={goToCourses}>
            {dictionary.COURSE.ALL_CONTENTS}
          </Style.AllCoursesButton>
        </Style.CategoriesList>
      </Style.CategoriesNav>
    </Style.Container>
  );
};

export default Channels;
