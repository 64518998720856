import { AllAnnotation } from '../models/all-annotation';

const convertAllAnnotation = (content: any): AllAnnotation => {
  return {
    id: content.content_id ? content.content_id : content.trail_id,
    trailId: content.trail_id,
    title: content.name ? content.name : content.title,
    category: content.category_title,
    contents: content.contents || [],
    annotations: content.annotations || [],
  };
};

export default convertAllAnnotation;
