import styled, { css } from 'styled-components';
interface FluxIndicatorProps {
  hasFinished: boolean;
  isSelected: boolean;
}

interface ContainerProps {
  isSelected: boolean;
}

interface TitleProps {
  isSelected: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  align-items: center;
  color: var(--text);
  padding: 0 10px;
  background: ${({ isSelected }) => (isSelected ? '#121241' : '#0C0C33;')};
  cursor: pointer;
  min-height: 60px;
`;

export const Duration = styled.div`
  font-size: 1.4rem;
  line-height: 20px;
  color: var(--neutralLight40);

  @media (max-width: 768px) {
    font-size: 1.2rem;
    line-height: 12px;
    letter-spacing: 0.4px;
  }
`;

export const Title = styled.div<TitleProps>`
  font-size: 14px;
  margin: 0 10px;
  flex-grow: 1;
  padding: 10px 0;
  color: #ffffff;
  font-weight: ${({ isSelected }) => (isSelected ? '700' : '400')};

  @media (max-width: 768px) {
    font-size: 1.2rem;
    line-height: 16px;
    letter-spacing: 0.4px;
  }
`;

export const FluxIndicatorContainer = styled.div`
  max-height: 80px;
  height: 100%;
  width: 45px;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const FluxIndicator = styled.div<FluxIndicatorProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid var(--neutralLight40);

  svg {
    position: relative;
    left: ${({ hasFinished }) => (hasFinished ? '0' : '2px')};
  }

  ${({ isSelected, hasFinished }) =>
    isSelected
      ? css`
          border-color: ${hasFinished ? 'var(--green)' : 'var(--lightBlue)'};
          background-color: none;

          svg {
            color: ${hasFinished ? 'var(--green)' : 'var(--lightBlue)'};
            fill: ${hasFinished ? 'var(--green)' : 'var(--lightBlue)'};
          }
        `
      : css`
          border-color: ${hasFinished
            ? 'var(--green)'
            : 'var(--neutralDark500)'};
          background-color: none;
          svg {
            color: ${hasFinished ? 'var(--green)' : 'var(--neutralDark500)'};
            fill: ${hasFinished ? 'var(--green)' : 'none'};
          }
        `};

  &:before {
    content: '';
    height: 20px;
    position: absolute;
    bottom: 97%;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    background-color: rgba(66, 82, 110, 0.64);
  }
  &:after {
    content: '';
    height: 20px;
    position: absolute;
    top: 97%;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    background-color: rgba(66, 82, 110, 0.64);
  }

  &:first-child:not(:last-child) {
    & {
      &:before {
        content: none;
      }
    }
  }
  &:last-child:not(:first-child) {
    & {
      &:after {
        content: none;
      }
    }
  }
  &:only-child {
    & {
      &:before {
        content: none;
      }
      &:after {
        content: none;
      }
    }
  }
`;

export const FluxBar = styled.div`
  width: 2px;
  border: solid 1px rgba(66, 82, 110, 0.64);
  flex-grow: 1;
`;
