import httpClient from '../../http-client';
import Live from '../../models/lives';

const getCurrentLives = async () => {
  return (await httpClient.get<Live[]>('/lives/in-live')).data;
};

const getNextLives = async () => {
  return (await httpClient.get<Live[]>('/lives/next')).data;
};

const getPreviousLives = async (filters?: Object) => {
  return (
    await httpClient.get<Live[]>('/lives', {
      params: {
        ...filters,
        status: 'STOPPED',
      },
    })
  ).data;
};

export { getCurrentLives, getNextLives, getPreviousLives };
