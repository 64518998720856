import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FiSearch } from 'react-icons/fi';

import Logo from '../../assets/logo-aluflix.png';

import Menu from '../Menu';

import ProfileDropdown from './components/ProfileDropdown';
import SearchInput from '../Inputs/SearchInput';
import Banner from '../../pages/Home/components/Banner';
import LanguageSelectorImage from './components/LanguageSelectorImage';

import PerfilAvatar from '../../assets/profile.png';
import { Mobile, NotMobile } from '../Responsive';
import * as Style from './styles';

const Header: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const isHomePage = location.pathname === '/home';
  const [profileIsOpen, setProfileIsOpen] = useState(false);

  return (
    <Style.HeaderContainer>
      <Style.HeaderComponents>
        <Link id="home-button" to="/home">
          <img src={Logo} alt="Logo" />
        </Link>

        <NotMobile>
          <Menu />

          <Style.HeaderUtilities>
            <SearchInput />
          </Style.HeaderUtilities>
        </NotMobile>

        <Style.ButtonsWrapper>
          <LanguageSelectorImage />
          <NotMobile>
            <Style.ProfileContainer
              profileIsOpen={profileIsOpen}
              onClick={() => setProfileIsOpen(!profileIsOpen)}
            >
              <img src={PerfilAvatar} alt="Perfil" />
              <ProfileDropdown setProfileIsOpen={setProfileIsOpen} />
            </Style.ProfileContainer>
          </NotMobile>
        </Style.ButtonsWrapper>
        <Mobile>
          <Style.SearchIcon
            id="search-button"
            onClick={() => history.push('/search-mobile')}
          >
            <FiSearch size={20} color="white" />
          </Style.SearchIcon>
        </Mobile>
      </Style.HeaderComponents>
      {isHomePage && <Banner />}
    </Style.HeaderContainer>
  );
};

export default Header;
