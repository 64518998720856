import React, { useContext, useState } from 'react';
import {
  AiOutlineClockCircle,
  AiOutlineLike,
  AiOutlineUser,
} from 'react-icons/ai';
import { BsBookmark, BsPlay } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  addContentBookmark,
  removeContentBookmark,
} from '../../services/bookmark';

import DefaultThumbnail from '../../assets/default-card-thumbnail.png';

import { secondsToTimeText } from '../../helpers/time';

import { LanguageContext } from '../../contexts/LanguageContext';

import OngoingCourse from '../../models/from-api/ongoing-course';
import * as Style from './styles';
import ProgressBar from '../ProgressBar';
import { Mobile } from '../Responsive';

interface CourseCardProps {
  course: OngoingCourse;
}

const CardCourseOngoing: React.FC<CourseCardProps> = ({ course }) => {
  const history = useHistory();
  const { dictionary } = useContext(LanguageContext);
  const [isAddedToList, setIsAddedToList] = useState(course.book_mark);

  const addToList = async (event: React.MouseEvent) => {
    event.stopPropagation();
    try {
      if (isAddedToList) {
        await removeContentBookmark(course.content_id);
      } else {
        await addContentBookmark(course.content_id);
      }
      setIsAddedToList(!isAddedToList);
    } catch (e) {
      toast.error('Erro ao adicionar conteúdo aos favoritos.');
    }
  };

  const goToContent = () => {
    history.push(`/courses/${course.content_id}`);
  };

  const convertToTimeText = (remainingTime: number) => {
    const hours = Math.floor(remainingTime / 3600);
    const minutes = Math.floor((remainingTime % 3600) / 60);

    return `${hours > 0 ? `${hours} h` : ''} ${minutes} min`;
  };

  return (
    <Style.Container onClick={goToContent}>
      <Style.CourseImageContainer>
        <Mobile>
          <span>
            <BsPlay />
          </span>
        </Mobile>
        <img src={course.thumbnail || DefaultThumbnail} alt="Trail" />
      </Style.CourseImageContainer>
      <Style.CourseBody>
        <Style.CourseUpperContent>
          <Style.CourseCategory>{course.category_name[0]}</Style.CourseCategory>
          <Style.BookmarkButton
            className={`${isAddedToList ? 'added-to-list' : ''}`}
            onClick={e => addToList(e)}
          >
            <BsBookmark
              size={20}
              color={`${isAddedToList ? '#FFF' : '#6B778C'}`}
            />
          </Style.BookmarkButton>
        </Style.CourseUpperContent>
        <Style.CourseLowerContent>
          <Style.CourseTitle>{course.name}</Style.CourseTitle>
          <Style.CourseAuthor>
            <AiOutlineUser size={16} color={'var(--neutralDark900)'} />
            {dictionary.COMMON.AUTHOR}: {course.author_name[0]}
          </Style.CourseAuthor>
          <Style.CourseFooter>
            <Style.CourseTime>
              <AiOutlineClockCircle size={16} color={'var(--neutralDark900)'} />
              {secondsToTimeText(course.time_remaining)}
            </Style.CourseTime>
            <Style.CourseLikes>
              <AiOutlineLike size={16} color={'var(--neutralDark900)'} />
              {`${course.likes.count} ${
                course.likes.count === 1
                  ? dictionary.COMMON.LIKE
                  : dictionary.COMMON.LIKES
              }`}
            </Style.CourseLikes>
          </Style.CourseFooter>
          <Style.RemainingTime>
            <AiOutlineClockCircle size={13} color={'var(--neutralDark900)'} />
            {dictionary.CARD.REMAINING_TIME}:{' '}
            {convertToTimeText(course.time_remaining)}
          </Style.RemainingTime>

          <ProgressBar
            darkenProgress
            completionPercentage={Math.floor(course.progress)}
          />
        </Style.CourseLowerContent>
      </Style.CourseBody>
    </Style.Container>
  );
};

export default CardCourseOngoing;
