import styled from 'styled-components';

interface ContentCardProps {
  isActive: boolean;
}

export const Container = styled.div`
  border: 1px solid var(--neutralLight40);
  border-radius: 4px;
  background-color: var(--neutralLight10);
  margin-top: 21px;
  max-width: 830px;
  width: 100%;

  & + & {
    margin-top: 16px;
  }

  &:last-child {
    margin-bottom: 33px;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    background-color: transparent;
    border: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Header = styled.div<ContentCardProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 13px 24px;
  border-bottom: 1px solid var(--neutralLight30);

  svg.chevron {
    flex-shrink: 0;
    stroke-width: 1px;
    margin-right: 21px;
    transform: rotate(${({ isActive }) => (isActive ? '180deg' : '0')});
    transition: transform 0.2s linear;
  }

  @media (max-width: 768px) {
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 5px;
    border-bottom: none;
    border-left: ${({ isActive }) =>
      isActive ? '.5px solid var(--neutralLight40)' : 'none'};
  }
`;

export const Title = styled.p`
  color: var(--neutralDark900);
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 24px;

  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 16px;
    letter-spacing: 1.5px;
    font-weight: 500;
    color: var(--neutralLight40);
  }
`;

export const GoToContent = styled.div`
  margin-left: auto;

  a {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 16px;
    color: var(--purple);
    text-decoration: none;

    svg {
      flex-shrink: 0;
      margin-left: 10px;
      stroke-width: 2px;
    }
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px 18px 18px 60px;

  @media (max-width: 768px) {
    padding-left: 8px;
    justify-items: center;
  }
`;

export const ContainerLineDashed = styled.div`
  @media (max-width: 768px) {
    width: 8px;
    margin-left: -8px;
    background: transparent;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: hidden;
    position: absolute;
  }
`;
