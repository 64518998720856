import Annotation from '../models/annotation';
import AnnotationFromResponse from '../models/from-api/annotation';

const convertAnnotation = (annotation: AnnotationFromResponse): Annotation => {
  return {
    id: annotation.annotation_id,
    title: annotation.title,
    description: annotation.description,
    contentId: annotation.content_id,
    trailId: annotation.trail_id || null,
    date: annotation.created_at ? new Date(annotation.created_at) : new Date(),
  };
};

export default convertAnnotation;
