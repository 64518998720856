import React, { useContext } from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer';

import certificate from '../assets/images/template.png';

// @ts-ignore
import NunitoLight from '../assets/fonts/NunitoSans-Light.ttf';

// @ts-ignore
import NunitoSemiBold from '../assets/fonts/NunitoSans-SemiBold.ttf';

import { CertificateProps } from '../../services/certificate';
import { LanguageContext } from '../../contexts/LanguageContext';

Font.register({
  family: 'Nunito Light',
  src: NunitoLight,
});

Font.register({
  family: 'Nunito SemiBold',
  src: NunitoSemiBold,
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  pageBackground: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  lowerContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '38%',
    marginTop: '130pt',
    marginRight: '95pt',
    lineHeight: '1.6pt',
  },
  weCertify: {
    fontFamily: 'Nunito Light',
    fontSize: '12pt',
  },
  boldText: {
    fontFamily: 'Nunito SemiBold',
  },
  congratulations: {
    marginTop: '25pt',
    fontFamily: 'Nunito Light',
    fontSize: '12pt',
  },
});

interface TemplateProps {
  data: CertificateProps;
}

const Template: React.FC<TemplateProps> = ({ data }) => {
  const { language } = useContext(LanguageContext);

  const formattedDate = new Intl.DateTimeFormat(language, {
    dateStyle: 'long',
  }).format(new Date(data.finish_at));

  function SecondsToHms(d: number) {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);

    const hDisplay = h > 0 ? h + (h === 1 ? ' hora e ' : ' horas e ') : '';
    const mDisplay = m > 0 ? m + (m === 1 ? ' minuto' : ' minutos') : '';
    return hDisplay + mDisplay;
  }

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <Image src={certificate} style={styles.pageBackground} />

        <View style={styles.lowerContent}>
          <Text style={styles.weCertify}>
            Certificamos que{' '}
            <Text style={styles.boldText}>{data.user_name}</Text> concluiu o
            curso <Text style={styles.boldText}>{data.name}</Text> do aglomerado{' '}
            <Text style={styles.boldText}>{data.category}</Text> da ALU em{' '}
            <Text style={styles.boldText}>{formattedDate}</Text> com duração de{' '}
            <Text style={styles.boldText}>
              {SecondsToHms(parseInt(data.duration))}
            </Text>
            .
          </Text>

          <Text style={styles.congratulations}>
            Parabéns por mais uma conquista!
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default Template;
