import styled, { css } from 'styled-components';
import { isInWebview } from '../../../../../../helpers/reactNativeWebView';
import { Link } from 'react-router-dom';

const webviewStyles = css`
  visibility: visible;
`;

const normalStyle = css`
  visibility: hidden;
`;

const LinkStyle = css`
  display: flex;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--purple);
  line-height: 16px;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1.25px;

  svg {
    margin-right: 10px;
    path {
      stroke: var(--purple);
    }
  }
  @media (max-width: 768px) {
    color: var(--lightBlue);
    font-weight: 400;

    svg {
      path {
        stroke: var(--white);
      }
    }
  }
`;

export const Container = styled.nav`
  display: flex;
  flex-direction: column;
  height: calc(100% - 63px);
  justify-content: flex-start;
  position: relative;
`;

export const OptionLink = styled(Link)`
  ${isInWebview ? webviewStyles : normalStyle}
  ${LinkStyle}
  width: 100%;
  & + * {
    margin-top: 18px;
    padding-top: 18px;
    border-top: 1px solid var(--purple10);
  }
  svg path {
    stroke: var(--lightBlue);
  }
`;

export const OptionLinkProfile = styled(Link)`
  ${isInWebview ? webviewStyles : normalStyle}
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  p {
    font-size: 1.4rem;
    font-weight: 400;
    letter-spacing: 0.25px;
    color: var(--neutralLight40);
    line-height: 20px;
    margin-top: 8px;
    margin-bottom: 24px;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 54px;
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 24px;
    background-color: var(--neutralDark500);
    color: var(--white);
  }
`;

export const OptionLinkMyCourses = styled(Link)`
  ${LinkStyle}

  color: var(--white) !important;
  @media (max-width: 768px) {
    position: absolute;
  }
  & + * {
    margin-top: 18px;
    padding-top: 18px;
    border-top: 1px solid var(--purple10);
  }
`;

export const LogoutLink = styled.a`
  ${LinkStyle}
  color: var(--white) !important;
  margin-top: auto;
  padding-top: 0;
  border-top: none;
`;

export const LanguageLink = styled.div`
  ${LinkStyle}
  flex-direction: column;
  align-items: flex-start;
  color: var(--neutralLight40) !important;
  @media (max-width: 768px) {
    position: absolute;
    top: 50px;
  }
  p {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
  }
`;
