import React, { useState, useEffect, useContext } from 'react';
import BackButton from '../../../../components/Buttons/BackButton';
import CourseCard from '../../../../components/CourseCard';
import CourseCardSkeleton from '../../../../components/CourseCardSkeleton';
import Pagination from '../../../../components/Pagination';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import ContentCard from '../../../../models/content-card';
import { getContentCards } from '../../../../services/content';
import { ReactComponent as Sliders } from '../../../../assets/svgs/Sliders.svg';

import * as Style from './styles';
import { FiSearch } from 'react-icons/fi';
import { isInWebview } from '../../../../helpers/reactNativeWebView';

const FavoriteCourses: React.FC = () => {
  const { dictionary } = useContext(LanguageContext);
  const [contents, setContents] = useState([] as ContentCard[]);
  const [shouldShowSkeleton, setShouldShowSkeleton] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [totalShowing, setTotalShowing] = useState(0);
  const [count, setCount] = useState(0);

  const contentsPerPage = 8;
  const VALUE_TO_SHOW = 3;
  const filter = { book_mark: true };

  useEffect(() => {
    (async () => {
      const allContents = await getContentCards(filter);

      if (allContents && allContents.length) {
        setTotalPages(Math.ceil(allContents.length / contentsPerPage));
      } else {
        setShouldShowSkeleton(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (totalShowing <= contents.length) {
      return setTotalShowing(contentsPerPage + count * VALUE_TO_SHOW);
    } else {
      return setTotalShowing(contents.length);
    }
  }, [count]);

  const filteredContents = contents.filter(content =>
    content.title
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()),
  );

  useEffect(() => {
    (async () => {
      setContents([]);
      setShouldShowSkeleton(true);
      const currentContents = await getContentCards({
        ...filter,
        limit: contentsPerPage,
        offset: page > 1 ? (page - 1) * contentsPerPage : undefined,
      });

      if (currentContents && currentContents.length) {
        setContents(currentContents);
      } else {
        setShouldShowSkeleton(false);
      }
    })();
  }, [page]);

  return (
    <>
      <Style.Container>
        {isInWebview ? (
          <>
            <Style.TitleWebView>
              {dictionary.MY_COURSES.CONTENTS_MARKED_AS_FAVORITE}
              <br />
              <span>({contents.length}) Vídeos</span>
            </Style.TitleWebView>
            <Style.StyledInput>
              <span className="iconSliders">
                <Sliders />
              </span>

              <input
                type="text"
                name="search"
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
                placeholder={dictionary.MY_COURSES.SEARCH_IN_FAVORITES}
                required
              />
              <FiSearch size={20} />
            </Style.StyledInput>{' '}
          </>
        ) : null}
        <Style.Title>{dictionary.MY_COURSES.FAVORITE_COURSES}</Style.Title>

        <Style.ContentList>
          {filteredContents && filteredContents.length ? (
            filteredContents
              .slice(0, totalShowing)
              .map(content => <CourseCard key={content.id} content={content} />)
          ) : shouldShowSkeleton ? (
            Array(8)
              .fill('')
              .map((i, index) => <CourseCardSkeleton key={index} />)
          ) : (
            <Style.NotFound>{dictionary.COMMON.NOT_FOUND}</Style.NotFound>
          )}
        </Style.ContentList>
        {totalPages > 1 && (
          <Pagination totalPages={totalPages} setPage={setPage} />
        )}
      </Style.Container>

      {!searchValue.length && (
        <Style.ButtonWrapper>
          {totalShowing < contents.length && (
            <Style.ShowMoreButton onClick={() => setCount(count + 1)}>
              {dictionary.MY_COURSES.VIEW_MORE}
            </Style.ShowMoreButton>
          )}
        </Style.ButtonWrapper>
      )}
    </>
  );
};

export default FavoriteCourses;
