import download from 'downloadjs';
import {
  isInWebview,
  downloadToWebView,
} from '../../helpers/reactNativeWebView';
import httpClient from '../../http-client';
import { generateCertificate } from '../../pdf/generateCertificate';

export interface CertificateProps {
  certificate_id: string;
  emmited_at: string;
  duration: string;
  finish_at: string;
  reference_id: string;
  name: string;
  category: string;
  user_name: string;
  type: string;
  author?: string;
  thumbnail?: string;
}

export const downloadCertificate = async (referenceId: string) => {
  let result = {} as CertificateProps;

  try {
    result = (
      await httpClient.get<CertificateProps>(`/certificates/${referenceId}`)
    ).data;

    const certificate = await generateCertificate(result);
    isInWebview
      ? downloadToWebView(certificate)
      : download(certificate, 'certificado');
  } catch (error: any) {
    console.error(error);
  }
};

export const getCertificates = async (
  filters?: Object,
): Promise<CertificateProps[]> => {
  const certificates = (
    await httpClient.get('/certificates', { params: filters })
  ).data;

  return certificates;
};

export const postCertificate = async (referenceId: string) => {
  let result = {} as CertificateProps;

  try {
    if (window.location.href.endsWith(`courses/${referenceId}`)) {
      await httpClient.post(`/certificates/`, { content_id: referenceId });
    }

    await httpClient.post(`/certificates/`, { trail_id: referenceId });

    result = (
      await httpClient.get<CertificateProps>(`/certificates/${referenceId}`)
    ).data;
  } catch (error: any) {
    if (error.response.status === 400) {
      result = (
        await httpClient.get<CertificateProps>(`/certificates/${referenceId}`)
      ).data;
    }
  }
};
