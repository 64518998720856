import httpClient from '../../http-client';

const sendEmail = async (subject: string, message: string) => {
  await httpClient.post('/faqs/mail', {
    subject,
    text: message,
  });
};

export { sendEmail };
