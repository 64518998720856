import React from 'react';
import { GrPrevious } from 'react-icons/gr';

import { Container } from './styles';

interface IPrevButtonProps {
  onClick?: () => void;
  className?: string;
}

const SliderPrevButton: React.FC<IPrevButtonProps> = ({
  onClick,
  className,
}) => {
  return (
    <Container onClick={onClick} className={className}>
      <GrPrevious size={24} />
    </Container>
  );
};

export default SliderPrevButton;
