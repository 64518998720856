import styled, { css } from 'styled-components';
import { isInWebview } from '../../../../../../helpers/reactNativeWebView';

export const webviewStyles = css`
  height: 100vh;
  width: 100vw;
  background-color: black;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
`;

export const PlayerContainer = styled.div`
  ${isInWebview && webviewStyles}
`;
