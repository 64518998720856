import styled, { css } from 'styled-components';
import { isInWebview } from './../../../../helpers/reactNativeWebView';

const webviewStyles = css<{ vertical?: boolean }>`
  overflow: scroll;
  display: flex;
  ${({ vertical }) =>
    vertical
      ? css`
          flex-wrap: wrap;
          flex-direction: row;
          & > div {
            max-width: 46%;
          }
        `
      : css`
          flex-direction: row;
        `}
`;

const normalStyle = css`
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;
  flex-wrap: nowrap;
  padding-left: 16px;
`;

export const Container = styled.section`
  padding: 32px 0 32px;
  max-width: 1424px;
  margin: auto;
  position: relative;

  @media (max-width: 768px) {
    padding: 48px 0 24px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;

  @media (max-width: 768px) {
    padding: 0 16px;
    align-items: flex-start;
    margin-bottom: 0;
  }
`;

export const Title = styled.h2`
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 40px;
  color: #ffffff;
  margin-bottom: 36px;

  @media (max-width: 768px) {
    font-size: 1.6rem;
    line-height: 24px;
    margin-bottom: 16px;
  }
`;

export const TitleWebView = styled.h1`
  font-weight: 500;
  font-size: 2rem;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-bottom: 32px;
  color: var(--white);
  span {
    font-size: 1.2rem;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: var(--neutralLight40);
    opacity: 0.6;
  }
`;
interface ContentListProps {
  isProfileOngoingCourses?: boolean;
  vertical?: boolean;
}

export const ContentList = styled.div<ContentListProps>`
  display: grid;
  gap: 16px;
  grid-template-columns: ${({ isProfileOngoingCourses }) =>
    isProfileOngoingCourses
      ? ' repeat(auto-fill, minmax(223px, 1fr))'
      : 'repeat(auto-fill, minmax(279px, 1fr))'};

  @media (max-width: 768px) {
    ${isInWebview ? webviewStyles : normalStyle};
    gap: 8px;
    padding-bottom: 16px;

    & > div:last-child {
      margin-right: 16px;
    }
  }
`;

export const NotFound = styled.p`
  font-weight: 500;
  font-size: 1.8rem;
  color: var(--neutralLight40);
`;

export const ViewMoreButton = styled.div`
  padding-bottom: 8px;
  color: var(--lightBlue);
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 18px;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  border: none;
  position: relative;

  &:after {
    content: '';
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    position: absolute;
    transition: all 0.3s ease-in-out;
    background: var(--blue);
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: #ffffff;
      &:after {
        width: 10%;
        background: #ffffff;
      }
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 2px;
    margin-top: 6px;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 16px;
    border-width: 1px;
  }
`;

export const StyledInput = styled.div`
  z-index: 1;

  position: relative;

  input {
    width: 100%;
    min-width: 280px;
    height: 40px;
    outline: none;
    border: none;
    border-radius: 50px;

    background: #f2f2f2;
    border: 1px solid #e6e6e6;
    padding: 9px 16px;
    font-size: 1.3rem;
    font-weight: 400;
  }

  svg {
    position: absolute;
    top: 10px;
    right: 16px;
    color: var(--gray);
  }

  svg.iconSliders {
    display: none;
  }

  @media (max-width: 768px) {
    margin-bottom: 32px;
    input {
      width: 340px;
      background: transparent;
      border: 1px solid rgba(66, 82, 110, 0.64);
      color: var(--white);
      padding: 0 36px;

      &::placeholder {
        text-align: center;
        color: var(--neutralDark500);
      }
    }

    svg {
      left: 10px;
      color: var(--neutralDark500);
    }

    span.iconSliders {
      display: flex;
      position: absolute;
      right: 42px;
    }
  }
`;

export const ButtonWrapper = styled.span`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 16px;

  > span {
    font-weight: bold;
    font-size: 1.6rem;
    color: var(--neutralDark900);
  }

  margin: ${isInWebview ? '36px 0' : '0'};
`;

export const ShowMoreButton = styled.button`
  border: none;
  background-color: var(--purple);
  color: white;
  line-height: 16px;
  letter-spacing: 1.5px;
  font-size: 1.4rem;
  font-weight: 500;
  border-radius: 31px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (max-width: 768px) {
    margin-bottom: 100px;
  }
`;
