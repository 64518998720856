import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Mobile, NotMobile } from '../../../../components/Responsive';
import CardCourseOngoing from '../../../../components/CardCourseOngoing';
import CourseCardSkeleton from '../../../../components/CourseCardSkeleton';
import TrailCard from '../../../../components/TrailCard';

import { LanguageContext } from '../../../../contexts/LanguageContext';

import OngoingTrail from '../../../../models/from-api/ongoing-trail';
import OngoingCourse from '../../../../models/from-api/ongoing-course';

import { getOngoingCourse } from '../../../../services/course';
import { getOngoingTrails } from '../../../../services/trail';

import * as Style from './styles';

const OngoingCoursesHome: React.FC = () => {
  const { dictionary } = useContext(LanguageContext);
  const [contents, setContents] = useState([] as OngoingTrail[]);
  const [contentsCourse, setContentsCourse] = useState([] as OngoingCourse[]);
  const [shouldShowSkeleton, setShouldShowSkeleton] = useState(true);
  const [page, setPage] = useState(1);

  const history = useHistory();
  const location = useLocation();
  const contentsPerPage = 4;
  const profileOngoing = history.location.pathname === '/my-courses/ongoing';
  const isHome = location.pathname === '/home';

  useEffect(() => {
    (async () => {
      setContents([]);

      const currentContents = await getOngoingTrails({
        limit: contentsPerPage,
        offset: page > 1 ? (page - 1) * contentsPerPage : undefined,
      });

      if (currentContents && currentContents.length) {
        setContents(currentContents);
      } else {
        setShouldShowSkeleton(false);
      }
    })();
  }, [page]);

  useEffect(() => {
    (async () => {
      setContentsCourse([]);

      const currentContents = await getOngoingCourse({
        limit: contentsPerPage,
        offset: page > 1 ? (page - 1) * contentsPerPage : undefined,
      });

      if (currentContents && currentContents.length) {
        setContentsCourse(currentContents);
      }
    })();
  }, [page]);

  return (
    <Style.Container>
      <Style.Header>
        <Style.Title>
          {profileOngoing
            ? dictionary.MY_COURSES.ONGOING_COURSES
            : dictionary.HOME.ONGOING_COURSES}
        </Style.Title>
        <Style.ViewMoreButton
          onClick={() => history.push(`/my-courses/ongoing`)}
        >
          <NotMobile>{dictionary.HOME.SEE_ALL_CONTENTS}</NotMobile>
          <Mobile>{dictionary.HOME.SEE_ALL}</Mobile>
        </Style.ViewMoreButton>
      </Style.Header>

      <Style.ContentList
        isProfileOngoingCourses={profileOngoing}
        isHome={isHome}
      >
        {contents && contents.length ? (
          contents.map(content => (
            <TrailCard key={content.trail_id} trail={content} />
          ))
        ) : shouldShowSkeleton ? (
          Array(4)
            .fill('')
            .map((i, index) => <CourseCardSkeleton key={index} />)
        ) : (
          <Style.NotFound>{dictionary.COMMON.NOT_FOUND}</Style.NotFound>
        )}
        {contentsCourse && contentsCourse.length
          ? contentsCourse.map(content => (
              <CardCourseOngoing key={content.content_id} course={content} />
            ))
          : null}
      </Style.ContentList>
    </Style.Container>
  );
};

export default OngoingCoursesHome;
