import React, { useContext, useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { SearchContext } from '../../../contexts/SearchContext';
import { SearchOverlayContext } from '../../../contexts/SearchOverlayContext';

import * as Style from './styles';

const SearchInput: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const [inputValue, setInputValue] = useState('');
  const { setSearchParam } = useContext(SearchContext);
  const { setIsOpen } = useContext(SearchOverlayContext);
  const { dictionary } = useContext(LanguageContext);

  const handleChange = (e: React.FormEvent) => {
    e.preventDefault();
    setSearchParam(inputValue);
    setIsOpen(false);

    if (location.pathname !== '/search') {
      history.push('/search');
    }
  };

  useEffect(() => {
    if (location.pathname !== '/search') {
      setInputValue('');
    }
  }, [location.pathname]);

  return (
    <Style.Container>
      <form onSubmit={e => handleChange(e)}>
        <input
          type="text"
          name="search"
          onChange={e => setInputValue(e.target.value)}
          value={inputValue}
          placeholder={dictionary.SEARCH.PLACEHOLDER}
          required
        />
        <button type="submit">
          <FiSearch size={20} color="var(--gray)" />
        </button>
      </form>
    </Style.Container>
  );
};

export default SearchInput;
