import React, { useMemo, useState } from 'react';
import { useEffect } from 'react';
import ProgressBar from '../../../../components/ProgressBar';
import Content from '../../../../models/content';
import Course from '../../../../models/course';
import Trail from '../../../../models/trail';
import TrailCourse from './components/TrailCourse';
import * as Style from './style';

interface TrailCoursesProps {
  trail: Trail;
  courses: Course[];
  currentContent: Content;
  contentList: Content[];
}

const TrailCourses: React.FC<TrailCoursesProps> = ({
  trail,
  courses,
  currentContent,
  contentList,
}) => {
  const [height, setHeight] = useState<number>(800);

  const progress = useMemo(() => {
    if (trail && trail.courses && trail.courses.length && contentList) {
      const numberOfContents = contentList.length;
      const numberOfFinishedContents = contentList.filter(
        content => content.alreadyFinished,
      ).length;

      return (numberOfFinishedContents / numberOfContents) * 100;
    }

    return 0;
  }, [trail, contentList]);

  const [moduleOpen, setModuleOpen] = useState<number>(0);

  const handleOpenAccordion = (index: number) => {
    setModuleOpen(index);
  };

  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById('trailContentId');
      setHeight(element?.clientHeight || 804);
    }, 1000);
  }, []);

  return (
    <Style.Container height={height}>
      {trail.alreadyStarted && <ProgressBar completionPercentage={progress} />}

      <Style.TrailCourses>
        {courses &&
          courses.length &&
          courses.map((course, index) => (
            <TrailCourse
              key={course.id}
              currentContent={currentContent}
              course={course}
              onOpen={() => handleOpenAccordion(index)}
              close={moduleOpen !== index}
              // showInitButton={index === 0}
            />
          ))}
      </Style.TrailCourses>

      <Style.Line />
    </Style.Container>
  );
};

export default TrailCourses;
