import styled from 'styled-components';

export const Container = styled.div`
  z-index: 1;
  width: 100%;

  form {
    margin: 0 auto;
    max-width: 570px;
    min-width: 370px;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    background: #f2f2f2;
    border: 1px solid #e6e6e6;
    border-radius: 50px;
    padding-right: 18px;

    @media (max-width: 768px) {
      min-width: auto;
      border: 1px solid rgba(66, 82, 110, 0.64);
      background: rgba(49, 52, 139, 0.08);
    }

    input {
      width: 100%;
      height: 100%;
      outline: none;
      border: none;
      border-radius: 50px;
      background: transparent;
      padding: 9px 16px;
      font-size: 1.3rem;
      font-weight: 400;
      @media (max-width: 768px) {
        color: var(--white);
      }
    }

    button {
      cursor: pointer;
      height: 100%;
      border: none;
      outline: none;
      background: none;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;
