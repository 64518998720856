import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { AiOutlineClockCircle, AiOutlineLike } from 'react-icons/ai';
import { BsBookmark } from 'react-icons/bs';

import { Mobile, NotMobile } from '../Responsive';

import {
  addContentBookmark,
  addTrailBookmark,
  removeContentBookmark,
  removeTrailBookmark,
} from '../../services/bookmark';
import { LanguageContext } from '../../contexts/LanguageContext';
import useDragDetection from '../../hooks/useDragDetection';
import { secondsToTimeText } from '../../helpers/time';
import ContentCard from '../../models/content-card';
import * as Style from './styles';

interface BannerProps {
  content: ContentCard;
}

const BannerCard: React.FC<BannerProps> = ({ content }) => {
  const history = useHistory();
  const { dictionary } = useContext(LanguageContext);
  const [isAddedToList, setIsAddedToList] = useState(content.addedToList);
  const { handleMouseDown, dragging } = useDragDetection();

  function handleChildClick(
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ): void {
    if (dragging) {
      e.stopPropagation();
    }
  }

  const addToList = async (event: React.MouseEvent) => {
    event.stopPropagation();
    if (content) {
      try {
        if (isAddedToList) {
          if (content.type === 'TRAIL') {
            await removeTrailBookmark(content.id);
          } else {
            await removeContentBookmark(content.id);
          }
        } else {
          if (content.type === 'TRAIL') {
            await addTrailBookmark(content.id);
          } else {
            await addContentBookmark(content.id);
          }
        }
        setIsAddedToList(!isAddedToList);
      } catch (e) {
        toast.error(dictionary.ERROR.BOOKMARK);
      }
    }
  };

  const goToContent = () => {
    if (content.type === 'CONTENT') {
      history.push(`/courses/${content.id}`);
    } else {
      history.push(`/trails/${content.id}`);
    }
  };

  return (
    <Style.Container
      onClick={goToContent}
      onMouseDownCapture={handleMouseDown}
      onClickCapture={handleChildClick}
    >
      <Style.CourseDetails>
        <Style.BookmarkButton
          onClick={e => addToList(e)}
          isAddedToList={isAddedToList}
        >
          <NotMobile>
            <BsBookmark size={32} color="#FFF" />
          </NotMobile>
          <Mobile>
            <BsBookmark size={18} color="#FFF" />
          </Mobile>
        </Style.BookmarkButton>
        <Style.CourseTitle>{content.title}</Style.CourseTitle>
        <Style.CourseData>
          <Style.CourseInfo>
            <NotMobile>
              <AiOutlineClockCircle size={24} />
            </NotMobile>
            <Mobile>
              <AiOutlineClockCircle size={16} />
            </Mobile>
            {secondsToTimeText(content.duration, false)}
            <Mobile>
              <Style.CourseLikes>
                <AiOutlineLike size={16} />
                {`${content.numberOfLikes} ${
                  content.numberOfLikes === 1
                    ? dictionary.COMMON.LIKE
                    : dictionary.COMMON.LIKES
                }`}
              </Style.CourseLikes>
            </Mobile>
          </Style.CourseInfo>
        </Style.CourseData>
      </Style.CourseDetails>
      <Mobile>
        <img src={content.thumbnail} alt={content.title} />
      </Mobile>
      <NotMobile>
        <img src={content.banner} alt={content.title} />
      </NotMobile>
    </Style.Container>
  );
};

export default BannerCard;
