import styled from 'styled-components';

export const Container = styled.div``;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  input,
  textarea {
    font-size: 1.6rem;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    outline: none;
    border: solid 1px #7a869a;
  }

  *:not(:first-child) {
    margin-top: 10px;
  }
`;

export const AnnotationList = styled.div`
  margin-top: 35px;
`;

export const FormTitle = styled.input``;

export const FormTextArea = styled.textarea`
  height: 80px;
  resize: none;
`;

export const Label = styled.span`
  font-size: 1.6rem;
  color: var(--neutralLight40);
`;

export const SubmitButton = styled.button`
  font-size: 1.6rem;
  background-color: var(--purple);
  color: white;
  outline: none;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
`;

export const Annotation = styled.div`
  &:not(:first-child) {
    border-top: 1px solid var(--neutralLight40);
    padding-top: 30px;
    margin-top: 30px;
  }
`;

export const AnnotationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AnnotationTitle = styled.h3`
  font-size: 1.6rem;
`;

export const AnnotationDate = styled.p`
  font-size: 1.3rem;
  margin-top: 3px;
`;

export const AnnotationContent = styled.p`
  font-size: 1.4rem;
  margin-top: 15px;
`;

export const AnnotationButtonContainer = styled.div``;

export const AnnotationButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background-color: #f0f3f6;
  padding: 2px;
  border-radius: 2px;
  color: #97a0af;

  &:not(:first-child) {
    margin-left: 5px;
  }
`;
