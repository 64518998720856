import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 64px;

  .slick-slider {
    &:nth-child(2) {
      .slick-list {
        padding: 0 7vw;
      }
    }

    @media (max-width: 768px) {
      margin-top: 40px;
      margin-bottom: 24px;

      &:nth-child(2) {
        .slick-list {
          padding-left: 5%;
        }
      }
    }
  }
`;

export const ChannelWrapper = styled.div`
  margin-top: 64px;

  .slick-slide + .slick-slide {
    margin-left: 40px !important;
  }

  .slick-slide {
    width: unset !important;
  }
`;

export const Title = styled.h1`
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-left: 16px;
  color: var(--white);
`;
