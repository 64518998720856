import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import CourseCard from '../../../../components/CourseCard';
import { Mobile, NotMobile } from '../../../../components/Responsive';
import CourseCardSkeleton from '../../../../components/Skeleton/CourseCardSkeleton';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import ContentCard from '../../../../models/content-card';
import { getContentCards } from '../../../../services/content';

import * as Style from './styles';

const MostViewedCourses: React.FC = () => {
  const history = useHistory();
  const { dictionary } = useContext(LanguageContext);
  const [mostViewedContents, setMostViewedContents] = useState(
    [] as ContentCard[],
  );
  const [shouldShowSkeleton, setShouldShowSkeleton] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const mostViewedFilters = { limit: 4, most_viewed: true };
        const localCourses = await getContentCards(mostViewedFilters);
        if (localCourses && localCourses.length) {
          setMostViewedContents(localCourses);
        } else {
          setShouldShowSkeleton(false);
        }
      } catch (e) {
        toast.error(dictionary.ERROR.LOAD_CONTENTS);
        setShouldShowSkeleton(false);
      }
    })();
  }, []);

  return (
    <Style.Container>
      <Style.Header>
        <Style.Title>{dictionary.HOME.MOST_VIEWED}</Style.Title>
        <NotMobile>
          <Style.ViewMoreButton onClick={() => history.push(`/courses`)}>
            <NotMobile>{dictionary.HOME.SEE_ALL_CONTENTS}</NotMobile>
            <Mobile>{dictionary.HOME.SEE_ALL}</Mobile>
          </Style.ViewMoreButton>
        </NotMobile>
      </Style.Header>

      <Style.CardList>
        {mostViewedContents && mostViewedContents.length ? (
          mostViewedContents
            .slice(0, 4)
            .map(content => <CourseCard key={content.id} content={content} />)
        ) : shouldShowSkeleton ? (
          Array(4)
            .fill('')
            .map((i, index) => <CourseCardSkeleton key={index} />)
        ) : (
          <Style.NotFound>
            {dictionary.HOME.NOT_FOUND_MOST_VIEWED}
          </Style.NotFound>
        )}
      </Style.CardList>
    </Style.Container>
  );
};

export default MostViewedCourses;
