import Course from '../models/course';
import ContentUserFromResponse from '../models/from-api/content-user';
import CourseFromResponse from '../models/from-api/course';
import CourseUserFromResponse from '../models/from-api/course-user';
import TrailFromResponse from '../models/from-api/trail';
import convertContent from './convert-content';
import convertExtraMaterial from './convert-extra-material';

const findContentUser = (
  contentId: string,
  contentUsers: ContentUserFromResponse[],
): ContentUserFromResponse => {
  return (
    contentUsers.find(content => content.content_id === contentId) ||
    ({} as ContentUserFromResponse)
  );
};

const convertCourse = (
  course: CourseFromResponse,
  courseUser?: CourseUserFromResponse,
  trail?: TrailFromResponse,
): Course => {
  const contentUsers =
    courseUser && courseUser.contents && courseUser.contents.length
      ? courseUser.contents
      : [];

  return {
    id: course.course_id,
    trailId: trail ? trail.trail_id : '',
    title: course.title,
    description: course.description,
    position: course.position,
    thumbnail: course.thumbnail,
    contents:
      course.contents && course.contents.length
        ? course.contents
            .sort((contentA, contentB) =>
              contentA.position > contentB.position ? 1 : -1,
            )
            .map(content =>
              convertContent(
                content,
                findContentUser(content.content_id, contentUsers),
                course,
                trail,
              ),
            )
        : [],
    extraMaterials:
      course.extra_materials && course.extra_materials.length
        ? course.extra_materials.map(convertExtraMaterial)
        : [],
    alreadyStarted: courseUser ? !!courseUser.start_at : false,
    alreadyFinished: courseUser ? !!courseUser.finish_at : false,
    isFirstCourse:
      trail && trail.courses && trail.courses.length
        ? trail.courses[0].course_id === course.course_id
        : false,
    duration:
      course && course.contents && course.contents.length
        ? course.contents
            .map(content => content.duration)
            .reduce((durationA, durationB) => durationA + durationB)
        : 0,
  };
};

export default convertCourse;
