import { useContext, useEffect, useRef } from 'react';
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock';
import Term from './components/Term';
import * as Style from './styles';
import Swal from 'sweetalert2';
import { acceptTerms } from '../../services/user';
import { LanguageContext } from '../../contexts/LanguageContext';

interface TermModalProps {
  userHasAccepted: boolean;
  setUserHasAccepted: React.Dispatch<React.SetStateAction<boolean>>;
}

const TermModal: React.FC<TermModalProps> = ({
  userHasAccepted,
  setUserHasAccepted,
}) => {
  const { dictionary } = useContext(LanguageContext);
  const isSafari = navigator.userAgent.indexOf('Safari') !== -1;
  const isIphone = navigator.userAgent.indexOf('iPhone') !== -1;
  const isMobileIosSafari = isSafari && isIphone;
  const modalRef: any = useRef(null);

  useEffect(() => {
    if (!isMobileIosSafari) {
      userHasAccepted
        ? enableBodyScroll(modalRef.current)
        : disableBodyScroll(modalRef.current);
    }
  }, [userHasAccepted]);

  const onButtonClick = () => {
    (async () => {
      try {
        await acceptTerms(true);
        setUserHasAccepted(true);
        clearAllBodyScrollLocks();
      } catch (error) {
        Swal.fire({
          title: dictionary.MODAL.ERROR,
          text: error.message,
          icon: 'error',
        });
      }
    })();
  };

  return (
    <Style.Container ref={modalRef}>
      <Style.Modal>
        <Style.Body>
          <Term />
        </Style.Body>
        <Style.Button type="button" onClick={onButtonClick}>
          Eu concordo | I agree
        </Style.Button>
      </Style.Modal>
    </Style.Container>
  );
};

export default TermModal;
