import React, { useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import {
  getCourseReview,
  reviewCourse,
  updateCourseReview,
} from '../../../../../../services/course-review';
import DefaultButton from '../../../../../../components/Buttons/DefaultButton';
import { LanguageContext } from '../../../../../../contexts/LanguageContext';
import * as Style from './styles';

interface CourseRateProps {
  courseId: string;
  stars: number;
}

const CourseRate: React.FC<CourseRateProps> = ({ courseId, stars }) => {
  const { dictionary } = useContext(LanguageContext);
  const [defaultStars, setDefaultStars] = useState(stars);
  const [starsValue, setStarsValue] = useState(stars);
  const starsMaxQuantity = 5;

  useEffect(() => {
    (async () => {
      try {
        if (starsValue === 0) return;
        const data = await getCourseReview(courseId);
        setDefaultStars(data.stars);
      } catch (error) {}
    })();
  }, [courseId]);

  const onStarClick = async () => {
    try {
      if (defaultStars) {
        await updateCourseReview(courseId, starsValue);
      } else {
        await reviewCourse(courseId, starsValue);
      }
      setDefaultStars(starsValue);
      Swal.fire({
        title: dictionary.MODAL.SUCCESS,
        text: dictionary.MODAL.RATE.SUCCESS,
      });
    } catch (error) {
      Swal.fire({
        title: dictionary.MODAL.ERROR,
        text: dictionary.MODAL.RATE.ERROR,
      });
    }
  };

  const changeStarQuantity = (newQuantity: number) => {
    setStarsValue(newQuantity);
  };

  return (
    <Style.RateContainer>
      <Style.RateStars>
        {Array(starsMaxQuantity)
          .fill('')
          .map((star, index) => (
            <DefaultButton
              key={index}
              onMouseOver={() => changeStarQuantity(index + 1)}
              onMouseOut={() => changeStarQuantity(defaultStars)}
              onClick={onStarClick}
            >
              {index + 1 <= starsValue ? (
                <AiFillStar color="var(--yellow)" size={21} />
              ) : (
                <AiOutlineStar color="var(--yellow)" size={21} />
              )}
            </DefaultButton>
          ))}
      </Style.RateStars>
    </Style.RateContainer>
  );
};

export default CourseRate;
