import { AiOutlinePlusCircle, AiFillMinusCircle } from 'react-icons/ai';
import Faq from '../../../../models/faq';
import * as Style from './styles';

interface QuestionAndAnswerProps {
  faq: Faq;
  closeInactiveQuestions: (id: string) => void;
}

const QuestionAndAnswer: React.FC<QuestionAndAnswerProps> = ({
  faq,
  closeInactiveQuestions,
}) => {
  const handleFaqClick = () => {
    closeInactiveQuestions(faq.id);
  };

  return (
    <Style.Container>
      <Style.IconContainer>
        {faq.isOpen ? (
          <AiFillMinusCircle
            onClick={handleFaqClick}
            size={22}
            color={'var(--lightBlue)'}
          />
        ) : (
          <AiOutlinePlusCircle
            onClick={handleFaqClick}
            size={22}
            color={'var(--lightBlue)'}
          />
        )}
      </Style.IconContainer>
      <Style.Content>
        <Style.Question onClick={handleFaqClick} isOpen={faq.isOpen}>
          {faq.question}
        </Style.Question>
        {faq.isOpen ? <Style.Answer>{faq.answer}</Style.Answer> : <></>}
      </Style.Content>
    </Style.Container>
  );
};

export default QuestionAndAnswer;
