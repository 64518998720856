import { Link } from 'react-router-dom';
import * as Style from './styles';

interface BreadcrumbProps {
  crumbs: { name: string; path: string }[];
}

const Breadcrumb = ({ crumbs }: BreadcrumbProps) => {
  const isLastCrumb = (index: number) => {
    return index === crumbs.length - 1;
  };

  return (
    <Style.Container>
      <Style.Breadcrumb>
        <Style.BreadcrumbList>
          {crumbs.map((crumb, index) => (
            <Style.Crumb key={index} isDisabled={isLastCrumb(index)}>
              <Link to={crumb.path}>{crumb.name}</Link>
            </Style.Crumb>
          ))}
        </Style.BreadcrumbList>
      </Style.Breadcrumb>
    </Style.Container>
  );
};

export default Breadcrumb;
