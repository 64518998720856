import ExtraMaterial from '../models/extra-material';
import ExtraMaterialFromResponse from '../models/from-api/extra-material';

const convertExtraMaterial = (
  extraMaterial: ExtraMaterialFromResponse,
): ExtraMaterial => {
  return {
    id: extraMaterial.extra_material_id,
    name: extraMaterial.name,
    reference: extraMaterial.reference,
    info: extraMaterial.info,
    createdAt: new Date(extraMaterial.created_at),
  };
};

export default convertExtraMaterial;
