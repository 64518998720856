import styled from 'styled-components';
import { isInWebview } from '../../../../helpers/reactNativeWebView';

export const Container = styled.div`
  ${isInWebview && 'padding-bottom: 56px;'}
  @media (min-width: 769px) and (max-width: 1400px) {
    width: 100%;
  }
`;

export const SliderWrapper = styled.span`
  flex: 1;
`;

export const StyledInput = styled.div`
  z-index: 1;
  position: relative;
  width: 100%;
  input {
    width: 100%;
    min-width: 280px;
    height: 40px;
    outline: none;
    border: none;
    border-radius: 50px;

    background: #f2f2f2;
    border: 1px solid #e6e6e6;
    padding: 9px 16px;
    font-size: 1.3rem;
    font-weight: 400;
  }

  svg {
    position: absolute;
    top: 10px;
    right: 16px;
    color: var(--gray);
  }

  svg.iconSliders {
    display: none;
  }

  @media (max-width: 768px) {
    input {
      background: transparent;
      border: 1px solid rgba(66, 82, 110, 0.64);
      color: var(--white);
      padding: 0 36px;

      &::placeholder {
        text-align: center;
        color: var(--neutralDark500);
      }
    }

    svg {
      left: 10px;
      color: var(--neutralDark500);
    }

    span.iconSliders {
      display: flex;
      position: absolute;
      right: 42px;
    }
  }
`;

export const Title = styled.h2`
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 40px;
  color: var(--neutralLight40);

  @media (max-width: 768px) {
    font-size: 1.6rem;
    line-height: 24px;
  }
`;

export const TitleWebView = styled.h1`
  font-weight: 500;
  font-size: 2rem;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-bottom: 32px;
  color: var(--white);
  span {
    font-size: 1.2rem;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: var(--neutralLight40);
    opacity: 0.6;
  }
`;

export const Table = styled.table`
  width: 100%;
  padding: 32px;
  background-color: rgba(12, 12, 51, 0.8);
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 65px;

  @media (min-width: 769px) and (max-width: 1400px) {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  @media (max-width: 768px) {
    padding: 24px;
  }
`;

export const TableHead = styled.thead`
  th {
    border-bottom: 1px solid var(--neutralLight40);
    text-align: left;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 1.5px;
    color: #ffffff;
    text-transform: uppercase;

    @media (min-width: 769px) and (max-width: 1400px) {
      &:not(:last-child) {
        padding-right: 80px;
      }
    }

    @media (max-width: 768px) {
      font-size: 1rem;
      line-height: 16px;
      border-bottom: none;
    }
  }
`;

export const TableBody = styled.tbody`
  tr {
    td {
      padding: 32px 0 32px 0;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 24px;
      color: #ffffff;

      &:not(:last-child) {
        padding-right: 80px;
      }
    }
    &:not(:last-child) {
      td {
        border-bottom: 1px solid var(--gray15);
      }
    }

    @media (max-width: 768px) {
      td {
        padding: 24px 0 24px 0;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 24px;

        &:not(:last-child) {
          padding-right: 60px;
        }
      }
    }
  }
`;

export const Category = styled.p`
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 20px;
  color: var(--lightBlue);
  margin-bottom: 6px;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 16px;
  }
`;

export const CourseTitle = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 24px;
  color: #ffffff;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 16px;
  }
`;

export const DownloadButton = styled.div`
  background-color: var(--lightBlue);
  color: white;
  line-height: 16px;
  letter-spacing: 1.5px;
  font-size: 1.4rem;
  font-weight: 500;
  border-radius: 31px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    margin-left: 10px;
  }

  @media (max-width: 768px) {
    background-color: var(--purple);
    border-radius: 50%;
    padding: 0;
    width: 40px;
    height: 40px;
    margin: auto;

    svg {
      margin-left: 0;
    }
  }
`;

export const Info = styled.div``;
export const Header = styled.div``;
export const Body = styled.div``;

export const NotFound = styled.p`
  font-weight: 500;
  font-size: 1.8rem;
  color: var(--neutralLight40);
`;

export const ButtonWrapper = styled.span`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 16px;

  > span {
    font-weight: bold;
    font-size: 1.6rem;
    color: var(--neutralDark900);
  }

  margin: 36px 0;
`;

export const SpaceBetween = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 36px;
  @media screen and (max-width: 560px) {
    flex-direction: column;
    grid-gap: 16px;
  }
`;

export const ShowMoreButton = styled.button`
  border: none;
  background-color: var(--purple);
  color: white;
  line-height: 16px;
  letter-spacing: 1.5px;
  font-size: 1.4rem;
  font-weight: 500;
  border-radius: 31px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (max-width: 768px) {
    margin-bottom: 100px;
  }
`;

export const ContainerCertificateMobileComponent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  & > div:nth-child(even) {
    margin-left: 16px;
  }
  & > div:last-child {
    max-width: 50%;
  }
`;

export const ContainerLoading = styled.div`
  width: 100%;
  margin-top: 50px;
  margin-left: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
