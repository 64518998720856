import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 30px;

  & + & {
    padding-top: 18px;
    border-top: 1px solid var(--neutralLight30);
  }

  @media (max-width: 768px) {
    background: rgba(12, 12, 51, 0.69);
    width: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.p`
  color: var(--neutralDark900);
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 24px;

  @media (max-width: 768px) {
    color: var(--white);
    font-size: 1rem;
    font-size: 10px;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 1.5px;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 0;
    padding: 8px;

    > div {
      display: flex;
      align-items: center;
    }
  }
`;

export const ButtonRedirectContent = styled.button`
  background: transparent;
  color: var(--lightBlue);
  padding: 8px;
  cursor: pointer;
  border: none;
  font-weight: 500;
  font-size: 1rem;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
`;

export const ActionButton = styled.div`
  color: var(--neutralMid80);
  background-color: var(--neutralLight20);
  padding: 5px;
  cursor: pointer;

  & + & {
    margin-left: 6px;
  }

  @media (max-width: 768px) {
    background-color: transparent;
  }
`;

export const Date = styled.span`
  color: var(--neutralMid400);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 20px;
  margin: 5px 0 10px;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const AnnotationContent = styled.div`
  color: var(--neutralMid400);
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 24px;

  @media (max-width: 768px) {
    max-width: 300px;
    height: 80px;
    overflow-y: scroll;
    padding: 10px;
    font-size: 1.2rem;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: var(--white);
    border: 1px solid rgba(255, 255, 255, 0.08);
  }
`;

export const EditionContainer = styled.form`
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 10px;
  }

  input,
  textarea {
    border: 1px solid var(--neutralLight40);
    border-radius: 4px;
    color: var(--neutralDark900);
    line-height: 24px;
    padding: 8px;

    @media (max-width: 768px) {
      background: transparent;
      color: var(--white);
    }
  }

  input {
    font-size: 1.8rem;

    @media (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  textarea {
    font-size: 1.6rem;
    height: 115px;
    @media (max-width: 768px) {
      font-size: 1.4rem;
    }
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;

  button {
    padding: 12px 16px;
    font-size: 1.4rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    border: 0;
    border-radius: 4px;
    cursor: pointer;

    &[type='button'] {
      background-color: var(--neutralLight30);
      color: var(--neutralDark800);
    }

    &[type='submit'] {
      background-color: var(--purple);
      color: white;
    }

    & + button {
      margin-left: 6px;
    }

    svg {
      margin-right: 10px;
    }
  }
`;
