import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import CourseContent, { PlayerEventListener } from './components/CourseContent';
import Breadcrumb from '../../components/Breadcrumb';
import CourseCard from '../../components/CourseCard';
import Loading from '../../components/Loading';

import {
  startContent as startContentService,
  finishContent as finishContentService,
  getContent as getContentService,
  getContentCards,
} from '../../services/content';

import { postCertificate } from '../../services/certificate/index';

import Content from '../../models/content';
import { LanguageContext } from '../../contexts/LanguageContext';

import * as Style from './styles';
import ContentCard from '../../models/content-card';
import { truncate } from 'fs';

export interface CourseParams {
  courseId: string;
}

interface ExtendedWindow extends Window {
  hasStartedCourse: boolean;
  hasFinishedCourse: boolean;
}

declare let window: ExtendedWindow;

const Course: React.FC = () => {
  const { dictionary } = useContext(LanguageContext);
  const { courseId } = useParams() as CourseParams;
  const [content, setContent] = useState({} as Content);
  const [contentsFromCategory, setContentsFromCategory] = useState<
    ContentCard[]
  >();
  const [courseProgress, setCourseProgress] = useState<number>(0);

  const crumbs = [
    { name: 'HOME', path: '/home' },
    { name: dictionary.COMMON.CHANNELS, path: '/courses' },
    { name: content.categoryName, path: `/channels/${content.categoryId}` },
    { name: content.title, path: '' },
  ];

  useEffect(() => {
    const getContent = async () => {
      const localContent = await getContentService(courseId);
      if (localContent && localContent.id) {
        setContent(localContent);
      }
    };
    getContent();
  }, [courseId]);

  useEffect(() => {
    window.hasStartedCourse = false;
    window.hasFinishedCourse = false;

    if (content && content.id) {
      window.hasStartedCourse = content.alreadyStarted;
      window.hasFinishedCourse = content.alreadyFinished;
    }
  }, [courseId, content]);

  useEffect(() => {
    (async () => {
      if (!content.categoryId) return;
      const localContents = await getContentCards({
        limit: 8,
        category_id: content.categoryId,
      });
      if (localContents && localContents.length) {
        setContentsFromCategory(
          localContents.filter(localContent => localContent.id !== content.id),
        );
      }
    })();
  }, [content, courseId]);

  const isLoading = useMemo(() => {
    return !content || !content.id;
  }, [content]);

  const getEventListeners = async (player: PlayerEventListener) => {
    if (window.location.href.endsWith(`courses/${content.id}`)) {
      if (content && content.id) {
        switch (player.event) {
          case 'onProgress':
            if (
              hasReachedCompletionTime(player.duration, player.eventParam, true)
            ) {
              await finishCourse();
            }
            updateCourseWatchTime(player);
            break;

          case 'onStart':
            await startCourse();
            break;

          case 'onFinish':
            postCertificate(courseId);

            break;

          default:
            break;
        }
      }
    }
  };

  const hasReachedCompletionTime = (
    duration: number,
    currentTime: number,
    isSec = false,
  ) => {
    const totalDurationInSecs = isSec ? duration : duration / 1000;
    const completionRate = 0.9;

    const completionTime = totalDurationInSecs * completionRate;
    return currentTime >= completionTime;
  };

  const updateCourseWatchTime = useCallback((player: PlayerEventListener) => {
    const { event, eventParam } = player;

    if (event === 'onProgress') {
      setCourseProgress(eventParam);
    }
    return null;
  }, []);

  const startCourse = async () => {
    if (!window.hasStartedCourse && !content.alreadyStarted) {
      try {
        window.hasStartedCourse = true;
        content.alreadyStarted = true;
        await startContentService(courseId);
      } catch (error) {
        window.hasStartedCourse = false;
        content.alreadyStarted = false;
      }
    }
  };

  const finishCourse = async () => {
    if (!window.hasFinishedCourse && !content.alreadyFinished) {
      try {
        window.hasFinishedCourse = true;
        content.alreadyFinished = true;
        finishContentService(courseId || '');
        if (content.type.toUpperCase() === 'SCORM') {
          postCertificate(courseId);
        }
      } catch (error) {
        window.hasFinishedCourse = false;
        content.alreadyFinished = false;
      }
    }
  };

  return (
    <Style.Container>
      {!isLoading ? (
        <>
          <Breadcrumb crumbs={crumbs} />
          <Style.CourseContainer>
            <CourseContent
              content={content}
              courseHasStarted={window.hasStartedCourse}
              getEventListeners={getEventListeners}
              progress={courseProgress}
              startCourse={startCourse}
              finishCourse={finishCourse}
            />
            {contentsFromCategory && contentsFromCategory.length > 0 && (
              <Style.OtherCourses>
                <Style.OtherCoursesTitle>
                  {dictionary.COURSE.OTHER_CONTENTS}
                </Style.OtherCoursesTitle>
                <Style.OtherCoursesList>
                  {contentsFromCategory &&
                    contentsFromCategory.map(content => (
                      <CourseCard key={content.id} content={content} />
                    ))}
                </Style.OtherCoursesList>
              </Style.OtherCourses>
            )}
          </Style.CourseContainer>
        </>
      ) : (
        <Loading size={200} />
      )}
    </Style.Container>
  );
};

export default Course;
