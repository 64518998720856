import React, { useContext, useState } from 'react';
import {
  AiOutlineClockCircle,
  AiOutlineLike,
  AiOutlineUser,
} from 'react-icons/ai';
import { BsBookmark, BsPlay } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { addTrailBookmark, removeTrailBookmark } from '../../services/bookmark';

import DefaultThumbnail from '../../assets/default-card-thumbnail.png';

import { secondsToTimeText } from '../../helpers/time';

import { LanguageContext } from '../../contexts/LanguageContext';

import OngoingTrail from '../../models/from-api/ongoing-trail';
import * as Style from './styles';
import ProgressBar from '../ProgressBar';
import { Mobile, NotMobile } from '../Responsive';

interface TrailCardProps {
  trail: OngoingTrail;
}

const TrailCard: React.FC<TrailCardProps> = ({ trail }) => {
  const history = useHistory();

  const { dictionary } = useContext(LanguageContext);
  const [isAddedToList, setIsAddedToList] = useState(trail.book_mark);

  const addToList = async (event: React.MouseEvent) => {
    event.stopPropagation();
    try {
      if (isAddedToList) {
        await removeTrailBookmark(trail.trail_id);
      } else {
        await addTrailBookmark(trail.trail_id);
      }
      setIsAddedToList(!isAddedToList);
    } catch (e) {
      toast.error('Erro ao adicionar conteúdo aos favoritos.');
    }
  };

  const goToContent = () => {
    history.push(`/trails/${trail.trail_id}`);
  };

  const convertToTimeText = (remainingTime: number) => {
    const hours = Math.floor(remainingTime / 3600);
    const minutes = Math.floor((remainingTime % 3600) / 60);

    return `${hours > 0 ? `${hours} h` : ''} ${minutes} min`;
  };

  return (
    <Style.Container onClick={goToContent}>
      <Style.CourseImageContainer>
        <Mobile>
          <span>
            <BsPlay />
          </span>
        </Mobile>
        <img src={trail.thumbnail || DefaultThumbnail} alt="Trail" />
      </Style.CourseImageContainer>
      <Style.CourseBody>
        <Style.CourseUpperContent>
          <Style.CourseCategory>{trail.category_name}</Style.CourseCategory>
          <NotMobile>
            <Style.BookmarkButton
              className={`${isAddedToList ? 'added-to-list' : ''}`}
              onClick={e => addToList(e)}
            >
              <BsBookmark
                size={20}
                color={`${isAddedToList ? '#FFF' : '#6B778C'}`}
              />
            </Style.BookmarkButton>
          </NotMobile>
        </Style.CourseUpperContent>
        <Style.CourseLowerContent>
          <Style.CourseTitle>{trail.name}</Style.CourseTitle>
          <NotMobile>
            <Style.CourseAuthor>
              <AiOutlineUser size={16} color={'var(--neutralDark900)'} />
              {dictionary.COMMON.AUTHOR}: {trail.author_name}
            </Style.CourseAuthor>
          </NotMobile>
          <Style.CourseFooter>
            <NotMobile>
              <Style.CourseTime>
                <AiOutlineClockCircle
                  size={16}
                  color={'var(--neutralDark900)'}
                />
                {secondsToTimeText(trail.time_remaining)}
              </Style.CourseTime>
              <Style.CourseLikes>
                <AiOutlineLike size={16} color={'var(--neutralDark900)'} />
                {`${trail.likes.count} ${
                  trail.likes.count === 1
                    ? dictionary.COMMON.LIKE
                    : dictionary.COMMON.LIKES
                }`}
              </Style.CourseLikes>
            </NotMobile>
          </Style.CourseFooter>
          <Style.RemainingTime>
            <AiOutlineClockCircle size={13} color={'var(--neutralDark500)'} />
            {dictionary.CARD.REMAINING_TIME}:
            <Mobile>
              <br />
            </Mobile>
            {convertToTimeText(trail.time_remaining)}
          </Style.RemainingTime>

          <ProgressBar
            darkenProgress
            completionPercentage={Math.floor(trail.progress * 100)}
          />
        </Style.CourseLowerContent>
      </Style.CourseBody>
    </Style.Container>
  );
};

export default TrailCard;
