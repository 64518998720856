import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const ScrollToTop: React.FC<any> = ({ history }) => {
  useEffect(() => {
    const unlisten = history.listen(({ pathname }: any) => {
      const isTrailCoursePage = pathname.split('/').includes('modules');
      if (!isTrailCoursePage) {
        window.scrollTo(0, 0);
      }
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
};

export default withRouter(ScrollToTop);
