import convertContent from '../../converters/convert-content';
import convertContentCard from '../../converters/convert-content-card';
import convertContentCopy from '../../converters/convert-content-copy';
import httpClient from '../../http-client';
import Content from '../../models/content';
import ContentCard from '../../models/content-card';
import ContentFromResponse from '../../models/from-api/content';
import ContentCardFromResponse from '../../models/from-api/content-card';

const getContents = async (filters?: Object): Promise<Content[]> => {
  const contents = (
    await httpClient.get<ContentFromResponse[]>('/contents/', {
      params: filters,
    })
  ).data;

  let convertedContents = [] as Content[];
  if (contents && contents.length) {
    convertedContents = contents.map(content => convertContent(content));
  }

  return convertedContents;
};

const getContentCards = async (filters?: Object): Promise<ContentCard[]> => {
  const contents = (
    await httpClient.get<ContentCardFromResponse[]>('/contents/with-trail', {
      params: filters,
    })
  ).data;

  let convertedContents = [] as ContentCard[];
  if (contents && contents.length) {
    convertedContents = contents.map(content => convertContentCard(content));
  }

  return convertedContents;
};

const getContent = async (contentId: string): Promise<Content> => {
  const content = (await httpClient.get<any>(`/contents/${contentId}`)).data;

  let convertedContent = {} as Content;
  if (content && content.content.content_id) {
    if (content.user) {
      convertedContent = convertContentCopy(content.content, content.user);
    } else {
      convertedContent = convertContentCopy(content.content);
    }
  }

  return convertedContent;
};

const startContent = async (
  contentId: string,
  courseId?: string,
  trailId?: string,
) => {
  if (!!contentId && !trailId && !courseId) {
    await httpClient.post(`/contents-user`, { content_id: contentId });
  } else {
    await httpClient.post(`/trail-course-content-users`, {
      trail_id: trailId,
      course_id: courseId,
      content_id: contentId,
    });
  }
};

const finishContent = async (
  contentId: string,
  courseId?: string,
  trailId?: string,
) => {
  if (!!contentId && !trailId && !courseId) {
    await httpClient.put(`/contents-user/${contentId}/finish`);
  } else {
    await httpClient.put(`/trail-course-content-users/finish`, {
      trail_id: trailId,
      course_id: courseId,
      content_id: contentId,
    });
  }
};

const updateContentProgress = async (
  contentId: string,
  progress: number | null,
) => {
  if ((progress || 0) <= 0) {
    return {};
  }

  return (
    await httpClient.patch(`/contents-user/${contentId}`, {
      content_view: Math.floor(progress || 0),
    })
  ).data;
};

const searchContents = async (
  param: string,
  filters?: Object,
): Promise<ContentCard[]> => {
  if (!param || param === '') {
    return [];
  }

  const contents = (
    await httpClient.get<ContentCardFromResponse[]>('/contents/with-trail', {
      params: {
        ...filters,
        search: param,
      },
    })
  ).data;

  let convertedContents = [] as ContentCard[];
  if (contents && contents.length) {
    convertedContents = contents.map(content => convertContentCard(content));
  }

  return convertedContents;
};

export {
  getContents,
  getContentCards,
  getContent,
  startContent,
  finishContent,
  updateContentProgress,
  searchContents,
};
