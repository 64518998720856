import convertAllAnnotation from '../../converters/convert-all-annotation';
import convertAnnotation from '../../converters/convert-annotation';
import httpClient from '../../http-client';
import { AllAnnotation } from '../../models/all-annotation';
import Annotation from '../../models/annotation';
import AnnotationFromResponse from '../../models/from-api/annotation';

const getAnnotations = async (filters?: Object): Promise<Annotation[]> => {
  const annotations = (
    await httpClient.get<AnnotationFromResponse[]>(`/annotations`, {
      params: filters,
    })
  ).data;

  let convertedAnnotations = [] as Annotation[];
  if (annotations && annotations.length) {
    convertedAnnotations = annotations.map(convertAnnotation);
  }

  return convertedAnnotations;
};

const getAllAnnotations = async (
  filters?: Object,
): Promise<AllAnnotation[]> => {
  const { contents, trails } = (
    await httpClient.get(`/annotations/all`, {
      params: filters,
    })
  ).data;

  const contentList = [...contents, ...trails];

  let convertedContents = [] as AllAnnotation[];

  if (contentList && contentList.length) {
    convertedContents = contentList.map(convertAllAnnotation);
  }

  return convertedContents;
};

const createAnnotation = async (
  newAnnotation: { title: string; description: string },
  contentId: string,
  trailId?: string,
) => {
  if (!newAnnotation) {
    throw new Error('Os dados para criação da anotação são obrigatórios');
  }

  const response = await httpClient({
    method: 'post',
    url: '/annotations',
    data: {
      trail_id: trailId,
      content_id: contentId,
      title: newAnnotation.title,
      description: newAnnotation.description,
    },
  });

  return response;
};

const deleteAnnotation = async (annotationId: string) => {
  if (!annotationId) {
    throw new Error('ID obrigatório para exclusão!');
  }

  const response = await httpClient({
    method: 'delete',
    url: `/annotations/${annotationId}`,
  });

  return response;
};

const updateAnnotation = async (annotationId: string, newAnnotation: any) => {
  if (!annotationId) {
    throw new Error('ID obrigatório para edição de anotação!');
  }

  if (!newAnnotation) {
    throw new Error('Dados para edição não encontrados!');
  }

  const response = await httpClient({
    method: 'put',
    url: `/annotations/${annotationId}`,
    data: {
      title: newAnnotation.title,
      description: newAnnotation.description,
    },
  });

  return response;
};

export {
  getAnnotations,
  getAllAnnotations,
  createAnnotation,
  deleteAnnotation,
  updateAnnotation,
};
