import { Dispatch, SetStateAction } from 'react';
import Channels from './components/Channels';
import Profile from './components/Profile';
import Menu from './components/Menu';
import * as Style from './styles';

interface SidebarMenuProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: Dispatch<SetStateAction<boolean>>;
  selectedTab: string;
}

const SidebarMenu: React.FC<SidebarMenuProps> = ({
  isSidebarOpen,
  setIsSidebarOpen,
  selectedTab,
}) => {
  return (
    <Style.Container id="sidebar-menu" isSidebarOpen={isSidebarOpen}>
      <Menu isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen}>
        {selectedTab === 'Perfil' ? (
          <Profile setIsSidebarOpen={setIsSidebarOpen} />
        ) : (
          <Channels setIsSidebarOpen={setIsSidebarOpen} />
        )}
      </Menu>
    </Style.Container>
  );
};

export default SidebarMenu;
