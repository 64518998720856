import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.h2`
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 40px;
  color: #ffffff;
  margin-bottom: 36px;

  @media (max-width: 768px) {
    font-size: 1.6rem;
    line-height: 24px;
  }
`;
export const TitleWebView = styled.h1`
  font-weight: 500;
  font-size: 2rem;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-bottom: 32px;
  color: var(--white);
  span {
    font-size: 1.2rem;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: var(--neutralLight40);
    opacity: 0.6;
  }
`;

export const AnnotationList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 65px;

  @media (max-width: 768px) {
    justify-items: center;
  }
`;

export const NotFound = styled.p`
  font-weight: bold;
  font-size: 1.4rem;
`;

export const StyledInput = styled.div`
  z-index: 1;

  position: relative;

  input {
    width: 100%;
    min-width: 280px;
    height: 40px;
    outline: none;
    border: none;
    border-radius: 50px;

    background: #f2f2f2;
    border: 1px solid #e6e6e6;
    padding: 9px 16px;
    font-size: 1.3rem;
    font-weight: 400;
  }

  svg {
    position: absolute;
    top: 10px;
    right: 16px;
    color: var(--gray);
  }

  svg.iconSliders {
    display: none;
  }

  @media (max-width: 768px) {
    margin-bottom: 32px;
    input {
      width: 340px;
      background: transparent;
      border: 1px solid rgba(66, 82, 110, 0.64);
      color: var(--white);
      padding: 0 36px;

      &::placeholder {
        text-align: center;
        color: var(--neutralDark500);
      }
    }

    svg {
      left: 10px;
      color: var(--neutralDark500);
    }

    span.iconSliders {
      display: flex;
      position: absolute;
      right: 42px;
    }
  }
`;
