import { BsFlag } from 'react-icons/bs';
import { AiOutlineDownload } from 'react-icons/ai';
import { ReactComponent as Medal } from '../../../../../../assets/svgs/medal.svg';
import notFound from '../../../../../../assets/no-image.png';

import * as Style from './styles';
import { useContext } from 'react';
import { LanguageContext } from '../../../../../../contexts/LanguageContext';

interface MobileCertificateProps {
  thumbnail?: string;
  author?: string;
  name: string;
  finish_at: string;
  reference_id: string;
  onButtonClick: (id: string) => void;
}

const MobileCertificate = ({
  thumbnail,
  author,
  name,
  finish_at,
  reference_id,
  onButtonClick,
}: MobileCertificateProps) => {
  const { dictionary } = useContext(LanguageContext);

  return (
    <Style.ContainerMobileCertificate>
      <Style.CardMobileCertificate>
        <Style.ThumbnailContainer>
          {thumbnail ? (
            <img src={thumbnail} alt={name} />
          ) : (
            <img src={notFound} alt="Imagem não encontrada" />
          )}
        </Style.ThumbnailContainer>
        <Style.AuthorCardCertificate>
          {author ? author : 'Treinamento'}
        </Style.AuthorCardCertificate>
        <Style.NameCardCertificate>{name}</Style.NameCardCertificate>
        <Style.DateCardCertificate>
          <BsFlag color={'var(--neutralDark500)'} size={14} />
          {dictionary.MY_COURSES.FINISHED_IN} <br />
          {finish_at}
        </Style.DateCardCertificate>
        <Style.ButtonCardCertificate
          onClick={() => onButtonClick(reference_id)}
        >
          <Medal />
          <span>{dictionary.MY_COURSES.CERTIFICATES}</span>
          <AiOutlineDownload size={14} />
        </Style.ButtonCardCertificate>
      </Style.CardMobileCertificate>
    </Style.ContainerMobileCertificate>
  );
};

export default MobileCertificate;
