import { FiLogOut } from 'react-icons/fi';
import { BiUserCircle } from 'react-icons/bi';
import { GrBook, GrGlobe } from 'react-icons/gr';

import { IoEarthOutline } from 'react-icons/io5';
import LanguageSelector from '../LanguageSelector';
import { LanguageContext } from '../../../../contexts/LanguageContext';

import { Dispatch, SetStateAction, useContext, useEffect, useRef } from 'react';
import * as Style from './styles';

interface ProfileDropdownProps {
  setProfileIsOpen: Dispatch<SetStateAction<boolean>>;
}

const ProfileDropdown: React.FC<ProfileDropdownProps> = ({
  setProfileIsOpen,
}) => {
  const { dictionary, language, setLanguage } = useContext(LanguageContext);
  const logout = () => {
    window.localStorage.removeItem('alpargatas-api-token');
    console.log(process.env.REACT_APP_REDIRECT_LOGOUT)
    window.location.href = `${process.env.REACT_APP_REDIRECT_LOGOUT}`;
  };
  const profileRef: any = useRef(null);

  const onLanguageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLanguage(event.target.value);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        profileRef.current &&
        !profileRef.current.contains(event.target) &&
        !profileRef.current.parentElement.contains(event.target)
      ) {
        setProfileIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [profileRef]);

  return (
    <Style.Container ref={profileRef} onClick={e => e.stopPropagation()}>
      {/* <Style.OptionLink to="/profile/my-data">
        <BiUserCircle size={16} />
        Juliana Paula
      </Style.OptionLink> */}

      <Style.OptionLink to="/my-courses/ongoing">
        <Style.LinkText>
          <GrBook size={16} />
          {dictionary.MY_COURSES.MY_COURSES}
        </Style.LinkText>
      </Style.OptionLink>

      <Style.LanguageLink>
        <Style.LinkText>
          <GrGlobe size={16} />
          {dictionary.PROFILE_DROPDOWN.LANGUAGE}
        </Style.LinkText>
        <Style.LinkText>
          <Style.Label checked={language === 'pt-BR'}>
            <input
              type="radio"
              name="language"
              value="pt-BR"
              checked={language === 'pt-BR'}
              onChange={e => onLanguageChange(e)}
            />
          </Style.Label>
          PT
        </Style.LinkText>
        <Style.LinkText>
          <Style.Label checked={language === 'en-US'}>
            <div className="inner-circle" />
            <input
              type="radio"
              name="language"
              value="en-US"
              checked={language === 'en-US'}
              onChange={e => onLanguageChange(e)}
            />
          </Style.Label>
          EN
        </Style.LinkText>
      </Style.LanguageLink>

      <Style.LogoutLink onClick={logout}>
        <Style.LinkText>
          <FiLogOut size={16} /> {dictionary.PROFILE_DROPDOWN.LOGOUT}
        </Style.LinkText>
      </Style.LogoutLink>
    </Style.Container>
  );
};

export default ProfileDropdown;
