import React, { useContext, useState } from 'react';
import {
  AiOutlineClockCircle,
  AiOutlineLike,
  AiOutlineUser,
} from 'react-icons/ai';
import { BsBookmark } from 'react-icons/bs';
import { RiBookMarkLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import Sun from '../../assets/svgs/sun-horizon.svg';

import {
  addContentBookmark,
  addTrailBookmark,
  removeContentBookmark,
  removeTrailBookmark,
} from '../../services/bookmark';

import DefaultThumbnail from '../../assets/default-card-thumbnail.png';

import { secondsToTimeText } from '../../helpers/time';

import { LanguageContext } from '../../contexts/LanguageContext';

import ContentCard from '../../models/content-card';
import * as Style from './styles';
import { Mobile, NotMobile } from '../Responsive';

interface CourseCardProps {
  content: ContentCard;
}

const CourseCard: React.FC<CourseCardProps> = ({ content }) => {
  const history = useHistory();
  const { dictionary } = useContext(LanguageContext);
  const [isAddedToList, setIsAddedToList] = useState(content.addedToList);

  const addToList = async (event: React.MouseEvent) => {
    event.stopPropagation();
    if (content) {
      try {
        if (isAddedToList) {
          if (content.type === 'CONTENT') {
            await removeContentBookmark(content.id);
          } else {
            await removeTrailBookmark(content.id);
          }
        } else {
          if (content.type === 'CONTENT') {
            await addContentBookmark(content.id);
          } else {
            await addTrailBookmark(content.id);
          }
        }
        setIsAddedToList(!isAddedToList);
      } catch (e) {
        toast.error(dictionary.ERROR.BOOKMARK);
      }
    }
  };

  const goToContent = () => {
    if (content.type === 'CONTENT') {
      history.push(`/courses/${content.id}`);
    } else {
      history.push(`/trails/${content.id}`);
    }
  };

  return (
    <Style.Container id="course-card" onClick={goToContent}>
      <Style.CourseImageContainer>
        <img
          src={content.thumbnail ? content.thumbnail : DefaultThumbnail}
          alt="Course"
        />
        {content.isNew && (
          <Style.CardTag>
            <img src={Sun} alt="Vetor de um Horizonte" className="sun-icon" />
            <NotMobile>{dictionary.CARD.NEW}</NotMobile>
          </Style.CardTag>
        )}
      </Style.CourseImageContainer>
      <Style.CourseBody>
        <Style.CourseUpperContent>
          <Style.CourseCategory>
            {content.categoryName} -{' '}
            {content.type === 'TRAIL'
              ? dictionary.COMMON.SERIE
              : dictionary.COMMON.COURSE}
          </Style.CourseCategory>
          <NotMobile>
            <Style.BookmarkButton
              className={`${isAddedToList ? 'added-to-list' : ''}`}
              onClick={e => addToList(e)}
            >
              <BsBookmark
                size={20}
                color={`${isAddedToList ? '#FFF' : '#6B778C'}`}
              />
            </Style.BookmarkButton>
          </NotMobile>
        </Style.CourseUpperContent>
        <Style.CourseLowerContent>
          <Style.CourseTitle>{content.title}</Style.CourseTitle>
          <Style.CourseAuthor>
            <NotMobile>
              <AiOutlineUser size={16} color={'var(--neutralDark900)'} />
            </NotMobile>
            <Mobile>
              <RiBookMarkLine />
            </Mobile>
            {dictionary.COMMON.THEME}: {content.authorName}
          </Style.CourseAuthor>
          <Style.CourseFooter>
            <Style.CourseTime>
              <AiOutlineClockCircle size={16} color={'var(--neutralDark900)'} />
              {secondsToTimeText(content.duration)}
            </Style.CourseTime>
            <Style.Row>
              <Style.CourseLikes>
                <AiOutlineLike size={16} color={'var(--neutralDark900)'} />
                {`${content.numberOfLikes} ${
                  content.numberOfLikes === 1
                    ? dictionary.COMMON.LIKE
                    : dictionary.COMMON.LIKES
                }`}
              </Style.CourseLikes>
            </Style.Row>
          </Style.CourseFooter>
        </Style.CourseLowerContent>
      </Style.CourseBody>
    </Style.Container>
  );
};

export default CourseCard;
