import httpClient from '../../http-client';

const likeTrailContent = async (trailId: string) => {
  await httpClient.post(`/trail-user-likes/`, { trail_id: trailId });
};

const dislikeTrailContent = async (trailId: string) => {
  await httpClient.delete(`/trail-user-likes/${trailId}`);
};

export { likeTrailContent, dislikeTrailContent };
