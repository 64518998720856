import React, { useContext, useEffect, useState } from 'react';
import QuestionAndAnswer from './components/QuestionAndAnswer';
import { getQuestions } from '../../services/faq';
import Faq from '../../models/faq';
import { LanguageContext } from '../../contexts/LanguageContext';
import Breadcrumb from '../../components/Breadcrumb';
import Loading from '../../components/Loading';
import * as Style from './styles';

const FAQ: React.FC = () => {
  const { dictionary } = useContext(LanguageContext);
  const [listQuestions, setListQuestions] = useState([] as Faq[]);
  const [isLoading, setIsLoading] = useState(true);

  const crumbs = [
    { name: 'HOME', path: '/home' },
    { name: dictionary.FAQ.FAQ, path: '' },
  ];

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const result = await getQuestions();
        setListQuestions(result);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, []);

  const closeInactiveQuestions = (id: string) => {
    const updatedList = [...listQuestions].map(question => ({
      ...question,
      isOpen: question.id !== id ? false : !question.isOpen,
    }));

    setListQuestions(updatedList);
  };

  return (
    <Style.Container>
      {!isLoading ? (
        <>
          <Breadcrumb crumbs={crumbs} />
          <Style.Body>
            <Style.Header>
              <Style.Title>{dictionary.FAQ.FAQ}</Style.Title>
              <Style.Description>
                {dictionary.FAQ.DESCRIPTION}
              </Style.Description>
            </Style.Header>
            <Style.List>
              {listQuestions && listQuestions.length ? (
                listQuestions.map(faq => (
                  <QuestionAndAnswer
                    key={faq.id}
                    faq={faq}
                    closeInactiveQuestions={closeInactiveQuestions}
                  />
                ))
              ) : (
                <Style.NotFound>{dictionary.COMMON.NOT_FOUND}</Style.NotFound>
              )}
            </Style.List>
          </Style.Body>
        </>
      ) : (
        <Loading size={200} />
      )}
    </Style.Container>
  );
};

export default FAQ;
