import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Swal from 'sweetalert2';
import Loading from '../../../../../../components/Loading';
import TrailContentAnnotation from './components/TrailContentAnnotation';
import checkEmptyString from '../../../../../../helpers/check-empty-string';
import { LanguageContext } from '../../../../../../contexts/LanguageContext';
import Annotation from '../../../../../../models/annotation';
import {
  createAnnotation,
  getAnnotations,
  updateAnnotation,
} from '../../../../../../services/annotations';
import * as Style from './styles';

interface TrailContentAnnotationsProps {
  trailId: string;
  contentId: string;
}

const TrailContentAnnotations: React.FC<TrailContentAnnotationsProps> = ({
  trailId,
  contentId,
}) => {
  const { dictionary } = useContext(LanguageContext);
  const [annotations, setAnnotations] = useState([] as Annotation[]);
  const [idOfEdittingAnnotation, setIdOfEdittingAnnotation] = useState('');
  const [annotationTitle, setAnnotationTitle] = useState('');
  const [annotationDescription, setAnnotationDescription] = useState('');
  const [loading, setLoading] = useState(false);

  const isEditting = useMemo(() => {
    return !!idOfEdittingAnnotation;
  }, [idOfEdittingAnnotation]);

  const editAnnotations = (annotation: Annotation) => {
    setAnnotationTitle(annotation.title);
    setAnnotationDescription(annotation.description);
    setIdOfEdittingAnnotation(annotation.id);
  };

  const refreshAnnotations = useCallback(async () => {
    setLoading(true);
    setAnnotations([]);
    const localAnnotations = await getAnnotations({
      trail_id: trailId,
      content_id: contentId,
    });
    if (localAnnotations && localAnnotations.length) {
      setAnnotations(localAnnotations);
    }

    setLoading(false);
  }, []);

  const createOrUpdateAnnotation = async (event: React.FormEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    try {
      if (checkEmptyString(annotationDescription)) {
        throw new Error(dictionary.ANNOTATION.INFORM_DESCRIPTION);
      }

      if (isEditting) {
        const foundAnnotation = annotations.find(
          annotation => annotation.id === idOfEdittingAnnotation,
        );
        if (foundAnnotation) {
          await updateAnnotation(idOfEdittingAnnotation, {
            ...foundAnnotation,
            title: annotationTitle,
            description: annotationDescription,
          });
        }
      } else {
        await createAnnotation(
          {
            title: annotationTitle,
            description: annotationDescription,
          },
          contentId,
          trailId,
        );
      }

      setAnnotationTitle('');
      setAnnotationDescription('');
      setIdOfEdittingAnnotation('');

      await refreshAnnotations();
    } catch (error) {
      Swal.fire({
        title: dictionary.ANNOTATION.MODAL.CREATE.ERROR,
        text: error.message,
        icon: 'error',
      });
    }
  };

  useEffect(() => {
    refreshAnnotations();
  }, [refreshAnnotations]);

  return (
    <Style.TrailContentAnnotationsContainer>
      <Style.AnnotationForm onSubmit={createOrUpdateAnnotation}>
        <Style.AnnotationFormLabel htmlFor="annotation-title">
          {isEditting
            ? dictionary.ANNOTATION.EDIT_ANNOTATION
            : dictionary.ANNOTATION.CREATE_ANNOTATION}
        </Style.AnnotationFormLabel>
        <Style.AnnotationFormTitle
          value={annotationTitle}
          onChange={event => setAnnotationTitle(event.target.value)}
          id="annotation-title"
        />
        <Style.AnnotationFormDescription
          value={annotationDescription}
          onChange={event => setAnnotationDescription(event.target.value)}
          required
        ></Style.AnnotationFormDescription>
        <Style.AnnotationFormSubmit>
          {isEditting
            ? dictionary.ANNOTATION.UPDATE_NOTE
            : dictionary.ANNOTATION.CREATE_NOTE}
        </Style.AnnotationFormSubmit>
      </Style.AnnotationForm>
      {!isEditting && (
        <Style.AnnotationList>
          {loading ? (
            <Loading />
          ) : (
            <>
              {annotations && annotations.length ? (
                annotations.map(annotation => (
                  <TrailContentAnnotation
                    key={annotation.id}
                    annotation={annotation}
                    edit={editAnnotations}
                    refreshAnnotations={refreshAnnotations}
                  />
                ))
              ) : (
                <></>
              )}
            </>
          )}
        </Style.AnnotationList>
      )}
    </Style.TrailContentAnnotationsContainer>
  );
};

export default TrailContentAnnotations;
