import styled from 'styled-components';
import { isInWebview } from '../../../../helpers/reactNativeWebView';

export const Container = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  -webkit-backface-visibility: hidden;
  background-color: var(--darkPurple);
  display: flex;
  justify-content: center;
  grid-gap: 64px;
  align-content: center;
  padding: 10px 24px;
  color: white;
  font-size: 1.2rem;
  font-weight: 400;
  z-index: 2;
  visibility: ${isInWebview ? 'hidden' : 'visible'};
`;
