import httpClient from '../../http-client';

const likeCourse = async (courseId: string) => {
  await httpClient.post(`/content-user-likes/`, { content_id: courseId });
};

const dislikeCourse = async (courseId: string) => {
  await httpClient.delete(`/content-user-likes/${courseId}`);
};

export { likeCourse, dislikeCourse };
