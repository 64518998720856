import styled from 'styled-components';
import StarsBackground from '../../assets/stars-background.png';

export const Container = styled.div`
  min-height: 30vw;
  height: 100%;

  margin: auto;
  max-width: 1424px;
  width: 100%;
  position: relative;
  &:before {
    content: '';
    background-image: url(${StarsBackground});
    background-position: center;
    background-repeat: repeat;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;

export const TrailContainer = styled.div`
  padding: 54px 0;

  @media (max-width: 768px) {
    padding: 32px 0;
  }
`;

export const UpperContent = styled.div`
  margin-bottom: 86px;

  @media (max-width: 1300px) {
    margin-bottom: 56px;
  }
`;

export const LowerContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  margin-bottom: 96px;
  position: relative;

  @media (max-width: 1300px) {
    flex-direction: column;
  }
`;

export const Header = styled.div`
  margin-bottom: 75px;
`;

export const Category = styled.h4`
  color: var(--lightBlue);
  font-size: 2rem;
  line-height: 24px;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 1.4rem;
    font-weight: 500;
  }
`;

export const Title = styled.h1`
  color: #ffffff;
  font-size: 4rem;
  line-height: 48px;
  font-weight: 700;
  margin: 16px 0;

  @media (max-width: 768px) {
    font-size: 2rem;
    line-height: 24px;
    font-weight: 500;
    margin: 8px 0;
  }
`;

export const Description = styled.p`
  color: var(--neutralLight40);
  font-size: 1.6rem;
  line-height: 24px;
  font-weight: 400;

  @media (max-width: 768px) {
    font-size: 1.4rem;
    line-height: 20px;
    font-weight: 400;
  }
`;

export const OtherTrails = styled.div`
  margin-bottom: 180px;
`;

export const OtherTrailsTitle = styled.h2`
  color: #ffffff;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 40px;
  margin-bottom: 36px;
`;

export const OtherTrailsList = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));

  @media (max-width: 768px) {
    justify-items: center;
  }
`;
