import httpClient from '../../http-client';

const getActualUser = async () => {
  const actualUser = (await httpClient.get('/users/me')).data;

  return actualUser;
};

const acceptTerms = async (accepted: boolean) => {
  const result = (
    await httpClient.patch('/users/accept-conditions', {
      accept: accepted,
    })
  ).data;

  return result;
};

export { getActualUser, acceptTerms };
