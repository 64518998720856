import { useEffect } from 'react';

//@ts-expect-error ReactNativeWebView may exist
export const isInWebview = window.isWebView;

//@ts-expect-error ReactNativeWebView may exist
export const isWebFrontend = !window.isWebView;

export const exposeToReactNativeWebView = <T>(source: T, name: string) => {
  //@ts-expect-error ReactNativeWebView may exist
  window[name] = source;
};

export const postMessageToReactNativeWebView = (message: string | object) => {
  if (isInWebview) {
    typeof message === 'string'
      ? //@ts-expect-error ReactNativeWebView may exist
        window.ReactNativeWebView.postMessage(message)
      : //@ts-expect-error ReactNativeWebView may exist
        window.ReactNativeWebView.postMessage(JSON.stringify(message));
  }
};

const logout = () => {
  window.localStorage.removeItem('alpargatas-api-token');
  window.location.href = `${process.env.REACT_APP_REDIRECT_LOGOUT}`;
};
exposeToReactNativeWebView(logout, 'logout');

const blobToBase64 = (blob: Blob): Promise<string | ArrayBuffer | null> => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

export const saveAsBase64 = (base64: string | ArrayBuffer | null) =>
  postMessageToReactNativeWebView(
    JSON.stringify({
      type: 'file',
      base64,
    }),
  );

export const downloadToWebView = (blob: Blob) =>
  blobToBase64(blob).then(saveAsBase64);

export const useRNWebViewExpose = <T>(source: T, name: string) => {
  useEffect(() => {
    //@ts-expect-error ReactNativeWebView may exist
    window[name] = source;
  }, [name, source]);
};
