import styled from 'styled-components';
import * as DefaultButtonStyle from '../../../../../../components/Buttons/DefaultButton/styles';

export const RateContainer = styled.div``;

export const RateStars = styled.div`
  display: flex;
  ${DefaultButtonStyle.Container} {
    padding: 3px;
    height: 21px;

    @media (max-width: 768px) {
      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
`;
