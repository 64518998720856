import styled from 'styled-components';

interface CategoryProps {
  color: string;
  active?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CategoriesTitle = styled.h4`
  font-size: 2rem;
  font-weight: 500;
  line-height: 24px;
  color: var(--white);
  margin-bottom: 50px;
`;

export const CategoriesNav = styled.nav`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const CategoriesList = styled.ul`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Category = styled.li<CategoryProps>`
  display: flex;
  align-items: center;
  margin-bottom: 45px;
  font-size: 1.4rem;
  line-height: 24px;
  font-weight: 400;
  text-decoration: none;
  color: ${({ active }) => (active ? 'var(--lightBlue)' : 'var(--white)')};
  cursor: pointer;

  img {
    margin-right: 10px;
  }

  svg {
    margin-right: 10px;

    path {
      fill: ${({ color }) => (color ? color : 'var(--black)')};
    }
  }
`;

export const AllCoursesButton = styled.li`
  font-family: 'Nunito Sans', 'Roboto', sans-serif;
  text-transform: uppercase;
  margin-top: auto;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 19px;
  color: var(--lightBlue);
`;
