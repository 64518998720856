import React, { useEffect, useState } from 'react';
import { PlayerEventListener } from '../..';
import Script from 'react-load-script';
import { PlayerContainer } from '../../../../../Trail/components/TrailContent/components/PlayerSambaVideos/style';

interface PlayerSambaVideosProps {
  contentUrl: string;
  getEventListeners: (player: PlayerEventListener) => void;
  resume: number | boolean;
}

declare let SambaPlayer: any;

const PlayerSambaVideos: React.FC<PlayerSambaVideosProps> = ({
  contentUrl,
  getEventListeners,
  resume,
}) => {
  const [player, setPlayer] = useState(null);
  const [playerWasInstantiated, setPlayerWasInstantiated] = useState(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    const validUrlStart =
      'https://fast.player.liquidplatform.com/pApiv2/embed/';
    let localContentUrl = contentUrl;
    localContentUrl = localContentUrl.replace(validUrlStart, '');

    if (localContentUrl.includes('/')) {
      const splitedLocalContentUrl = localContentUrl.split('/');
      const playerHash = splitedLocalContentUrl[0];
      const midiaId = splitedLocalContentUrl[1];

      process.nextTick(() => {
        const divPlayer = document.getElementById('player');

        if (
          divPlayer &&
          !divPlayer.children.length &&
          !player &&
          scriptLoaded &&
          !playerWasInstantiated &&
          SambaPlayer
        ) {
          // clearAllMediaEvents: Limpa todos os eventos do player
          // Utilizado para evitar multiplas chamadas de vídeos já instanciados.
          SambaPlayer.clearAllMediaEvents();

          setPlayer(
            new SambaPlayer(divPlayer, {
              ph: playerHash,
              m: midiaId,
              playerParams: {
                captionTheme: ['FFFF00', null, null, true],
                enableControls: [
                  'play',
                  'pause',
                  'quality',
                  'fullscreen',
                  'speed',
                  'time',
                  'volume',
                  'backward',
                  'subtitles',
                ],
                enableShare: false,
                resume: resume || false,
                thumbnailMaxQuality: true,
              },
              events: {
                '*': getEventListeners,
              },
            }),
          );

          setPlayerWasInstantiated(true);
        }
      });
    }
  }, [
    contentUrl,
    getEventListeners,
    player,
    scriptLoaded,
    playerWasInstantiated,
  ]);

  return (
    <>
      <Script
        onLoad={() => {
          setScriptLoaded(true);
        }}
        url="https://player.sambatech.com.br/stable/js/samba.player.api.js"
      />
      <PlayerContainer>
        <div id="player"></div>
      </PlayerContainer>
    </>
  );
};

export default PlayerSambaVideos;
