import Faq from '../models/faq';
import FaqFromResponse from '../models/from-api/faq';

const convertFaq = (faq: FaqFromResponse): Faq => {
  return {
    id: faq.faq_id,
    answer: faq.answer,
    question: faq.question,
    isOpen: false,
  };
};

export default convertFaq;
