import React, { useCallback, useContext, useEffect, useState } from 'react';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Loading from '../../../../../../components/Loading';
import { LanguageContext } from '../../../../../../contexts/LanguageContext';
import checkEmptyString from '../../../../../../helpers/check-empty-string';
import Annotation from '../../../../../../models/annotation';
import {
  getAnnotations as getAllAnnotationsService,
  createAnnotation as createAnnotationService,
  deleteAnnotation as deleteAnnotationService,
  updateAnnotation as updateAnnotationService,
} from '../../../../../../services/annotations';
import * as Style from './styles';

interface AnnotationsProps {
  contentId: string;
}

const Annotations: React.FC<AnnotationsProps> = ({ contentId }) => {
  const { dictionary, language } = useContext(LanguageContext);
  const [newAnnotationTitle, setNewAnnotationTitle] = useState('');
  const [newAnnotationContent, setNewAnnotationContent] = useState('');
  const [annotationsToBeShown, setAnnotationsToBeShown] = useState<
    Annotation[]
  >([]);
  const [idEditingAnnotation, setIdEditingAnnotation] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const clearNewAnnotationData = () => {
    setIdEditingAnnotation('');
    setNewAnnotationTitle('');
    setNewAnnotationContent('');
  };

  const getAllAnnotations = useCallback(async () => {
    setIsLoading(true);
    setAnnotationsToBeShown([]);
    const annotations = await getAllAnnotationsService({
      content_id: contentId,
    });

    if (annotations && annotations.length) {
      setAnnotationsToBeShown(annotations);
    }
    setIsLoading(false);
  }, [contentId]);

  const createNewAnnotation = async (event: React.FormEvent) => {
    event.preventDefault();

    const newAnnotation = {
      annotation_id: '',
      title: newAnnotationTitle,
      description: newAnnotationContent,
    };

    try {
      if (checkEmptyString(newAnnotationContent)) {
        throw new Error(dictionary.ANNOTATION.INFORM_DESCRIPTION);
      }

      await createAnnotationService(newAnnotation, contentId);

      toast.success(dictionary.ANNOTATION.MODAL.CREATE.SUCCESS);

      clearNewAnnotationData();
      await getAllAnnotations();
    } catch (error) {
      Swal.fire({
        title: dictionary.ANNOTATION.MODAL.CREATE.ERROR,
        text: error.message,
        icon: 'error',
      });
    }
  };

  const removeAnnotation = async (annotationId: string) => {
    try {
      await deleteAnnotationService(`${annotationId}`);
      setAnnotationsToBeShown(
        annotationsToBeShown.filter(
          annotation => annotationId !== annotation.id,
        ),
      );

      toast.success(dictionary.ANNOTATION.MODAL.REMOVE.SUCCESS);

      clearNewAnnotationData();
      await getAllAnnotations();
    } catch (e) {
      toast.error(dictionary.ANNOTATION.MODAL.REMOVE.ERROR);
    }
  };

  const editAnnotation = (annotationId: string) => {
    setIdEditingAnnotation(annotationId);

    const annotation = annotationsToBeShown.find(
      annotation => annotationId === annotation.id,
    );

    if (annotation) {
      setNewAnnotationContent(annotation?.description);
      setNewAnnotationTitle(annotation?.title);
    }
  };

  const updateAnnotation = async (event: React.FormEvent) => {
    event.preventDefault();
    const uneditedAnnotation = annotationsToBeShown.find(
      annotation => idEditingAnnotation === annotation.id,
    );

    if (uneditedAnnotation) {
      uneditedAnnotation.title = newAnnotationTitle;
      uneditedAnnotation.description = newAnnotationContent;

      try {
        if (checkEmptyString(newAnnotationContent)) {
          throw new Error(dictionary.ANNOTATION.INFORM_TITLE);
        }

        await updateAnnotationService(idEditingAnnotation, uneditedAnnotation);

        toast.success(dictionary.ANNOTATION.MODAL.UPDATE.SUCCESS);

        clearNewAnnotationData();
        await getAllAnnotations();
      } catch (error) {
        Swal.fire({
          title: dictionary.ANNOTATION.MODAL.UPDATE.ERROR,
          text: error.message,
          icon: 'error',
        });
      }
    }
  };

  useEffect(() => {
    getAllAnnotations();
  }, [getAllAnnotations]);

  return (
    <Style.Container>
      <Style.Form
        onSubmit={e => {
          if (idEditingAnnotation !== '') {
            updateAnnotation(e);
          } else {
            createNewAnnotation(e);
          }
        }}
      >
        {
          <Style.Label>
            {idEditingAnnotation
              ? dictionary.ANNOTATION.EDIT_ANNOTATION
              : dictionary.ANNOTATION.CREATE_ANNOTATION}
          </Style.Label>
        }

        <Style.FormTitle
          type="text"
          value={newAnnotationTitle}
          onChange={e => setNewAnnotationTitle(e.target.value)}
          placeholder={dictionary.ANNOTATION.TITLE}
        />

        <Style.FormTextArea
          value={newAnnotationContent}
          onChange={e => setNewAnnotationContent(e.target.value)}
          placeholder={dictionary.ANNOTATION.WRITE_HERE}
          required={true}
        ></Style.FormTextArea>

        <Style.SubmitButton type="submit">
          {idEditingAnnotation
            ? dictionary.ANNOTATION.UPDATE_NOTE
            : dictionary.ANNOTATION.CREATE_NOTE}
        </Style.SubmitButton>
      </Style.Form>

      <Style.AnnotationList>
        {!idEditingAnnotation &&
          (isLoading ? (
            <Loading />
          ) : (
            annotationsToBeShown.map((annotation, index) => (
              <Style.Annotation key={index}>
                <Style.AnnotationHeader>
                  <Style.AnnotationTitle>
                    {annotation.title || '-'}
                  </Style.AnnotationTitle>

                  <Style.AnnotationButtonContainer>
                    <Style.AnnotationButton
                      onClick={() => {
                        editAnnotation(annotation.id);
                      }}
                    >
                      <BiEdit size={20} />
                    </Style.AnnotationButton>

                    <Style.AnnotationButton
                      onClick={() => {
                        removeAnnotation(annotation.id);
                      }}
                    >
                      <BiTrash size={20} />
                    </Style.AnnotationButton>
                  </Style.AnnotationButtonContainer>
                </Style.AnnotationHeader>

                <Style.AnnotationDate>
                  {new Intl.DateTimeFormat(language).format(annotation.date)}
                </Style.AnnotationDate>

                <Style.AnnotationContent>
                  {annotation.description}
                </Style.AnnotationContent>
              </Style.Annotation>
            ))
          ))}
      </Style.AnnotationList>
    </Style.Container>
  );
};

export default Annotations;
