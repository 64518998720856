import styled from 'styled-components';

export const Container = styled.button`
  cursor: pointer;
  display: block;
  width: 100%;
  height: 48px;
  font-size: 1.6rem;
  font-weight: bold;
  background: none;
  border: none;
  border-radius: 6px;
  margin-right: 9px;
  transition: background-color 0.2s;
`;
