import React, { useContext, useEffect, useState } from 'react';
import { AiTwotoneLike } from 'react-icons/ai';
import {
  dislikeCourse,
  likeCourse,
} from '../../../../../../services/course-like';
import { LanguageContext } from '../../../../../../contexts/LanguageContext';
import DefaultButton from '../../../../../../components/Buttons/DefaultButton';
import Swal from 'sweetalert2';
import * as Style from './styles';

interface CourseLikeProps {
  courseId: string;
  numberOfLikes: number;
  userLiked: boolean;
}

const CourseLike: React.FC<CourseLikeProps> = ({
  courseId,
  numberOfLikes,
  userLiked,
}) => {
  const { dictionary } = useContext(LanguageContext);
  const [likes, setLikes] = useState(numberOfLikes);
  const [isLiked, setIsLiked] = useState(userLiked);

  useEffect(() => {
    setLikes(numberOfLikes);
    setIsLiked(userLiked);
  }, [numberOfLikes, userLiked]);

  const onLikeClick = async () => {
    try {
      if (isLiked) {
        await dislikeCourse(courseId);
        setLikes(likes - 1);
      } else {
        await likeCourse(courseId);
        setLikes(likes + 1);
      }
      setIsLiked(!isLiked);
      Swal.fire({
        title: dictionary.MODAL.SUCCESS,
        text: dictionary.MODAL.LIKE.SUCCESS,
      });
    } catch (error) {
      Swal.fire({
        title: dictionary.MODAL.ERROR,
        text: dictionary.MODAL.LIKE.ERROR,
      });
    }
  };

  return (
    <Style.Container>
      <DefaultButton
        type="button"
        onClick={onLikeClick}
        className="transparent"
      >
        <Style.LikeIcon isLiked={isLiked}>
          <AiTwotoneLike size={20} />
        </Style.LikeIcon>
        <Style.LikeQuantity>
          {likes}{' '}
          {likes === 1 ? dictionary.COMMON.LIKE : dictionary.COMMON.LIKES}
        </Style.LikeQuantity>
      </DefaultButton>
    </Style.Container>
  );
};

export default CourseLike;
