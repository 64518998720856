import styled from 'styled-components';

export const HeaderWebViewContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background: var(--purple);

  z-index: 3;
  width: 100%;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 0;
    width: 24px;
    height: 24px;
    background: var(--white);
    box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
      0px 4px 4px -2px rgba(24, 39, 75, 0.08);
    border-radius: 23px;
  }

  h1 {
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: 0.15px;
    line-height: 24px;
    color: var(--white);
  }
`;

export const SearchIcon = styled.div`
  z-index: 1;
`;
