import React, { useContext, useEffect, useState } from 'react';
import Slider from 'react-slick';

import BannerCard from '../../../../components/BannerCard';
import CategoryCard from '../../../../components/CategoryCard';
import SliderPrevButton from '../../../../components/SliderButtons/SliderPrevButton';
import SliderNextButton from '../../../../components/SliderButtons/SliderNextButton';
import { Mobile, NotMobile } from '../../../../components/Responsive';

import { getContentCards } from '../../../../services/content';
import {
  bannerSliderSettings,
  channelSliderSettings,
  bannerMobileSliderSettings,
  channelMobileSliderSettings,
} from '../../../../config/sliders';
import Loading from '../../../../components/Loading';
import { useMediaQuery } from 'react-responsive';

import { CategoriesContext } from '../../../../contexts/CategoriesContext';

import ContentCard from '../../../../models/content-card';
import * as Style from './styles';
import { LanguageContext } from '../../../../contexts/LanguageContext';

const Banner: React.FC = () => {
  const [highlightedBanners, setHighlightedBanners] = useState<ContentCard[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(false);
  const { categories } = useContext(CategoriesContext);
  const { dictionary } = useContext(LanguageContext);

  const SmallScreens = useMediaQuery({ maxWidth: 1200 });
  const MediumScreens = useMediaQuery({ maxWidth: 1600 });

  const [bannersToShow, setBannersToShow] = useState(4);

  useEffect(() => {
    if (MediumScreens && !SmallScreens) {
      setBannersToShow(3);
      return;
    }

    if (SmallScreens) {
      setBannersToShow(2);
      return;
    }

    if (!SmallScreens) {
      setBannersToShow(4);
      return;
    }
  }, []);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const courses = await getContentCards({
        is_highlighted: true,
      });
      setHighlightedBanners(courses);
      setIsLoading(false);
    })();
  }, []);

  return (
    <Style.Container>
      {!isLoading ? (
        <>
          <NotMobile>
            {highlightedBanners && highlightedBanners.length > 0 && (
              <Slider
                {...bannerSliderSettings}
                centerMode={highlightedBanners.length !== 1}
                prevArrow={<SliderPrevButton />}
                nextArrow={<SliderNextButton />}
              >
                {highlightedBanners.map(content => (
                  <BannerCard key={content.id} content={content} />
                ))}
              </Slider>
            )}

            <Style.ChannelWrapper>
              {categories && categories.length > 0 && (
                <Slider
                  {...channelSliderSettings}
                  slidesToShow={bannersToShow}
                  prevArrow={<SliderPrevButton />}
                  nextArrow={<SliderNextButton />}
                >
                  {categories.map(category => (
                    <CategoryCard key={category.id} category={category} />
                  ))}
                </Slider>
              )}
            </Style.ChannelWrapper>
          </NotMobile>
          <Mobile>
            {highlightedBanners && highlightedBanners.length > 0 && (
              <Slider
                {...bannerMobileSliderSettings}
                centerMode={highlightedBanners.length !== 1}
              >
                {highlightedBanners.map(content => (
                  <BannerCard key={content.id} content={content} />
                ))}
              </Slider>
            )}
            <Style.Title>{dictionary.COMMON.CHANNELS}</Style.Title>
            {categories && categories.length > 0 && (
              <Slider {...channelMobileSliderSettings}>
                {categories.map(category => (
                  <CategoryCard key={category.id} category={category} />
                ))}
              </Slider>
            )}
          </Mobile>
        </>
      ) : (
        <Loading />
      )}
    </Style.Container>
  );
};

export default Banner;
