import httpClient from '../../http-client';

const getCourseReview = async (courseId: string) => {
  const response = (
    await httpClient.get(`/content-user-reviews/${courseId}/user`)
  ).data;

  return response;
};

const reviewCourse = async (courseId: string, stars: number) => {
  await httpClient.post(`/content-user-reviews`, {
    content_id: courseId,
    stars: stars,
  });
};

const updateCourseReview = async (courseId: string, stars: number) => {
  await httpClient.put(`/content-user-reviews/${courseId}`, {
    stars: stars,
  });
};

export { getCourseReview, reviewCourse, updateCourseReview };
