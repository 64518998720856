import styled from 'styled-components';

export const Container = styled.div`
  width: 285px;
  height: 160px;
  border-radius: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 0;

  img {
    height: 160px;
  }

  p {
    font-size: 1.6rem;
    font-weight: 500;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    text-align: center;
  }

  @media (max-width: 768px) {
    width: 205px;
    height: 110px;

    img {
      height: 110px;
    }
  }
`;
