import styled from 'styled-components';
import StarsBackground from '../../../../assets/stars-background.png';

interface BannerProps {
  color: string;
}

export const Container = styled.div`
  padding: 46px 0 86px;

  @media (max-width: 768px) {
    padding: 32px 0 86px;
  }
`;

export const Header = styled.header`
  margin-bottom: 85px;

  @media (max-width: 768px) {
    margin-bottom: 56px;
  }
`;

export const Banner = styled.div<BannerProps>`
  background-color: ${({ color }) => (color ? color : '#e5e8eb')};
  border-radius: 8px;
  padding: 24px;
  text-align: center;
  margin-bottom: 26px;
  position: relative;

  img {
    height: 150px;
    width: auto;
  }

  @media (max-width: 768px) {
    margin-bottom: 16px;

    img {
      height: auto;
      width: 195px;
    }
  }
`;

export const Description = styled.p`
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 24px;
  color: var(--neutralLight40);
`;

export const CourseListContainer = styled.div`
  margin-bottom: 180px;
`;

export const CourseList = styled.div`
  display: grid;
  gap: 30px 16px;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));

  @media (max-width: 768px) {
    justify-items: center;
  }
`;

export const NotFound = styled.p`
  font-weight: bold;
  font-size: 1.4rem;
`;
