import styled from 'styled-components';

export const Container = styled.div`
  font-size: 1.4rem;
  line-height: 20px;

  h1 {
    text-align: center;
    margin-bottom: 30px;
    line-height: 30px;
  }

  p {
    margin: 15px 0;
  }

  ol {
    padding: revert;
    list-style-type: lower-roman;

    li {
      margin: 15px;
    }
  }
`;
