import httpClient from '../../http-client';
import OngoingCourse from '../../models/from-api/ongoing-course';

const startCourse = async (courseId: string, trailId: string) => {
  httpClient.post(`/trail-course-users`, {
    trail_id: trailId,
    course_id: courseId,
  });
};

const finishCourse = async (courseId: string, trailId: string) => {
  httpClient.put(`/trail-course-users/finish`, {
    trail_id: trailId,
    course_id: courseId,
  });
};

const getOngoingCourse = async (filters?: object) => {
  const courses = (
    await httpClient.get<OngoingCourse[]>('/contents-user/me/in-progress', {
      params: filters,
    })
  ).data;

  return courses;
};

export { startCourse, finishCourse, getOngoingCourse };
