import styled from 'styled-components';
import StarsBackground from '../../assets/stars-background.png';

interface LinkItemProps {
  isBold: boolean;
}

export const Container = styled.footer`
  width: 100%;
  margin: 0 auto;
  padding: 64px 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin-bottom: 56px;
    z-index: 1;

    @media (max-width: 768px) {
      width: 110px;
    }
  }
`;

export const LinkList = styled.ul`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: wrap;
  gap: 32px;
  margin-bottom: 48px;
  z-index: 1;

  @media (max-width: 768px) {
    margin-bottom: 60px;
    flex-direction: column;
    align-items: center;

    &:nth-of-type(2) {
      border-top: 1px solid rgba(203, 203, 203, 0.22);
      padding-top: 59px;
      margin-bottom: 118px;
    }
  }
`;

export const LinkItem = styled.li<LinkItemProps>`
  color: white;
  font-size: ${({ isBold }) => (isBold ? '1.8rem' : '1.4rem')};
  font-weight: ${({ isBold }) => (isBold ? '500' : '400')};
  text-transform: ${({ isBold }) => (isBold ? 'none' : 'uppercase')};
  line-height: ${({ isBold }) => (isBold ? '24px' : '18px')};

  a {
    text-decoration: none;
    color: white;
    position: relative;
  }

  a:after {
    content: '';
    width: 0;
    opacity: 0;
    height: 2px;
    bottom: -6px;
    left: 0;
    position: absolute;
    transition: all 0.3s ease-in-out;
    background: white;
  }

  @media (hover: hover) and (pointer: fine) {
    a:hover {
      &:after {
        width: 100%;
        opacity: 1;
      }
    }
  }

  @media (max-width: 768px) {
    text-transform: none;
    font-size: 2rem;
    line-height: 24px;

    &.mobile-text-style {
      font-weight: 400;
      line-height: 16px;
      font-size: 1.4rem;
    }
  }
`;

export const CopyrightText = styled.p`
  color: white;
  font-size: 1.4rem;
  text-transform: uppercase;
  z-index: 1;

  @media (max-width: 768px) {
    font-size: 1rem;
    letter-spacing: 1.5px;
  }
`;
