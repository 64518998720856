import styled from 'styled-components';

interface AnnotationCardProps {
  isActive: boolean;
}

export const Container = styled.div<AnnotationCardProps>`
  border: 1px solid
    ${({ isActive }) => (isActive ? 'var(--purple)' : 'var(--neutralLight40)')};
  box-shadow: ${({ isActive }) =>
    isActive ? '0px 18px 32px -14px #B3BAC5' : 'none'};
  border-radius: 4px;
  background-color: white;
  padding: 13px 24px;

  & + & {
    margin-top: 16px;
  }

  @media (max-width: 768px) {
    padding: 13px;
    background-color: transparent;
    border: 1px solid rgba(66, 82, 110, 0.64);
    border-radius: 8px;
    box-shadow: none;
    margin-bottom: 10px;
  }
`;

export const Header = styled.div<AnnotationCardProps>`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    flex-shrink: 0;
    stroke-width: 1px;
    margin-right: 21px;
    transform: rotate(${({ isActive }) => (isActive ? '180deg' : '0')});
    transition: transform 0.2s linear;
  }

  @media (max-width: 768px) {
    justify-content: space-between;
    border-bottom: ${({ isActive }) =>
      isActive ? '1px solid rgba(255, 255, 255, 0.15)' : '0)'};

    padding-bottom: 8px;
  }
`;

export const Info = styled.div``;

export const Category = styled.p`
  color: var(--lightBlue);
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 3px;

  @media (max-width: 768px) {
    font-weight: 500;
    font-size: 1rem;
    line-height: 12px;
    letter-spacing: 4px;
  }
`;

export const Title = styled.p`
  color: var(--neutralDark900);
  font-size: 2rem;
  font-weight: 500;
  line-height: 24px;

  @media (max-width: 768px) {
    font-size: 1.6rem;
    color: #ffffff;
    font-size: 1.2rem;
    line-height: 16px;
  }
`;

export const AnnotationList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px;
  border: 1px solid var(--neutralLight40);
  border-radius: 4px;
  background-color: var(--neutralLight10);
  max-width: 830px;
  margin-top: 21px;
  margin-bottom: 33px;
  margin-left: 39px;

  @media (max-width: 768px) {
    margin-left: 0;
    justify-items: center;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 39px;

  @media (max-width: 768px) {
    padding-left: 0;
    justify-items: center;
  }
`;
