import React from 'react';
import Skeleton from '..';

import { Container } from './styles';

const CourseCardSkeleton: React.FC = () => {
  return (
    <Container>
      <Skeleton className="white">
        <Skeleton className="white-secundary" />
        <Skeleton className="white-secundary" />
        <Skeleton className="white-secundary" />
      </Skeleton>
    </Container>
  );
};

export default CourseCardSkeleton;
