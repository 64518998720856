import styled, { css } from 'styled-components';
import { isInWebview } from './../../helpers/reactNativeWebView';

export const Container = styled.div`
  margin: auto;
  max-width: 1424px;
  width: 100%;
  min-height: 40vh;
  z-index: 2;
  flex: 1;
`;

export const PageTitle = styled.h1`
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 32px;
  color: var(--neutralLight40);
  margin: 56px 0;

  @media (max-width: 768px) {
    font-size: 2rem;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin: 32px 0 16px;
  }
`;

export const Body = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Navbar = styled.div`
  min-width: 335px;
  width: auto;
  flex-shrink: 0;
  background-color: var(--darkPurple);
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.13);
  border-radius: 0px 4px 0px 0px;
  padding: 85px 85px 180px 5px;
  display: flex;
  flex-direction: column;
  position: relative;

  a {
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 22px;
    color: var(--lightBlue);
    text-decoration: none;
    padding-left: 33.5px;

    & + a {
      margin-top: 54px;
    }

    svg {
      margin-right: 17px;
    }

    &.active {
      position: relative;
      color: #ffffff;
      font-weight: 700;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 7.5px;
        background-color: #ffffff;
        border-radius: 8px;
      }
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: calc(-100vw + 5px);
    background-color: none;
    height: 100%;
    width: 100vw;
  }

  @media (max-width: 768px) {
    min-width: initial;
    width: auto;
    background-color: unset;
    box-shadow: none;
    border-radius: 0;
    padding: 0 0 10px 0;
    flex-direction: row;
    overflow-x: auto;

    a {
      position: relative;
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 16px;
      padding: 12px 8px;
      text-transform: uppercase;
      white-space: nowrap;

      & + a {
        margin-top: 0;
        padding-left: 18px;
      }

      svg {
        height: 15px;
        width: 15px;
        flex-shrink: 0;
        margin-right: 9px;
      }

      &.active {
        position: relative;
        color: var(--blue);
        font-weight: 700;

        &:after {
          content: '';
          position: absolute;
          top: unset;
          bottom: 0;
          left: 0;
          background-color: var(--purple);
          height: 2px;
          width: 100%;
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: unset;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.2);
        height: 1px;
        width: 100%;
      }
    }

    &:after {
      display: none;
    }
  }
`;

export const Content = styled.div`
  margin-left: 20px;
  width: 100%;

  @media (min-width: 769px) and (max-width: 1460px) {
    overflow: hidden;
  }

  @media (max-width: 768px) {
    top: ${!isInWebview ? 'none' : '0'};
    margin-left: 0;
    margin-top: 40px;
  }
`;
