import React, { useState, useEffect, useContext } from 'react';
import { FiSearch } from 'react-icons/fi';
import CourseCard from '../../../../components/CourseCard';
import CourseCardSkeleton from '../../../../components/CourseCardSkeleton';
import Pagination from '../../../../components/Pagination';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import ContentCard from '../../../../models/content-card';
import { getContentCards } from '../../../../services/content';
import { ReactComponent as Sliders } from '../../../../assets/svgs/Sliders.svg';

import * as Style from './styles';
import { isInWebview } from '../../../../helpers/reactNativeWebView';

const RecommendedCourses: React.FC = () => {
  const { dictionary } = useContext(LanguageContext);
  const [contents, setContents] = useState([] as ContentCard[]);
  const [shouldShowSkeleton, setShouldShowSkeleton] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const contentsPerPage = 8;
  const filter = { recommended: true };

  const filteredContents = contents.filter(content =>
    content.title
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()),
  );

  useEffect(() => {
    (async () => {
      const allContents = await getContentCards(filter);

      if (allContents && allContents.length) {
        setTotalPages(Math.ceil(allContents.length / contentsPerPage));
      } else {
        setShouldShowSkeleton(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setContents([]);
      const currentContents = await getContentCards({
        ...filter,
        limit: contentsPerPage,
        offset: page > 1 ? (page - 1) * contentsPerPage : undefined,
      });

      if (currentContents && currentContents.length) {
        setContents(currentContents);
      } else {
        setShouldShowSkeleton(false);
      }
    })();
  }, [page]);

  return (
    <Style.Container>
      {isInWebview ? (
        <Style.TitleWebView>
          {dictionary.MY_COURSES.RECOMMENDED_COURSES}
          <br />
          <span>({contents.length}) Videos</span>
        </Style.TitleWebView>
      ) : null}

      <Style.Title>{dictionary.MY_COURSES.RECOMMENDED_COURSES}</Style.Title>
      {isInWebview ? (
        <Style.StyledInput>
          <span className="iconSliders">
            <Sliders />
          </span>

          <input
            type="text"
            name="search"
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            placeholder={dictionary.MY_COURSES.SEARCH_RECOMMENDED_COURSES}
            required
          />
          <FiSearch size={20} />
        </Style.StyledInput>
      ) : null}

      <Style.ContentList>
        {filteredContents && filteredContents.length ? (
          filteredContents.map(content => (
            <CourseCard key={content.id} content={content} />
          ))
        ) : shouldShowSkeleton ? (
          Array(8)
            .fill('')
            .map((i, index) => <CourseCardSkeleton key={index} />)
        ) : (
          <Style.NotFound>{dictionary.COMMON.NOT_FOUND}</Style.NotFound>
        )}
      </Style.ContentList>
      {totalPages > 1 && (
        <Pagination totalPages={totalPages} setPage={setPage} />
      )}
    </Style.Container>
  );
};

export default RecommendedCourses;
