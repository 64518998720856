import React, { useContext } from 'react';
import { Route, Switch, NavLink } from 'react-router-dom';
import { FiTarget, FiPlayCircle, FiBookmark, FiAward } from 'react-icons/fi';
import { BiNotepad } from 'react-icons/bi';
import OngoingCourses from './routes/OngoingCourses';
import FavoriteCourses from './routes/FavoriteCourses';
import Certificates from './routes/Certificates';
import Annotations from './routes/Annotations';
import RecommendedCourses from './routes/RecommendedCourses';
import Breadcrumb from '../../components/Breadcrumb';
import { LanguageContext } from '../../contexts/LanguageContext';
import * as Style from './styles';
import { isInWebview } from '../../helpers/reactNativeWebView';

const MyCourses: React.FC = () => {
  const { dictionary } = useContext(LanguageContext);

  const crumbs = [
    { name: 'HOME', path: '/home' },
    { name: dictionary.MY_COURSES.MY_COURSES, path: '' },
  ];

  return (
    <Style.Container>
      {!isInWebview ? (
        <>
          <Breadcrumb crumbs={crumbs} />
          <Style.PageTitle>{dictionary.MY_COURSES.MY_COURSES}</Style.PageTitle>
        </>
      ) : null}
      <Style.Body>
        {!isInWebview ? (
          <>
            <Style.Navbar>
              <NavLink to="/my-courses/ongoing">
                <FiPlayCircle size={22} />
                {dictionary.MY_COURSES.ONGOING}
              </NavLink>
              <NavLink to="/my-courses/favorite">
                <FiBookmark size={22} />
                {dictionary.MY_COURSES.FAVORITES}
              </NavLink>
              <NavLink to="/my-courses/certificates">
                <FiAward size={22} />
                {dictionary.MY_COURSES.CERTIFICATES}
              </NavLink>
              <NavLink to="/my-courses/annotations">
                <BiNotepad size={22} />
                {dictionary.COMMON.ANNOTATIONS}
              </NavLink>
              <NavLink to="/my-courses/recommended">
                <FiTarget size={22} />
                {dictionary.MY_COURSES.RECOMMENDED}
              </NavLink>
            </Style.Navbar>
          </>
        ) : null}
        <Style.Content>
          <Switch>
            <Route path="/my-courses/ongoing" component={OngoingCourses} />
            <Route path="/my-courses/favorite" component={FavoriteCourses} />
            <Route path="/my-courses/certificates" component={Certificates} />
            <Route path="/my-courses/annotations" component={Annotations} />
            <Route
              path="/my-courses/recommended"
              component={RecommendedCourses}
            />
          </Switch>
        </Style.Content>
      </Style.Body>
    </Style.Container>
  );
};

export default MyCourses;
