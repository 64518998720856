import { useMediaQuery } from 'react-responsive';

const useResponsive = () => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return {
    isNotMobile,
    isMobile,
  };
};

export default useResponsive;
