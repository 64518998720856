import React, { useContext } from 'react';
import { BiDownload } from 'react-icons/bi';
import { LanguageContext } from '../../../../../../contexts/LanguageContext';
import { formatName } from '../../../../../../helpers/extraMaterials';
import ExtraMaterial from '../../../../../../models/extra-material';
import * as Style from './styles';
import fileDownload from 'js-file-download';
import axios from 'axios';

interface ExtraMaterialsProps {
  extraMaterials: ExtraMaterial[];
}

const ExtraMaterials: React.FC<ExtraMaterialsProps> = ({ extraMaterials }) => {
  const { dictionary } = useContext(LanguageContext);
  const downloadMaterial = (material: ExtraMaterial) => {
    axios
      .get(material.reference, {
        responseType: 'blob',
      })
      .then(res => {
        fileDownload(res.data, formatName(material.name));
      });
  };

  return (
    <Style.Container>
      <Style.MaterialsList>
        {extraMaterials && extraMaterials.length ? (
          extraMaterials.map(material => (
            <Style.MaterialItem key={material.id}>
              {formatName(material.name)}
              <button type="button" onClick={() => downloadMaterial(material)}>
                <BiDownload size={18} />
                Download
              </button>
            </Style.MaterialItem>
          ))
        ) : (
          <Style.NotFound>
            {dictionary.COURSE.EXTRA_MATERIALS.NOT_FOUND}
          </Style.NotFound>
        )}
      </Style.MaterialsList>
    </Style.Container>
  );
};

export default ExtraMaterials;
