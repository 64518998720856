import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  text-align: center;
  margin: auto;
  display: flex;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 40px;
  color: #ffffff;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  align-self: center;
  border: 1px solid var(--neutralDark500);
`;
