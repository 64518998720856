import { useContext } from 'react';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import BrazilFlag from '../../../../assets/brazil-flag.png';
import UsaFlag from '../../../../assets/usa-flag.png';

import * as Style from './styles';

const LanguageSelectorImage = () => {
  const { language, setLanguage } = useContext(LanguageContext);

  const onLanguageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLanguage(event.target.value);
  };

  return (
    <Style.Container>
      <Style.Label checked={language === 'pt-BR'}>
        <img src={BrazilFlag} alt="Brazil Flag" />
        <input
          type="radio"
          name="language"
          value="pt-BR"
          checked={language === 'pt-BR'}
          onChange={e => onLanguageChange(e)}
        />
        PT
      </Style.Label>
      <Style.Label checked={language === 'en-US'}>
        <img src={UsaFlag} alt="Usa Flag" />
        <input
          type="radio"
          name="language"
          value="en-US"
          checked={language === 'en-US'}
          onChange={e => onLanguageChange(e)}
        />
        EN
      </Style.Label>
    </Style.Container>
  );
};

export default LanguageSelectorImage;
