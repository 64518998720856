import styled from 'styled-components';
import * as DefaultButtonStyle from '../../../../../../components/Buttons/DefaultButton/styles';

interface LikeIconProps {
  isLiked: boolean;
}

export const Container = styled.div`
  ${DefaultButtonStyle.Container} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 25px;

    @media (max-width: 768px) {
      height: 21px;
    }
  }
`;

export const LikeIcon = styled.span<LikeIconProps>`
  margin-right: 15px;

  svg {
    color: ${({ isLiked }) =>
      isLiked ? 'var(--neutralLight40)' : 'var(--neutralLight40)'};
    path:first-child {
      fill: ${({ isLiked }) =>
        isLiked ? 'var(--neutralLight40)' : 'transparent'};
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      svg {
        color: var(--neutralLight40);
        path:first-child {
          fill: var(--neutralLight40);
        }
      }
    }
  }

  @media (max-width: 768px) {
    svg {
      width: 13px;
      height: 13px;
    }
  }
`;

export const LikeQuantity = styled.span`
  color: var(--neutralLight40);
  font-size: 1.6rem;
  font-weight: 400;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    line-height: 16px;
  }
`;
