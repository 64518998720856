import React, { useState, useEffect, useContext, useCallback } from 'react';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { AllAnnotation } from '../../../../models/all-annotation';
import { getAllAnnotations } from '../../../../services/annotations';
import AnnotationCard from './components/AnnotationCard';
import AnnotationTrailCardSkeleton from './components/AnnotationTrailCardSkeleton';
import { ReactComponent as Sliders } from '../../../../assets/svgs/Sliders.svg';

import * as Style from './styles';
import { FiSearch } from 'react-icons/fi';
import { isInWebview } from '../../../../helpers/reactNativeWebView';

const Annotations: React.FC = () => {
  const { dictionary } = useContext(LanguageContext);
  const [notes, setNotes] = useState([] as AllAnnotation[]);
  const [shouldShowSkeleton, setShouldShowSkeleton] = useState(true);
  const [searchValue, setSearchValue] = useState('');

  const filteredNotes = notes.filter(note =>
    note.title.toLowerCase().trim().includes(searchValue.toLowerCase().trim()),
  );

  const refreshAnnotations = useCallback(async () => {
    const allAnnotations = await getAllAnnotations();
    if (allAnnotations && allAnnotations.length) {
      setNotes(allAnnotations);
    } else {
      setShouldShowSkeleton(false);
    }
    setNotes(allAnnotations);
  }, []);

  useEffect(() => {
    refreshAnnotations();
  }, [refreshAnnotations]);

  return (
    <Style.Container>
      {isInWebview ? (
        <Style.TitleWebView>
          {dictionary.COMMON.ANNOTATIONS}
          <br />
          <span>
            ({notes.length}) {dictionary.COMMON.ANNOTATIONS}
          </span>
        </Style.TitleWebView>
      ) : null}
      <Style.Title>{dictionary.COMMON.ANNOTATIONS}</Style.Title>
      {isInWebview ? (
        <Style.StyledInput>
          <span className="iconSliders">
            <Sliders />
          </span>

          <input
            type="text"
            name="search"
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            placeholder={dictionary.ANNOTATION.SEARCH_ANNOTATION}
            required
          />
          <FiSearch size={20} />
        </Style.StyledInput>
      ) : null}

      <Style.AnnotationList>
        {filteredNotes && filteredNotes.length ? (
          filteredNotes.map(course => (
            <AnnotationCard
              key={course.id}
              course={course}
              refreshAnnotations={refreshAnnotations}
            />
          ))
        ) : shouldShowSkeleton ? (
          Array(3)
            .fill('')
            .map((i, index) => <AnnotationTrailCardSkeleton key={index} />)
        ) : (
          <Style.NotFound>{dictionary.COMMON.NOT_FOUND}</Style.NotFound>
        )}
      </Style.AnnotationList>
    </Style.Container>
  );
};

export default Annotations;
