import styled from 'styled-components';

const ProgressBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .course-progress-bar {
    flex-grow: 1;
    height: 16px;
    background-color: rgba(255, 255, 255, 0.35);
    border-radius: 4px;

    .progress {
      background-color: var(--lightBlue);
      height: 100%;
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
      transition: ease 0.5s all;
    }
  }

  .course-progress-percentage {
    margin-left: 10px;
    font-size: 1.4rem;
    font-weight: bold;
    color: #ffffff;

    &.darken-progress {
      color: var(--neutralLight40);
    }
  }
  @media (max-width: 768px) {
    max-width: 200px;
    align-items: flex-end;
    flex-direction: column-reverse;
    margin-top: -28px;
    margin-bottom: 8px;
    .course-progress-bar {
      height: 4px;
      width: 100%;

      .progress {
        background-color: var(--blue);
      }
    }
    .course-progress-percentage {
      margin-bottom: 8px;
      font-size: 1rem;
      font-weight: 500;
      line-height: 16px;
    }
  }
`;

export { ProgressBarContainer };
