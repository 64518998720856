import styled from 'styled-components';

interface ContainerProps {
  isSidebarOpen: boolean;
}

export const Container = styled.div<ContainerProps>`
  background-color: ${({ isSidebarOpen }) =>
    isSidebarOpen ? 'rgba(0, 0, 0, 0.2)' : 'transparent'};
  visibility: ${({ isSidebarOpen }) => (isSidebarOpen ? 'visible' : 'hidden')};
  height: 100%;
  width: 100vw;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-backface-visibility: hidden;
  z-index: 1;
  transition: all 0.2s linear;
  z-index: 3; // TODO: fix this.
`;
