import {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { options } from '../config/languages';
import Language from '../config/languages/language-interface';
import { useRNWebViewExpose } from '../helpers/reactNativeWebView';

interface Context {
  language: string;
  setLanguage: Dispatch<SetStateAction<string>>;
  dictionary: Language;
}

export const LanguageContext = createContext<Context>({} as Context);

export const LanguageProvider: React.FC = ({ children }) => {
  const [language, setLanguage] = useState(
    localStorage.getItem('alpargatas-language-option') || 'pt-BR',
  );

  useEffect(() => {
    localStorage.setItem('alpargatas-language-option', language);
  }, [language]);

  useRNWebViewExpose(setLanguage, 'setLanguage');

  return (
    <LanguageContext.Provider
      value={{
        language,
        setLanguage,
        dictionary: options[language] as Language,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
