import styled from 'styled-components';
import StarsBackground from '../../assets/stars-background.png';

export const Container = styled.div`
  margin: auto;
  width: 100%;
  max-width: 1424px;

  &:before {
    content: '';
    background-image: url(${StarsBackground});
    background-position: center;
    background-repeat: repeat;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;
