import styled from 'styled-components';

interface ProfileContainerProps {
  profileIsOpen: boolean;
}

export const HeaderContainer = styled.header`
  padding: 64px 0 0;
  position: relative;
  z-index: 3;

  @media (max-width: 768px) {
    padding: 16px 0;
  }
`;

export const HeaderComponents = styled.div`
  max-width: 1424px;
  width: 100%;

  margin: auto;

  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    z-index: 1;
    img {
      width: 160px;
    }
  }
  @media (max-width: 768px) {
    a {
      img {
        width: 80px;
      }
    }
  }
`;

export const HeaderUtilities = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ProfileContainer = styled.div<ProfileContainerProps>`
  position: relative;
  display: flex;
  align-items: center;

  height: 100%;

  margin-left: 24px;

  img {
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  nav {
    visibility: ${({ profileIsOpen }) =>
      profileIsOpen ? 'visible' : 'hidden'};
    transition: visibility 0.1s;
  }
`;

export const Profile = styled.span`
  font-size: 2.2rem;
  margin-right: 16px;
  color: white;
  font-weight: 500;
`;

export const SearchIcon = styled.div`
  z-index: 1;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 767px) {
    margin: 0 32px 0 auto;
  }
`;
