import React, { useContext, useEffect, useRef, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { LanguageContext } from '../../../../../../contexts/LanguageContext';
import { secondsToTimeText } from '../../../../../../helpers/time';
import { IoPlayOutline } from 'react-icons/io5';
import Content from '../../../../../../models/content';
import Course from '../../../../../../models/course';
import TrailCourseContent from './components/TrailCourseContent';

import * as Style from './styles';

interface TrailCourseProps {
  course: Course;
  currentContent: Content;
  close: boolean;
  onOpen: () => void;
  // showInitButton: boolean;
}

const TrailCourse: React.FC<TrailCourseProps> = ({
  course,
  currentContent,
  onOpen,
  close,
  // showInitButton,
}) => {
  const { dictionary } = useContext(LanguageContext);
  const [opened, setOpened] = useState(course.isFirstCourse);
  const [showMore, setShowMore] = useState(false);
  const [isFlex, setFlex] = useState(true);
  const [showAllContents, setShowAllContents] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const checkIfIsSelected = (content: Content): boolean => {
    return !!(
      content.id === currentContent.id &&
      content.courseId === currentContent.courseId &&
      content.trailId &&
      currentContent.trailId
    );
  };

  useEffect(() => {
    if (
      course.contents &&
      course.contents.some(
        content =>
          content.id === currentContent.id &&
          content.courseId === currentContent.courseId &&
          content.trailId === currentContent.trailId,
      )
    ) {
      setOpened(true);
    } else if (currentContent.id) {
      setOpened(false);
    }
  }, [course.contents, currentContent]);

  const verifiedHeight = () => {
    if (containerRef.current && contentRef.current) {
      const { offsetHeight: areaUtil } = containerRef.current;
      const { offsetHeight: heightTotal } = contentRef.current;

      if (heightTotal + 53 > areaUtil) {
        setShowMore(true);
        setShowAllContents(false);
        setFlex(true);
      } else {
        setShowMore(false);
        setFlex(false);
      }
    }
  };

  useEffect(() => {
    if (close) {
      setOpened(false);
      setShowMore(false);
      setShowAllContents(true);
      setFlex(true);
    }
  }, [close]);

  useEffect(() => {
    if (!opened) {
      setShowAllContents(false);
      setFlex(true);
    } else {
      onOpen();
    }

    if (containerRef.current) {
      setTimeout(verifiedHeight, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened]);

  return (
    <Style.Container
      ref={containerRef}
      opened={opened}
      showMore={showAllContents}
      isFlex={isFlex}
    >
      <Style.UpperContent onClick={() => setOpened(!opened)} opened={opened}>
        <Style.Title>
          {dictionary.TRAIL.SEASON}: {course.title}
        </Style.Title>
        <Style.Duration>{secondsToTimeText(course.duration)}</Style.Duration>

        <Style.CollapseIndicatorContainer opened={opened}>
          <BiChevronDown />
        </Style.CollapseIndicatorContainer>
      </Style.UpperContent>

      {/* {showInitButton && opened && (
        <Style.ContainerStartButton>
          <Style.StartButton>
            Iniciar série <IoPlayOutline style={{ marginLeft: '10px' }} />
          </Style.StartButton>
        </Style.ContainerStartButton>
      )} */}

      {opened && showMore && (
        <Style.SeeAll
          onClick={() => {
            setShowMore(false);
            setShowAllContents(true);
          }}
          type="button"
        >
          Ver mais...({course.contents.length - 10})
        </Style.SeeAll>
      )}

      {opened && (
        <Style.LowerContent ref={contentRef}>
          {course.contents &&
            course.contents.length &&
            course.contents.map(content => (
              <>
                <TrailCourseContent
                  key={content.id}
                  content={content}
                  isSelected={checkIfIsSelected(content)}
                />
              </>
            ))}
        </Style.LowerContent>
      )}
    </Style.Container>
  );
};

export default TrailCourse;
