import { useContext, useEffect, useState } from 'react';
import { AiOutlineDownload } from 'react-icons/ai';
import { FiSearch } from 'react-icons/fi';
import Loading from '../../../../components/Loading';
import Pagination from '../../../../components/Pagination';
import { Mobile, NotMobile } from '../../../../components/Responsive';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import {
  downloadCertificate,
  getCertificates,
  CertificateProps,
} from '../../../../services/certificate';
import { getTrails } from '../../../../services/trail';
import InfoCell from './components/InfoCell';
import MobileCertificate from './components/MobileCertificate';
import { ReactComponent as Sliders } from '../../../../assets/svgs/Sliders.svg';
import * as Style from './styles';
import { isInWebview } from '../../../../helpers/reactNativeWebView';

const Certificates = () => {
  const { language, dictionary } = useContext(LanguageContext);
  const [contents, setContents] = useState([] as CertificateProps[]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);

  const [page, setPage] = useState(1);
  const contentsPerPage = 999;
  const filter = { is_finished: true };

  const [searchValue, setSearchValue] = useState('');

  const [count, setCount] = useState(0);
  const [totalShowing, setTotalShowing] = useState(0);
  const INITIAL_VALUE_SHOWING = 6;
  const VALUE_TO_SHOW = 3;

  useEffect(() => {
    (async () => {
      const allContents = await getTrails(filter);

      if (allContents && allContents.length) {
        setTotalPages(Math.ceil(allContents.length / contentsPerPage));
      } else {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setContents([]);
      setIsLoading(true);
      const currentContents = await getCertificates({
        ...filter,
        limit: undefined,
        offset: undefined,
      });

      if (currentContents && currentContents.length) {
        setContents(currentContents);
      } else {
        setIsLoading(false);
      }
    })();
  }, [page]);

  useEffect(() => {
    if (totalShowing <= contents.length) {
      return setTotalShowing(INITIAL_VALUE_SHOWING + count * VALUE_TO_SHOW);
    } else {
      return setTotalShowing(contents.length);
    }
  }, [count]);

  const filteredContents = contents.filter(content =>
    content.name
      .toLowerCase()
      .trim()
      .includes(searchValue.toLowerCase().trim()),
  );

  const onButtonClick = async (id: string) => {
    await downloadCertificate(id);
  };

  const formatDate = (date: Date) => {
    return new Intl.DateTimeFormat(language).format(date);
  };

  return (
    <Style.Container id="certificates">
      {isInWebview ? (
        <Style.TitleWebView>
          {dictionary.MY_COURSES.COMPLETED_CONTENTS}
          <br />
          <span>({contents.length}) concluídos</span>
        </Style.TitleWebView>
      ) : (
        <Style.Title>{dictionary.MY_COURSES.COMPLETED_CONTENTS}</Style.Title>
      )}
      <Style.SpaceBetween>
        {isInWebview ? (
          <Style.StyledInput>
            <Mobile>
              <Style.SliderWrapper className="iconSliders">
                <Sliders />
              </Style.SliderWrapper>
            </Mobile>
            <input
              type="text"
              name="search"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              placeholder={dictionary.MY_COURSES.SEARCH_COMPLETED_COURSES}
              required
            />
            <FiSearch size={20} />
          </Style.StyledInput>
        ) : null}
      </Style.SpaceBetween>

      <NotMobile>
        <Style.Table>
          <Style.TableHead>
            <tr>
              <th>Curso</th>
              <th style={{ width: '27%' }}>
                {dictionary.MY_COURSES.CERTIFICATE_PAGE.AUTHOR}
              </th>
              <th style={{ width: '250px' }}>
                {dictionary.MY_COURSES.CERTIFICATE_PAGE.DATE}
              </th>
              <th style={{ width: '203px' }}>
                {dictionary.MY_COURSES.CERTIFICATE_PAGE.CERTIFICATE}
              </th>
            </tr>
          </Style.TableHead>
          <Style.TableBody>
            {filteredContents && filteredContents.length ? (
              filteredContents.slice(0, totalShowing).map(content => (
                <tr key={content.reference_id}>
                  <td>
                    <Style.Category>{content.category}</Style.Category>
                    <Style.CourseTitle>{content.name}</Style.CourseTitle>
                  </td>
                  <td>{content.author || '-'}</td>
                  <td>
                    {content.finish_at &&
                      formatDate(new Date(content.finish_at))}
                  </td>
                  <td>
                    <Style.DownloadButton
                      onClick={() => onButtonClick(content.reference_id)}
                    >
                      {
                        dictionary.MY_COURSES.CERTIFICATE_PAGE
                          .DOWNLOAD_CERTIFICATE
                      }
                      <AiOutlineDownload size={18} />
                    </Style.DownloadButton>
                  </td>
                </tr>
              ))
            ) : isLoading ? (
              <tr>
                <td colSpan={4}>
                  <Loading />
                </td>
              </tr>
            ) : (
              <tr>
                <td colSpan={4}>
                  <Style.NotFound>{dictionary.COMMON.NOT_FOUND}</Style.NotFound>
                </td>
              </tr>
            )}
          </Style.TableBody>
        </Style.Table>
      </NotMobile>

      <Mobile>
        <Style.ContainerCertificateMobileComponent>
          {filteredContents && filteredContents.length ? (
            filteredContents.slice(0, totalShowing).map(content => {
              const DateFormatted = formatDate(new Date(content.finish_at));
              return (
                <MobileCertificate
                  key={content.reference_id}
                  thumbnail={content.thumbnail}
                  author={content.author}
                  name={content.name}
                  finish_at={DateFormatted}
                  reference_id={content.reference_id}
                  onButtonClick={onButtonClick}
                />
              );
            })
          ) : isLoading ? (
            <Style.ContainerLoading>
              <Loading />
            </Style.ContainerLoading>
          ) : (
            <Style.ContainerLoading>
              <Style.NotFound>{dictionary.COMMON.NOT_FOUND}</Style.NotFound>
            </Style.ContainerLoading>
          )}
        </Style.ContainerCertificateMobileComponent>
      </Mobile>

      {!searchValue.length && (
        <Style.ButtonWrapper>
          {totalShowing < contents.length && (
            <Style.ShowMoreButton onClick={() => setCount(count + 1)}>
              {dictionary.MY_COURSES.VIEW_MORE}
            </Style.ShowMoreButton>
          )}
        </Style.ButtonWrapper>
      )}

      {totalPages > 1 && (
        <Pagination totalPages={totalPages} setPage={setPage} />
      )}
    </Style.Container>
  );
};

export default Certificates;
