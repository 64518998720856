import { createContext, useEffect, useState } from 'react';
import Category from '../models/category';
import { getCategories } from '../services/category';

interface Context {
  categories: Category[];
}

export const CategoriesContext = createContext<Context>({} as Context);

export const CategoriesProvider: React.FC = ({ children }) => {
  const [categories, setCategories] = useState<Category[]>([]);

  useEffect(() => {
    (async () => {
      const allCategories = await getCategories();
      setCategories(allCategories);
    })();
  }, []);

  return (
    <CategoriesContext.Provider value={{ categories }}>
      {children}
    </CategoriesContext.Provider>
  );
};
