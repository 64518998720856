import React, { useContext, useEffect, useState } from 'react';
import { AiTwotoneLike } from 'react-icons/ai';
import { toast } from 'react-toastify';
import DefaultButton from '../../../../../../components/Buttons/DefaultButton';
import { LanguageContext } from '../../../../../../contexts/LanguageContext';
import {
  likeTrailContent,
  dislikeTrailContent,
} from '../../../../../../services/trail-like';
import * as Style from './styles';

interface TrailContentLikeProps {
  trailId: string;
  userLiked: boolean;
  numberOfLikes: number;
}

const TrailContentLike: React.FC<TrailContentLikeProps> = ({
  trailId,
  userLiked,
  numberOfLikes,
}) => {
  const { dictionary } = useContext(LanguageContext);
  const [likes, setLikes] = useState(numberOfLikes);
  const [isLiked, setIsLiked] = useState(userLiked);

  useEffect(() => {
    setLikes(numberOfLikes);
    setIsLiked(userLiked);
  }, [numberOfLikes, userLiked]);

  const onLikeClick = async () => {
    try {
      if (isLiked) {
        await dislikeTrailContent(trailId);
        setLikes(likes - 1);
      } else {
        await likeTrailContent(trailId);
        setLikes(likes + 1);
      }
      setIsLiked(!isLiked);
    } catch (error) {
      toast.error(dictionary.ERROR.LIKE);
    }
  };

  return (
    <Style.Container>
      <DefaultButton
        type="button"
        onClick={onLikeClick}
        className="transparent"
      >
        <Style.LikeIcon isLiked={isLiked}>
          <AiTwotoneLike size={20} />
        </Style.LikeIcon>
        <Style.LikeQuantity>
          {likes}{' '}
          {likes === 1 ? dictionary.COMMON.LIKE : dictionary.COMMON.LIKES}
        </Style.LikeQuantity>
      </DefaultButton>
    </Style.Container>
  );
};

export default TrailContentLike;
