import { Dispatch, SetStateAction, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { CategoriesContext } from '../../../../../../contexts/CategoriesContext';
import { LanguageContext } from '../../../../../../contexts/LanguageContext';
import { ReactComponent as Ring } from '../../../../../../assets/svgs/category-ring.svg';
import * as Style from './styles';

interface ChannelsProps {
  setIsSidebarOpen: Dispatch<SetStateAction<boolean>>;
}

const Channels: React.FC<ChannelsProps> = ({ setIsSidebarOpen }) => {
  const { categories } = useContext(CategoriesContext);
  const { dictionary } = useContext(LanguageContext);

  const history = useHistory();

  const goToChannel = (id: string) => {
    setIsSidebarOpen(false);
    history.push(`/channels/${id}`);
  };

  const goToCourses = () => {
    setIsSidebarOpen(false);
    history.push(`/courses`);
  };

  return (
    <Style.Container>
      <Style.CategoriesTitle>
        {dictionary.COMMON.CHANNELS}
      </Style.CategoriesTitle>
      <Style.CategoriesNav>
        <Style.CategoriesList>
          {categories.map(category => (
            <Style.Category
              key={category.id}
              onClick={() => goToChannel(category.id)}
              color={category.info.color}
            >
              <Ring />
              {category.title}
            </Style.Category>
          ))}
          <Style.AllCoursesButton onClick={goToCourses}>
            {dictionary.COURSE.ALL_CONTENTS}
          </Style.AllCoursesButton>
        </Style.CategoriesList>
      </Style.CategoriesNav>
    </Style.Container>
  );
};

export default Channels;
