import styled from 'styled-components';

interface QuestionProps {
  isOpen: boolean;
}

export const Container = styled.div`
  display: flex;
  padding: 32px 0 0;
`;

export const IconContainer = styled.div`
  margin-right: 38px;

  svg {
    cursor: pointer;
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        path {
          fill: var(--blue);
        }
      }
    }
  }

  @media (max-width: 768px) {
    margin-right: 18px;
  }
`;

export const Content = styled.div`
  width: 100%;
  padding-bottom: 32px;
  border-bottom: 1px solid var(--neutralLight40);

  @media (max-width: 768px) {
    padding-bottom: 24px;
  }
`;

export const Question = styled.h3<QuestionProps>`
  color: var(--lightBlue);
  font-size: 2.4rem;
  font-weight: ${({ isOpen }) => (isOpen ? '700' : '400')};
  line-height: 22px;
  cursor: pointer;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: var(--blue);
      font-weight: 700;
    }
  }

  @media (max-width: 768px) {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 24px;
  }
`;

export const Answer = styled.p`
  color: var(--neutralLight40);
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 32px;
  margin-top: 16px;

  @media (max-width: 768px) {
    font-size: 1.2rem;
    line-height: 16px;
    letter-spacing: 0.4px;
    margin-top: 8px;
  }
`;
