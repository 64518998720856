import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1424px;

  margin: auto;

  position: relative;

  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;
