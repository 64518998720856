import httpClient from '../../http-client';

const getTrailContentReview = async (trailId: string) => {
  const response = (await httpClient.get(`/trail-user-reviews/${trailId}/user`))
    .data;

  return response;
};

const reviewTrailContent = async (trailId: string, stars: number) => {
  await httpClient.post(`/trail-user-reviews`, {
    trail_id: trailId,
    stars: stars,
  });
};

const updateTrailContentReview = async (trailId: string, stars: number) => {
  await httpClient.put(`/trail-user-reviews/${trailId}`, {
    stars: stars,
  });
};

export { getTrailContentReview, reviewTrailContent, updateTrailContentReview };
