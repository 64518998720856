import { Dispatch, SetStateAction, useContext } from 'react';
import { FiLogOut, FiTarget } from 'react-icons/fi';
import { AiOutlinePlayCircle } from 'react-icons/ai';
import { BiNotepad } from 'react-icons/bi';
import { GrBook } from 'react-icons/gr';
import { IoEarthOutline } from 'react-icons/io5';
import { LanguageContext } from '../../../../../../contexts/LanguageContext';
import LanguageSelector from '../../../../../Header/components/LanguageSelector';
import * as Style from './styles';
import UserContext from '../../../../../../contexts/UserContext';

import { ReactComponent as Medal } from '../../../../../../assets/svgs/medal.svg';
import { BsBookmark } from 'react-icons/bs';
import { isInWebview } from '../../../../../../helpers/reactNativeWebView';

interface ProfileProps {
  setIsSidebarOpen: Dispatch<SetStateAction<boolean>>;
}

const Profile: React.FC<ProfileProps> = ({ setIsSidebarOpen }) => {
  const { dictionary } = useContext(LanguageContext);
  const { user } = useContext(UserContext);
  const logout = () => {
    window.localStorage.removeItem('alpargatas-api-token');
    window.location.href = `${process.env.REACT_APP_REDIRECT_LOGOUT}`;
  };

  const renderFirstLetter = user.name.substring(0, 1);

  return (
    <Style.Container>
      <Style.OptionLinkProfile
        to="/profile/my-data"
        onClick={() => setIsSidebarOpen(false)}
      >
        <span>{renderFirstLetter}</span>
        <p>{user.name}</p>
      </Style.OptionLinkProfile>

      <Style.OptionLink
        to="/my-courses/ongoing"
        onClick={() => setIsSidebarOpen(false)}
      >
        <AiOutlinePlayCircle size={14} />
        {dictionary.MY_COURSES.ONGOING_COURSES}
      </Style.OptionLink>

      <Style.OptionLink
        to="/my-courses/annotations"
        onClick={() => setIsSidebarOpen(false)}
      >
        <BiNotepad size={14} />
        {dictionary.COMMON.ANNOTATIONS}
      </Style.OptionLink>

      <Style.OptionLink
        to="/my-courses/certificates"
        onClick={() => setIsSidebarOpen(false)}
      >
        <Medal />
        {dictionary.MY_COURSES.CERTIFICATES}
      </Style.OptionLink>

      <Style.OptionLink
        to="/my-courses/favorite"
        onClick={() => setIsSidebarOpen(false)}
      >
        <BsBookmark size={14} />
        {dictionary.MY_COURSES.FAVORITES}
      </Style.OptionLink>

      <Style.OptionLink
        to="/my-courses/recommended"
        onClick={() => setIsSidebarOpen(false)}
      >
        <FiTarget size={14} />
        {dictionary.MY_COURSES.RECOMMENDED}
      </Style.OptionLink>
      {isInWebview ? null : (
        <Style.OptionLinkMyCourses
          to="/my-courses/ongoing"
          onClick={() => setIsSidebarOpen(false)}
        >
          <GrBook size={16} />
          {dictionary.MY_COURSES.MY_COURSES}
        </Style.OptionLinkMyCourses>
      )}
      <Style.LanguageLink>
        <p>
          <IoEarthOutline size={16} />
          {dictionary.PROFILE_DROPDOWN.LANGUAGE}
        </p>
        <LanguageSelector />
      </Style.LanguageLink>

      <Style.LogoutLink onClick={logout}>
        <FiLogOut size={16} /> {dictionary.PROFILE_DROPDOWN.LOGOUT}
      </Style.LogoutLink>
    </Style.Container>
  );
};

export default Profile;
