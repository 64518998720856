import { useContext, useRef } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { LanguageContext } from '../../contexts/LanguageContext';
import SearchInput from '../Inputs/SearchInput';
import { Mobile, NotMobile } from '../Responsive';
import * as Style from './styles';

const SearchOverlay = () => {
  const { dictionary } = useContext(LanguageContext);
  const searchOverlayRef: any = useRef(null);

  return (
    <Style.Container id="search-overlay" ref={searchOverlayRef}>
      <Style.CloseButton
        id="close-search-button"
        onClick={() => history.back()}
      >
        <NotMobile>
          <AiOutlineClose size={20} color={'var(--purple)'} />
        </NotMobile>
        <Mobile>
          <AiOutlineClose size={20} color={'var(--white)'} />
        </Mobile>
      </Style.CloseButton>
      <Style.Body>
        <Style.Title>{dictionary.SEARCH.SEARCH}</Style.Title>
        <SearchInput />
      </Style.Body>
    </Style.Container>
  );
};

export default SearchOverlay;
