import styled from 'styled-components';

const TrailContentAnnotationContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-top: solid 2px var(--neutralLight40);
  margin: 5px 0px;
  padding: 5px 0px;
`;

const TrailContentAnnotationUpperContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TrailContentAnnotationTitle = styled.div`
  flex-grow: 1;
  font-weight: bold;
  font-size: 16px;
`;

const TrailContentAnnotationActionButtons = styled.div`
  display: flex;
  align-items: center;
`;

const TrailContentAnnotationButton = styled.button`
  background: var(--neutralLight40);
  cursor: pointer;
  color: var(--text);
  border: none;
  outline: none;
  border-radius: 5px;
  margin-left: 5px;
  padding: 5px;
`;

const TrailContentAnnotationLowerContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const TrailContentAnnotationDate = styled.div`
  font-size: 12px;
  color: var(--text);
  margin: 5px 0;
`;

const TrailContentAnnotationDescription = styled.div`
  font-size: 14px;
`;

export {
  TrailContentAnnotationContainer,
  TrailContentAnnotationUpperContent,
  TrailContentAnnotationTitle,
  TrailContentAnnotationActionButtons,
  TrailContentAnnotationButton,
  TrailContentAnnotationLowerContent,
  TrailContentAnnotationDate,
  TrailContentAnnotationDescription,
};
