import ContentLoader from 'react-content-loader';
import { clamp } from '../../helpers';
import { Mobile, NotMobile } from '../Responsive';
import * as Style from './styles';

const pagePadding = 32 + 16;
const loaderWidth = clamp(window.innerWidth - pagePadding * 2, 0, 350);

const CourseCardSkeleton = () => {
  return (
    <Style.Container>
      <NotMobile>
        <ContentLoader
          style={{ margin: '7px' }}
          width={300}
          height={350}
          backgroundColor="#0e0e41"
          foregroundColor="#131263"
        >
          <rect width="300" height="175" ry="5" />
          <rect rx="5" ry="5" y="173" width="5" height="175" />
          <rect rx="5" ry="5" y="345" width="300" height="5" />
          <rect rx="5" ry="5" x="295" y="173" width="5" height="175" />

          <rect rx="5" ry="5" x="10" y="190" width="280" height="35" />
          <rect rx="5" ry="5" x="10" y="245" width="280" height="35" />
          <rect rx="5" ry="5" x="10" y="300" width="280" height="35" />
        </ContentLoader>
      </NotMobile>
      <Mobile>
        <ContentLoader
          style={{ margin: '7px' }}
          width={loaderWidth}
          height={340}
          backgroundColor="#131263"
          foregroundColor="#1b1a82"
        >
          <rect width={loaderWidth} height="175" ry="5" />
          <rect rx="5" ry="5" y="173" width="5" height="175" />
          <rect rx="5" ry="5" y="345" width={loaderWidth - 40} height="5" />
          <rect
            rx="5"
            ry="5"
            x={loaderWidth - 5}
            y="173"
            width="5"
            height="175"
          />
          <rect
            rx="5"
            ry="5"
            x="10"
            y="190"
            width={loaderWidth - 20}
            height="35"
          />
          <rect
            rx="5"
            ry="5"
            x="10"
            y="245"
            width={loaderWidth - 20}
            height="35"
          />
          <rect
            rx="5"
            ry="5"
            x="10"
            y="300"
            width={loaderWidth - 20}
            height="35"
          />
        </ContentLoader>
      </Mobile>
    </Style.Container>
  );
};

export default CourseCardSkeleton;
