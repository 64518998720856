import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { BsChevronLeft } from 'react-icons/bs';
import { FiSearch } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/svgs/Expertise.svg';
import { LanguageContext } from '../../contexts/LanguageContext';
import { SearchOverlayContext } from '../../contexts/SearchOverlayContext';

import * as Style from './styles';

const HeaderWebView: React.FC = () => {
  const { setIsOpen } = useContext(SearchOverlayContext);
  const { dictionary } = useContext(LanguageContext);

  const history = useHistory();
  const location = useLocation();

  const titles = () => {
    switch (location.pathname) {
      case '/my-courses/favorite':
        return <h1>{dictionary.MY_COURSES.FAVORITE_CONTENTS}</h1>;
      case '/my-courses/ongoing':
        return <h1>{dictionary.MY_COURSES.CONTENT_IN_PROGRESS}</h1>;
      case '/my-courses/recommended':
        return <h1>{dictionary.MY_COURSES.RECOMMENDED_CONTENTS}</h1>;
      case '/my-courses/certificates':
        return <h1>{dictionary.MY_COURSES.CERTIFICATES}</h1>;
      case '/my-courses/annotations':
        return <h1>{dictionary.MY_COURSES.NOTES}</h1>;
    }
  };

  return (
    <>
      <Style.HeaderWebViewContainer>
        <button onClick={() => history.push('/home')}>
          <BsChevronLeft size={12} color={'var(--darkPurple)'} />
        </button>
        {titles()}
        <Style.SearchIcon id="search-button" onClick={() => setIsOpen(true)}>
          <FiSearch size={20} color="white" />
        </Style.SearchIcon>
      </Style.HeaderWebViewContainer>
    </>
  );
};

export default HeaderWebView;
