import styled from 'styled-components';

export const Container = styled.button`
  cursor: pointer;
  border: none;
  border-radius: 50%;
  background: var(--secondary-color);
  position: absolute;
  top: 50%;
  left: 80px;
  transform: translate(-50%, -50%);
  z-index: 5;

  svg {
    path,
    polyline {
      stroke: #fff;
    }
  }

  &.slick-disabled {
    display: none;
    svg polyline {
      stroke: var(--neutralDark500);
    }
  }
`;
