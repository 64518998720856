const secondsToTimeText = (seconds: number, hasApproachSign = true): string => {
  seconds = seconds || 0;
  const minutes = Math.floor(seconds / 60);

  if (minutes >= 60) {
    const hours = Math.floor(minutes / 60);

    return hasApproachSign ? `~ ${hours} h` : `${hours} h`;
  } else {
    return hasApproachSign ? `~ ${minutes} min` : `${minutes} min`;
  }
};

export { secondsToTimeText };
