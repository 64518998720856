import * as Style from './styles';

const Term = () => {
  return (
    <Style.Container>
      <h1>TERMOS E CONDIÇÕES DE USO DA ALUFLIX </h1>

      <a
        href="https://alpargatas-front-prod.s3.amazonaws.com/TERMOS+DE+USO+-+ALUFLIX_eng.pdf"
        target="_blank"
        rel="noreferrer"
      >
        English version
      </a>
      <p>Olá, seja bem-vindo a plataforma ALUFLIX.</p>
      <p>
        A seguir apresentamos a você (“Usuário”) os Termos e Condições de Uso
        (“Termos”), documento que especifica as principais regras a serem
        observadas quando do acesso e utilização da ALUFLIX.
      </p>
      <p>
        A ALUFLIX é de propriedade da Alpargatas S.A., com sede na Av. das
        Nações Unidas, nº 14.261, Ala A – 10º andar, bairro Vila Gertrudes, São
        Paulo-SP, CEP 04.794-000, Brasil, inscrita no CNPJ/MF sob o nº
        61.079.117/0001-05 (“Alpargatas”) e operado pela Samba Mobile Multimídia
        SA, inscrita no CNPJ/MF sob o nº 06.165.667/0001-20
      </p>
      <p>
        A utilização da ALUFLIX faz parte da Universidade Corporativa da
        Alpargatas, denominada “Alpa Learning Universe - ALU” (“Projeto”). A
        ALUFLIX tem como objetivo a educação corporativa oferecido em plataforma
        digital online, na qual os Usuários poderão acessar informações sobre
        desenvolvimento pessoal e profissional. A seguir apresentamos as
        principais regras que devem ser observadas pelos Usuários. Desse modo, a
        aceitação livre, expressa e informada, sem quaisquer reservas, com
        relação aos itens destes Termos, dar-se-á por meio do clique no botão
        “Aceito os Termos”. Caso o Usuário não concorde com quaisquer das
        disposições desses Termos, ou com eventuais alterações, deverá se abster
        de utilizar a ALUFLIX.
      </p>
      <ul>
        <li>
          <h3>1. Informações Gerais</h3>
          <p>
            1.1. A ALUFLIX é dedicada a promover informação aos Usuários. Para
            acessar a ALUFLIX de forma segura, é de inteira responsabilidade dos
            Usuários disporem de serviço de conexão à internet, com antivírus e
            firewall habilitados.
          </p>
          <p>
            1.2. A ALUFLIX e suas funcionalidades são apresentados aos Usuários
            na maneira como estão disponíveis, com acesso restrito mediante a
            digitação de senha que reconhece e autentica a sua identidade
            podendo passar por constantes aprimoramentos e atualizações.
          </p>
          <p>
            1.3. A Operadora envidará seus melhores esforços para a
            disponibilidade contínua e permanente da ALUFLIX. No entanto, pode
            ocorrer, eventualmente, alguma indisponibilidade temporária
            decorrente de manutenção necessária ou mesmo gerada por motivo de
            força maior, como desastres naturais, falhas ou colapsos nos
            sistemas centrais de comunicação e acesso à internet ou fatos de
            terceiro que fogem de sua esfera de vigilância e responsabilidade.
          </p>
          <p>
            1.4. Eventuais procedimentos de manutenção serão informados por meio
            dos canais oficiais de comunicação da ALUFLIX (a exemplo, mas não se
            limitando, perfis oficiais nas mídias sociais, telefone de
            atendimento ou e-mail), caso seja necessário que este fique
            indisponível por longos períodos.
          </p>
          <p>
            1.5. Dentro da ALUFLIX o Usuário terá acesso a diversos conteúdos,
            todos estarão disponíveis em tempo integral sendo que o Usuário
            poderá acessá-los a qualquer momento, ficando a Alpargatas isenta de
            qualquer obrigatoriedade legal caso o Usuário venha acessar tais
            materiais ou qualquer outra funcionalidade disponível na ALUFLIX
            fora do seu horário trabalho.
          </p>
          <p>
            1.6. O Usuário se compromete a, durante a utilização da ALUFLIX,
            respeitar as boas práticas, incluindo, mas não se limitando a
            cuidados com a linguagem e postura ao interagir com os demais
            Usuários.
          </p>
        </li>
        <li>
          <h3>2. Funcionalidades da ALUFLIX</h3>
          <p>
            2.1. A ALUFLIX oferece, através do streaming, vídeos voltados para o
            desenvolvimento pessoal e profissional dos Usuários, como webseries
            para que os Usuários possam assistir os conteúdos de desenvolvimento
            de forma descomplicada e rápida. Por se tratar de um canal de
            conveniência, as funcionalidades da ALUFLIX poderão ser ampliadas,
            reduzidas, alteradas ou extintas pela Alpargatas a qualquer tempo.
          </p>
        </li>
        <li>
          <h3>3. Das responsabilidades e obrigações dos Usuários</h3>
          <p>3.1. Os Usuários são responsáveis e se obrigam a:</p>
          <ol>
            <li>
              realizar navegação ética na ALUFLIX, adequada com os propósitos
              aqui estabelecidos, sempre respeitando as condições que regem a
              utilização DA ALUFLIX e sua finalidade, sob pena de suportarem os
              ônus dos atos praticados e terem a exclusão da conta de acesso da
              ALUFLIX;
            </li>
            <li>
              seguir todas as diretrizes estabelecidas pela ALUFLIX para a
              correta execução das funcionalidades deste, incluindo orientações
              quanto ao manejo do dispositivo utilizado e instruções
              necessárias;
            </li>
            <li>
              respeitar todos os direitos de propriedade intelectual de
              titularidade da Alpargatas, tal qual todos os direitos referentes
              a terceiros que porventura estejam, ou estiveram, de alguma forma,
              disponíveis na ALUFLIX;
            </li>
            <li>
              manter o sigilo dos dados de sua conta de acesso em relação a
              terceiros, não divulgando ou compartilhando sua senha a quem quer
              que seja, sob pena de suportarem os ônus dos atos praticados.
            </li>
          </ol>
          <p>
            3.2. Caso o Usuário suspeite que a confidencialidade de sua senha
            tenha sido quebrada, este deverá proceder sua troca o mais rápido
            possível.
          </p>
          <p>
            3.3. Não é permitido o acesso às áreas de programação da ALUFLIX,
            bem como de dados ou qualquer outro conjunto de informações que faça
            parte da atividade de desenvolvimento. Também não é autorizado aos
            Usuários realizar ou permitir engenharia reversa, nem traduzir,
            decompilar, copiar, modificar, reproduzir, alugar, sublicenciar,
            publicar, divulgar, transmitir, emprestar, distribuir ou, de outra
            maneira, dispor das ferramentas de consulta da ALUFLIX e de suas
            funcionalidades.
          </p>
          <p>
            3.4. Na incidência de danos a ALUFLIX ou a terceiros, o responsável
            se compromete a arcar com todas as obrigações de indenizar o sujeito
            lesado.
          </p>
          <p>
            3.5. Os Usuários se obrigam a ressarcir a Alpargatas de todas as
            condenações e prejuízos que sofrer cuja origem for de atos
            praticados por meio de seu acesso, assumindo o polo passivo de ação
            judicial ou procedimento administrativo e requerendo a exclusão da
            Alpargatas, inclusive, devendo arcar totalmente com as despesas e
            custas processuais atinentes, deixando-a livre de prejuízos e ônus.
          </p>
        </li>
        <li>
          <h3>4. Responsabilidades da Alpargatas</h3>
          <p>
            4.1. A Alpargatas não medirá esforços para oferecer, na ALUFLIX, uma
            navegabilidade segura, prática e confortável. Contudo, a Alpargatas
            não se responsabilizará por eventuais danos causados aos Usuários
            e/ou ao equipamento, decorrentes de ataques de vírus e/ou invasões,
            havidos durante o uso, uma vez não responsável pela segurança das
            conexões.
          </p>
          <p>
            4.2. Como informado anteriormente, se o Usuário não concordar com
            quaisquer das disposições desses Termos, ou com eventuais
            alterações, o Usuário deverá se abster de navegar na ALUFLIX, de
            modo que, no momento do aceite aos presentes Termos, o Usuário
            declara estar ciente de que será considerado o único e exclusivo
            responsável pelo uso que fizer da ALUFLIX.
          </p>
          <p>
            4.3. A ALUFLIX está sujeito a constantes aprimoramentos e
            atualizações, não ficando a Alpargatas obrigada a manter determinada
            estrutura, layout, funcionalidades e/ou ferramentas, se não por sua
            própria conveniência, não lhe cabendo indenizar os Usuários em caso
            de eventuais alterações.
          </p>
          <p>4.4. A ALUFLIX não se responsabiliza por/pela(o):</p>
          <ol>
            <li>
              eventuais indisponibilidades, erros ou falhas de acesso o ALUFLIX,
              bem como por qualquer forma fraudulenta de uso que o Usuário possa
              ter atribuído a ALUFLIX;
            </li>
            <li>
              quaisquer danos e prejuízos eventualmente causados por terceiros
              durante a prestação de serviços por parte da Alpargatas, tais
              quais, mas não se limitando a, transportadoras e administradoras
              de cartão de crédito, eximindo-se a Alpargatas de qualquer
              responsabilidade relacionada a tais fatos;
            </li>
            <li>
              erros ou inconsistências na transmissão de dados ou pela qualidade
              ou disponibilidade do sinal de Internet, que provoquem falhas no
              normal funcionamento da ALUFLIX.
            </li>
          </ol>
        </li>
        <li>
          <h3>5. Direitos Autorais e Propriedade Intelectual da ALUFLIX</h3>
          <p>
            5.1. A Alpargatas é titular da marca Alpargatas e ALU em todo
            território nacional e internacional, e salvo quando expressamente
            autorizado por lei ou pela Alpargatas, o Usuário não poderá
            reproduzir, transmitir, licenciar, vender, executar publicamente,
            distribuir, adaptar, traduzir, modificar, agrupar, fundir,
            compartilhar ou disponibilizar a qualquer pessoa, ou criar trabalhos
            derivados, com qualquer um dos materiais ou conteúdos disponíveis na
            ALUFLIX, ou usá-los para fins comerciais, incluindo mas não se
            limitando a marca, nome empresarial ou nome de domínio. A proteção
            dos direitos de autor do mencionado conteúdo estende-se a todas as
            reproduções ou cópias, obtidas a partir da ALUFLIX.
          </p>
          <p>
            5.2. Ao acessar a ALUFLIX, o Usuário se declara ciente de que deve
            respeitar todos os direitos de propriedade intelectual, incluindo
            direitos autorais e aqueles decorrentes da proteção de marcas,
            patentes e/ou desenhos industriais, depositados ou registrados em
            nome da Alpargatas, bem como todos os direitos referentes a
            terceiros que porventura estejam, ou estiveram, de alguma forma,
            publicados na ALUFLIX, podendo ser responsabilizado civil e/ou
            criminalmente por quaisquer usos indevidos deste conteúdo.
          </p>
          <p>
            5.3. O Usuário reconhece e concorda que ao acessar a ALUFLIX não lhe
            é conferido o direito ao uso dos nomes, títulos, textos, figuras,
            gráficos, frases, marcas, patentes, obras, imagens, dados e
            informações, e demais materiais que estejam ou que estiveram
            disponíveis na ALUFLIX, sendo vedada sua reprodução, cópia,
            distribuição ou utilização para finalidades comerciais,
            publicitárias ou quaisquer outras que contrariem a realidade para a
            qual foi concebida, salvo mediante prévia autorização por escrito da
            Alpargatas ou em caso de uso exclusivamente pessoal, que não importe
            aquisição de quaisquer direitos sobre os materiais.
          </p>
        </li>
        <li>
          <h3>6. Modificações deste Termo</h3>
          <p>
            6.1. Os presentes Termos podem ser alterados a qualquer momento,
            estando sujeitos a constante melhoria e aprimoramento. Assim, a
            Alpargatas se reserva o direito de modificar a qualquer momento, de
            forma unilateral, o presente documento e sua Política de
            Privacidade, entrando em vigor as novas versões quando de sua
            publicação na ALUFLIX.
          </p>
        </li>
        <li>
          <h3>7. Disposições Gerais</h3>
          <p>
            7.1. A tolerância com relação a eventual descumprimento de quaisquer
            das cláusulas e condições do presente instrumento não constituirá
            novação das obrigações aqui estipuladas e tampouco impedirá ou
            inibirá a exigibilidade das mesmas a qualquer tempo.
          </p>
          <p>
            7.2. Qualquer cláusula ou condição deste instrumento que, por
            qualquer razão, venha a ser reputada nula ou ineficaz por qualquer
            juízo ou tribunal, não afetará a validade das demais disposições
            destes Termos, as quais permanecerão plenamente válidas e
            vinculantes, gerando efeitos em sua máxima extensão.
          </p>
          <p>
            7.3. Alpargatas utilizará como principal canal de comunicação com
            seus Usuários o e-mail informado quando da realização do cadastro,
            mas se reserva o direito de utilizar outros meios de contato
            fornecidos durante o cadastro.
          </p>
        </li>
        <li>
          <h3>8. Lei aplicável</h3>
          <p>
            Os Termos aqui descritos são interpretados segundo a legislação
            brasileira, no idioma português, sendo eleito o foro do domicílio do
            Usuário para dirimir qualquer litígio ou controvérsia envolvendo o
            presente documento, renunciando as partes a qualquer outro, por mais
            privilegiado que seja.
          </p>
        </li>
      </ul>

      <p>Atualização: 10 de Junho de 2021.</p>
      <p>
        <strong>Alpargatas S.A.</strong>
      </p>
    </Style.Container>
  );
};

export default Term;
