import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getCategory } from '../../services/category';
import AvailableCourses from './components/AvailableCourses';
import Breadcrumb from '../../components/Breadcrumb';
import { Container } from './styles';
import Category from '../../models/category';
import { LanguageContext } from '../../contexts/LanguageContext';

interface Crumbs {
  name: string;
  path: string;
}

interface ChannelParams {
  channelId: string;
}

const Channel: React.FC = () => {
  const { channelId } = useParams() as ChannelParams;
  const { dictionary } = useContext(LanguageContext);
  const [category, setCategory] = useState({} as Category);
  const [crumbs, setCrumbs] = useState<Crumbs[]>([]);
  const defaultCrumbs = [
    { name: 'HOME', path: '/home' },
    { name: dictionary.COMMON.CHANNELS, path: '/courses' },
  ];

  useEffect(() => {
    (async () => {
      const data = await getCategory(channelId);
      setCategory(data);
      setCrumbs([...defaultCrumbs, { name: data.title, path: '' }]);
    })();
  }, [channelId]);

  return (
    <Container>
      <Breadcrumb crumbs={crumbs} />
      <AvailableCourses category={category} />
    </Container>
  );
};

export default Channel;
