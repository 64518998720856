import styled from 'styled-components';

interface CrumbProps {
  isDisabled: boolean;
}

export const Container = styled.div`
  margin: auto;
  padding: 66px 0 26px;
  max-width: 1424px;
  width: 100%;
  border-bottom: 0.75px solid var(--gray25);

  @media (max-width: 768px) {
    padding: 32px 0;
  }
`;

export const Breadcrumb = styled.nav``;

export const BreadcrumbList = styled.ul`
  display: flex;
  align-items: center;
`;

export const Crumb = styled.li<CrumbProps>`
  position: relative;
  font-size: 1.4rem;
  text-transform: uppercase;

  a {
    font-size: 1.4rem;
    text-decoration: none;
    color: ${({ isDisabled }) => (isDisabled ? '#ffffff' : 'var(--lightBlue)')};
    font-weight: ${({ isDisabled }) => (isDisabled ? '400' : '500')};
    pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
    position: relative;

    &:after {
      content: '';
      width: 0;
      opacity: 0;
      height: 2px;
      bottom: -6px;
      left: 0;
      position: absolute;
      transition: all 0.3s ease-in-out;
      background: var(--purple);
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        &:after {
          width: 100%;
          opacity: 1;
        }
      }
    }
  }

  & + & {
    padding-left: 20px;
    margin-left: 20px;

    :after {
      content: '';
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M0.659553 0.722053C0.778541 0.603066 0.971459 0.603066 1.09045 0.722053L5.15295 4.78455C5.27193 4.90354 5.27193 5.09646 5.15295 5.21545L1.09045 9.27795C0.971459 9.39693 0.778541 9.39693 0.659553 9.27795C0.540566 9.15896 0.540566 8.96604 0.659553 8.84705L4.50661 5L0.659553 1.15295C0.540566 1.03396 0.540566 0.841041 0.659553 0.722053Z" fill="%2331348B"/%3E%3C/svg%3E%0A');
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 7px;
      height: 10px;
      background-repeat: no-repeat;
    }
  }

  @media (max-width: 768px) {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;
