import { useContext } from 'react';
import { useHistory } from 'react-router';
import Breadcrumb from '../../components/Breadcrumb';
import CourseCard from '../../components/CourseCard';
import Loading from '../../components/Loading';
import Pagination from '../../components/Pagination';
import { ReactComponent as MagnifierIcon } from '../../assets/svgs/search-empty.svg';
import { LanguageContext } from '../../contexts/LanguageContext';
import { SearchContext } from '../../contexts/SearchContext';
import * as Style from './styles';

const Search = () => {
  const { dictionary } = useContext(LanguageContext);
  const {
    isSearching,
    searchResults,
    searchParam,
    resultsCount,
    totalPages,
    setPage,
  } = useContext(SearchContext);
  const history = useHistory();

  const crumbs = [
    { name: 'HOME', path: '/' },
    { name: dictionary.SEARCH.SEARCH, path: '' },
  ];

  const goToCourses = () => {
    history.push('/courses');
  };

  return (
    <Style.Container>
      <Breadcrumb crumbs={crumbs} />
      <Style.ResultContainer>
        {!isSearching ? (
          <>
            <Style.Title>
              {dictionary.SEARCH.YOUR_SEARCH} &quot;<span>{searchParam}</span>
              &quot;
            </Style.Title>
            <Style.Subtitle>
              {resultsCount ? (
                <>
                  {dictionary.SEARCH.FOUND} <span>{resultsCount}</span>{' '}
                  {dictionary.SEARCH.RESULTS}.
                </>
              ) : (
                <>
                  {dictionary.SEARCH.NO_RESULTS}.
                  <span className="verify-message">
                    {dictionary.SEARCH.CHECK_SPELLING}.
                  </span>
                </>
              )}
            </Style.Subtitle>
            {resultsCount ? (
              <Style.Results>
                {searchResults.map(result => (
                  <CourseCard key={result.id} content={result} />
                ))}
              </Style.Results>
            ) : (
              <Style.NotFound>
                <MagnifierIcon />
                <div onClick={goToCourses}>
                  {dictionary.SEARCH.GO_TO_COURSES}
                </div>
              </Style.NotFound>
            )}
          </>
        ) : (
          <Loading size={200} />
        )}
        {totalPages > 1 && (
          <Pagination totalPages={totalPages} setPage={setPage} />
        )}
      </Style.ResultContainer>
    </Style.Container>
  );
};

export default Search;
