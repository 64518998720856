import styled from 'styled-components';
import StarsBackground from '../../assets/svgs/new-stars.svg';
import Background from '../../assets/svgs/backgroundImage.svg';
export const Container = styled.div`
  padding: 0 32px;

  display: flex;
  flex-direction: column;
  background: var(--blueGradient);
  background-image: url(${Background});
  position: relative;

  &:before {
    content: '';
    background-image: url(${StarsBackground});
    background-position: center;
    background-size: contain;
    background-repeat: repeat;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  @media screen and (max-width: 600px) {
    padding: 0 16px;
  }
`;
