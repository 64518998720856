import { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import * as Style from './styles';

interface BottomTabProps {
  tab: {
    name: string;
    value: string;
    path: string;
    icon: string;
  };
  setIsSidebarOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedTab: Dispatch<SetStateAction<string>>;
}

const BottomTab: React.FC<BottomTabProps> = ({
  tab,
  setIsSidebarOpen,
  setSelectedTab,
}) => {
  const history = useHistory();

  const onTabClick = () => {
    if (tab.path) {
      history.push(`${tab.path}`);
    } else {
      setIsSidebarOpen(true);
      setSelectedTab(tab.value);
    }
  };
  return (
    <Style.Container id={tab.value} onClick={onTabClick}>
      <Style.Icon>
        <img src={tab.icon} alt="Icon" />
      </Style.Icon>
      <Style.Title>{tab.name}</Style.Title>
    </Style.Container>
  );
};

export default BottomTab;
