import React from 'react';
import { useHistory } from 'react-router';
import { BiCheck } from 'react-icons/bi';
import { FiPlay } from 'react-icons/fi';
import Content from '../../../../../../../../models/content';
import { secondsToTimeText } from '../../../../../../../../helpers/time';
import * as Style from './style';

interface TrailCourseContentProps {
  content: Content;
  isSelected: boolean;
}

const TrailCourseContent: React.FC<TrailCourseContentProps> = ({
  content,
  isSelected,
}) => {
  const history = useHistory();

  const changeContent = () => {
    history.push(
      `/trails/${content.trailId}/modules/${content.courseId}/contents/${content.id}`,
    );
  };

  return (
    <Style.Container isSelected={isSelected} onClick={changeContent}>
      <Style.FluxIndicatorContainer>
        {!content.isFirstOfCourse && <Style.FluxBar />}
        <Style.FluxIndicator
          isSelected={isSelected}
          hasFinished={content.alreadyFinished}
        >
          {content.alreadyFinished ? (
            <BiCheck size={30} />
          ) : (
            <FiPlay size={24} />
          )}
        </Style.FluxIndicator>
        {!content.isLastOfCourse && <Style.FluxBar />}
      </Style.FluxIndicatorContainer>
      <Style.Title isSelected={isSelected}>{content.title}</Style.Title>
      <Style.Duration>{secondsToTimeText(content.duration)}</Style.Duration>
    </Style.Container>
  );
};

export default TrailCourseContent;
