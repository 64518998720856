import ContentCard from '../models/content-card';
import ContentCardFromResponse from '../models/from-api/content-card';

const convertContentCard = (content: ContentCardFromResponse): ContentCard => {
  return {
    id: content.type === 'TRAIL' ? content.trail_id : content.content_id,
    title: content.title,
    description: content.description,
    thumbnail: content.thumbnail,
    banner: content.info?.banner,
    duration: content.duration,
    addedToList: content.book_mark,
    categoryName:
      content.categories && content.categories.length
        ? content.categories.map((cat: any) => cat.title).join(', ')
        : '',
    categoryId:
      content.categories && content.categories.length
        ? content.categories[0].category_id
        : '',
    authorName:
      content.authors && content.authors.length
        ? content.authors.map((author: any) => author.name).join(', ')
        : '',
    numberOfLikes: (content.likes && content.likes.count) || 0,
    isNew:
      new Date(content.created_at) &&
      (new Date().getTime() - new Date(content.created_at).getTime()) /
        (1000 * 3600 * 24) <
        10,
    type: content.type,
  };
};

export default convertContentCard;
