import styled from 'styled-components';

export const Container = styled.section`
  padding: 32px 0 32px;
  max-width: 1424px;
  margin: auto;
  position: relative;

  @media (max-width: 768px) {
    padding: 24px 0;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;

  @media (max-width: 768px) {
    padding: 0 16px;
    font-size: 1.6rem;
    line-height: 24px;
  }
`;

export const Title = styled.h3`
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 32px;
  color: #ffffff;

  @media (max-width: 768px) {
    font-size: 1.6rem;
    line-height: 24px;
  }
`;

export const ViewMoreButton = styled.div`
  padding-bottom: 8px;
  color: var(--lightBlue);
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 18px;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  border: none;
  position: relative;

  &:after {
    content: '';
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    position: absolute;
    transition: all 0.3s ease-in-out;
    background: var(--blue);
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: #ffffff;
      &:after {
        width: 10%;
        background: #ffffff;
      }
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 2px;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 16px;
    border-width: 1px;
  }
`;

export const CardList = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(277px, 1fr));

  @media (max-width: 768px) {
    display: flex;
    justify-content: flex-start;
    overflow-x: auto;
    flex-wrap: nowrap;
    gap: 8px;
    padding-bottom: 16px;
    padding-left: 16px;

    & > div:last-child {
      margin-right: 16px;
    }
  }
`;

export const NotFound = styled.p`
  font-weight: 500;
  font-size: 1.8rem;
  color: var(--neutralLight40);
`;
