import styled from 'styled-components';

export const TrailContentAnnotationsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AnnotationForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & *:not(:first-child) {
    margin-top: 10px;
  }
`;

export const AnnotationFormLabel = styled.label`
  font-size: 16px;
  color: var(--neutralLight40);
`;

export const AnnotationFormTitle = styled.input`
  font-size: 1.6rem;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  border: solid 1px #7a869a;
`;

export const AnnotationFormSubmit = styled.button`
  font-size: 1.6rem;
  background-color: var(--purple);
  color: white;
  outline: none;
  border: none;
  color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
`;

export const AnnotationList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AnnotationFormDescription = styled.textarea`
  font-size: 1.6rem;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  border: solid 1px #7a869a;
`;
