import ReactPaginate from 'react-paginate';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import * as Style from './styles';

interface PaginationProps {
  totalPages: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

interface PaginationData {
  selected: number;
}

const Pagination: React.FC<PaginationProps> = ({ totalPages, setPage }) => {
  const handlePageClick = (data: PaginationData) => {
    setPage(data.selected + 1);
  };

  return (
    <Style.Container>
      <ReactPaginate
        previousLabel={<AiOutlineLeft />}
        nextLabel={<AiOutlineRight />}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={totalPages}
        marginPagesDisplayed={1}
        pageRangeDisplayed={1}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        activeClassName={'active'}
      />
    </Style.Container>
  );
};

export default Pagination;
