import styled from 'styled-components';

interface ContainerProps {
  isSidebarOpen: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 326px;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  background: var(--blueGradient);
  right: ${({ isSidebarOpen }) => (isSidebarOpen ? '0' : '-100%')};
  top: 0;
  position: fixed;
  transition: all 0.2s linear;
  padding: 32px;
  overflow: auto;
`;

export const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  display: block;
  margin-left: auto;
  margin-bottom: 40px;
`;
