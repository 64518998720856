import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Meteor } from '../../assets/svgs/meteor.svg';
import Logo from '../../assets/logo-aluflix-negative.png';

import { CategoriesContext } from '../../contexts/CategoriesContext';
import { LanguageContext } from '../../contexts/LanguageContext';

import { NotMobile } from '../Responsive';
import * as Style from './styles';

const Footer: React.FC = () => {
  const { categories } = useContext(CategoriesContext);
  const { dictionary } = useContext(LanguageContext);

  return (
    <Style.Container>
      <img src={Logo} alt="Logo" />
      <Style.LinkList>
        <Style.LinkItem isBold={true}>
          <Link to={`/courses`}>{dictionary.COMMON.CHANNELS}</Link>
        </Style.LinkItem>
        {categories.map(category => (
          <Style.LinkItem
            isBold={false}
            key={category.id}
            className="mobile-text-style"
          >
            <Link to={`/channels/${category.id}`}>{category.title}</Link>
          </Style.LinkItem>
        ))}
      </Style.LinkList>
      <Style.LinkList>
        <Style.LinkItem isBold={true} className="mobile-text-style">
          <Link to="/faq">FAQ</Link>
        </Style.LinkItem>
        <Style.LinkItem isBold={true}>
          <Link to="/contact">{dictionary.CONTACT.CONTACT_US}</Link>
        </Style.LinkItem>
      </Style.LinkList>
      <Style.CopyrightText>
        © ALUFLIX, 2021, {dictionary.FOOTER.COPYRIGHT}
      </Style.CopyrightText>
    </Style.Container>
  );
};

export default Footer;
