import React, { useContext } from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import { LanguageContext } from '../../contexts/LanguageContext';
import AvailableCourses from './components/AvailableCourses';
import * as Style from './styles';

const Courses: React.FC = () => {
  const { dictionary } = useContext(LanguageContext);
  const crumbs = [
    { name: 'HOME', path: '/home' },
    { name: dictionary.COMMON.CHANNELS, path: '' },
  ];

  return (
    <Style.Container>
      <Breadcrumb crumbs={crumbs} />
      <AvailableCourses />
    </Style.Container>
  );
};

export default Courses;
