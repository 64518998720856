import Content from '../models/content';
import ContentFromResponseCopy from '../models/from-api/content-copy';
import ContentUserFromResponse from '../models/from-api/content-user';
import CourseFromResponse from '../models/from-api/course';
import TrailFromResponse from '../models/from-api/trail';
import convertExtraMaterial from './convert-extra-material';

const convertContentCopy = (
  content: ContentFromResponseCopy,
  contentUser?: ContentUserFromResponse,
  course?: CourseFromResponse,
  trail?: TrailFromResponse,
): Content => {
  return {
    id: content.content_id,
    trailId: trail && trail.trail_id ? trail.trail_id : '',
    courseId: course && course.course_id ? course.course_id : '',
    title: content.title,
    addedToList: content.book_mark,
    authorName:
      content.authors && content.authors.length
        ? content.authors.map(author => author.name).join(', ')
        : '',
    categoryName:
      content.categories && content.categories.length
        ? content.categories.map(cat => cat.title).join(', ')
        : '',
    categoryId:
      content.categories && content.categories.length
        ? content.categories[0].category_id
        : '',
    contentUrl: content.reference,
    description: content.description,
    duration: content.duration,
    extraMaterials:
      content.extra_materials && content.extra_materials.length
        ? content.extra_materials.map(convertExtraMaterial)
        : [],
    numberOfLikes: (content.likes && content.likes.count) || 0,
    position: content.position,
    thumbnail: content.thumbnail,
    banner: content.info.banner,
    userLiked: (content.likes && content.likes.user_like) || false,
    alreadyStarted: contentUser ? !!contentUser.start_at : false,
    alreadyFinished: contentUser ? !!contentUser.finish_at : false,
    isNew:
      new Date(content.created_at) &&
      (new Date().getTime() - new Date(content.created_at).getTime()) /
        (1000 * 3600 * 24) <
        10,
    isFirstOfCourse:
      course && course.contents && course.contents.length
        ? course.contents[0].content_id === content.content_id
        : false,
    isLastOfCourse:
      course && course.contents && course.contents.length
        ? course.contents[course.contents.length - 1].content_id ===
          content.content_id
        : false,
    contentView: contentUser && contentUser.content_view,
    stars:
      contentUser &&
      contentUser.review &&
      Object.keys(contentUser.review).length
        ? contentUser.review.stars
        : 0,
    scorm_version: content.info.scorm_version,
    type: content.type,
  };
};

export default convertContentCopy;
