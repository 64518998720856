import ContentLoader from 'react-content-loader';

const CourseCardSkeleton = () => {
  return (
    <ContentLoader
      style={{ width: '100%', height: '60px', marginBottom: '30px' }}
      backgroundColor="#131263"
      foregroundColor="#1b1a82"
    >
      <rect style={{ width: '100%', height: '60px' }} ry="5" />
    </ContentLoader>
  );
};

export default CourseCardSkeleton;
