import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Routes from './routes';

import Header from './components/Header';
import Footer from './components/Footer';
import { Container } from './components/Container';
import { Mobile, NotMobile } from './components/Responsive';

import { login as loginService } from './services/login';
import { getActualUser } from './services/user';

import MobileNavigation from './components/MobileNavigation';
import TermModal from './components/TermModal';
import { LanguageContext } from './contexts/LanguageContext';
import { CategoriesProvider } from './contexts/CategoriesContext';
import { SearchProvider } from './contexts/SearchContext';
import { SearchOverlayProvider } from './contexts/SearchOverlayContext';
import User from './models/user';
import UserContext from './contexts/UserContext';
import 'react-toastify/dist/ReactToastify.css';
import {
  exposeToReactNativeWebView,
  isInWebview,
  isWebFrontend,
} from './helpers/reactNativeWebView';
import HeaderWebView from './components/HeaderWebView';
import useIsMainWindow from './hooks/useIsMainWindow';
import StaticAlert from './components/StaticAlert';

const App = () => {
  const history = useHistory();
  const location = useLocation();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [user, setUser] = useState<User>({} as User);
  const [userHasAccepted, setUserHasAccepted] = useState(false);
  const isMain = useIsMainWindow();
  const { dictionary } = useContext(LanguageContext);

  useEffect(() => {
    exposeToReactNativeWebView(history.push, 'historyPush');
    exposeToReactNativeWebView(history.listen, 'historyListen');
    exposeToReactNativeWebView(history.replace, 'historyReplace');
    exposeToReactNativeWebView(history.goBack, 'historyGoBack');
  }, [history]);

  const login = useCallback(async () => {
    if (!window.localStorage.getItem('alpargatas-api-token')) {
      await tryLogin();
    } else {
      try {
        const userData = await getActualUser();
        setUser(userData);
        setUserHasAccepted(userData.accept_conditions);
        changeToHome();
        setIsAuthorized(true);
      } catch (error) {
        window.localStorage.removeItem('alpargatas-api-token');
        await tryLogin();
      }
    }
  }, [history]);

  const tryLogin = async () => {
    try {
      await loginService();
      const userData = await getActualUser();
      setUser(userData);
      setUserHasAccepted(userData.accept_conditions);
      changeToHome();
      setIsAuthorized(true);
    } catch (error) {
      window.location.href = `${process.env.REACT_APP_REDIRECT_LOGIN}`;
    }
  };

  const changeToHome = () => {
    if (
      location.pathname === '' ||
      location.pathname === '/' ||
      location.pathname === '/login' ||
      location.pathname === '/home'
    ) {
      history.push('/home');
    }
  };

  useEffect(() => {
    login();
  }, [login]);

  if (isAuthorized && isMain) {
    return (
      <CategoriesProvider>
        <SearchProvider>
          <SearchOverlayProvider>
            <UserContext.Provider value={{ user }}>
              {isInWebview && location.pathname !== '/home' && (
                <HeaderWebView />
              )}
              <Container>
                {isInWebview && location.pathname === '/home' && <Header />}
                {!isInWebview && <Header />}
                <Routes />
                <NotMobile>
                  <Footer />
                </NotMobile>
                <Mobile>
                  <MobileNavigation />
                </Mobile>
                <ToastContainer />
                {!userHasAccepted && (
                  <TermModal
                    userHasAccepted={userHasAccepted}
                    setUserHasAccepted={setUserHasAccepted}
                  />
                )}
              </Container>
            </UserContext.Provider>
          </SearchOverlayProvider>
        </SearchProvider>
      </CategoriesProvider>
    );
  } else if (!isMain && isWebFrontend) {
    return (
      <Container>
        <StaticAlert>{dictionary.ALREADY_EXISTS}</StaticAlert>
      </Container>
    );
  } else {
    return null;
  }
};

export default App;
