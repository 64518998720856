import styled, { css } from 'styled-components';
import { isInWebview } from './../../helpers/reactNativeWebView';

const webviewStyles = css`
  width: 100%;
`;

const normalStyle = css`
  width: 70%;
`;

export const Container = styled.div`
  width: 100%;
  min-height: 280px;
  border-radius: 8px;
  border: 1px solid var(--neutralDark500);
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  transition: ease all 0.2s;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      border: solid 1px var(--lightBlue);
    }
  }
  @media (max-width: 768px) {
    ${isInWebview ? webviewStyles : normalStyle}
  }
`;

export const CourseBody = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (max-width: 768px) {
    padding: 0 8px;
    margin-bottom: 8px;
  }
`;

export const CourseImageContainer = styled.div`
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  height: 193px;
  flex: 0 0 auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  @media (max-width: 768px) {
    padding: 8px;
    height: auto;

    span {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: rgba(49, 52, 139, 0.56);
      border-radius: 111px;
      padding: 8px;

      svg {
        font-size: 18px;
        color: var(--white);
        padding-top: 2px;
      }
    }
    img {
      border-radius: 8px;
      max-height: 140px;
    }
  }
`;

export const CourseUpperContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`;

export const CourseCategory = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 20px;
  color: var(--lightBlue);
`;

export const BookmarkButton = styled.div`
  border: none;
  cursor: pointer;
  outline: none;
  padding: 6px;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  &.added-to-list {
    background-color: var(--lightBlue);
    color: white;
  }

  &:hover {
    background: var(--purple);
    svg path {
      color: white;
    }
  }
`;

export const CourseLowerContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const CourseTitle = styled.div`
  color: #ffffff;
  font-size: 2rem;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 16px;
`;

export const CourseAuthor = styled.div`
  color: var(--neutralLight40);
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  line-height: 15px;
  margin-bottom: 16px;

  svg {
    margin-right: 10px;
    fill: var(--neutralDark500);
  }
`;

export const CourseFooter = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  line-height: 15px;
  margin-bottom: 16px;
`;

export const CourseTime = styled.div`
  color: var(--neutralLight40);
  display: flex;
  align-items: center;
  font-size: 1.4rem;

  svg {
    margin-right: 10px;
    fill: var(--neutralDark500);
  }
`;

export const CourseLikes = styled.div`
  color: var(--neutralLight40);
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  margin-left: 16px;
  padding-left: 16px;
  border-left: 1px solid rgba(80, 95, 121, 0.2);

  svg {
    margin-right: 10px;
    fill: var(--neutralDark500);
  }
`;

export const CardTag = styled.span`
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: var(--purple);
  color: white;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 15px;
  border-radius: 30px;
  padding: 5px 12px;
  .sun-icon {
    margin-right: 5px;
  }
`;

export const RemainingTime = styled.p`
  font-size: 1.4rem;
  color: var(--neutralLight40);
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  svg {
    margin-right: 9px;
  }

  @media (max-width: 768px) {
    margin-bottom: 8px;
    color: var(--neutralLight40);
    font-weight: 500;
    font-size: 1rem;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }
`;
