import { createContext } from 'react';
import User from '../models/user';

interface Context {
  user: User;
}

export default createContext<Context>({
  user: {} as User,
});
