import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import CourseCard from '../../../../components/CourseCard';
import CourseCardSkeleton from '../../../../components/CourseCardSkeleton';

import { getContentCards } from '../../../../services/content';

import Pagination from '../../../../components/Pagination';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import Category from '../../../../models/category';
import * as Style from './styles';
import ContentCard from '../../../../models/content-card';

interface ChannelCoursesProps {
  category: Category;
}

const AvailableCourses: React.FC<ChannelCoursesProps> = ({ category }) => {
  const { dictionary } = useContext(LanguageContext);
  const [availableContents, setAvailableContents] = useState(
    [] as ContentCard[],
  );
  const [shouldShowSkeleton, setShouldShowSkeleton] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const contentsPerPage = 12;

  useEffect(() => {
    (async () => {
      try {
        if (!category.id) return;

        setShouldShowSkeleton(true);

        const allContents = await getContentCards({
          category_id: category.id,
        });

        if (allContents && allContents.length) {
          setPage(1);
          setTotalPages(Math.ceil(allContents.length / contentsPerPage));
        } else {
          setShouldShowSkeleton(false);
        }
      } catch (error) {
        toast.error(dictionary.ERROR.LOAD_CONTENTS);
        setShouldShowSkeleton(false);
      }
    })();
  }, [category]);

  useEffect(() => {
    (async () => {
      try {
        if (!category.id) return;

        const currentCourses = await getContentCards({
          category_id: category.id,
          limit: contentsPerPage,
          offset: page > 1 ? (page - 1) * contentsPerPage : undefined,
        });

        if (currentCourses && currentCourses.length) {
          setAvailableContents(currentCourses);
        } else {
          setAvailableContents([]);
          setShouldShowSkeleton(false);
        }
      } catch (e) {
        toast.error(dictionary.ERROR.LOAD_CONTENTS);
        setShouldShowSkeleton(false);
      }
    })();
  }, [category, page]);

  return (
    <Style.Container>
      <Style.Header>
        {category.logo && (
          <Style.Banner color={category.info.color}>
            <img src={category.logo} alt="Logo" />
          </Style.Banner>
        )}
        <Style.Description>{category.description}</Style.Description>
      </Style.Header>
      <Style.CourseListContainer>
        <Style.CourseList>
          {availableContents && availableContents.length ? (
            availableContents.map(content => (
              <CourseCard content={content} key={content.id} />
            ))
          ) : shouldShowSkeleton ? (
            Array(9)
              .fill('')
              .map((i, index) => <CourseCardSkeleton key={index} />)
          ) : (
            <Style.NotFound>{dictionary.COMMON.NOT_FOUND}</Style.NotFound>
          )}
        </Style.CourseList>
        {totalPages > 1 && (
          <Pagination totalPages={totalPages} setPage={setPage} />
        )}
      </Style.CourseListContainer>
    </Style.Container>
  );
};

export default AvailableCourses;
