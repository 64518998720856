import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 64px 0 0;

  .pagination {
    font-family: 'Nunito Sans', 'Roboto', sans-serif;
    font-size: 1.4rem;
    font-weight: 600;
    display: flex;
    position: relative;
    z-index: 2;

    a {
      border: 1px solid #ffffff;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      cursor: pointer;
    }

    li {
      &:nth-child(2) {
        margin: 0;
      }

      &.previous,
      &.next {
        a {
          border-color: #ffffff;
          color: #ffffff;
        }
      }

      &.previous {
        margin-right: 21px;
        margin-left: 0;
      }

      &.next {
        margin-right: 0;
        margin-left: 21px;
      }

      @media (hover: hover) and (pointer: fine) {
        &:not(.disabled, .active) {
          a:hover {
            background-color: var(--purple);
            color: white;
            border: var(--purple);
          }
        }
      }

      &.disabled,
      &.active {
        a {
          border: 1px solid var(--inputForm);
          color: var(--inputForm);
          cursor: default;
          user-select: none;
          outline: none;
        }

        svg {
          color: var(--inputForm) !important;
        }
      }

      & + li {
        margin-right: 0;
        margin-left: 10px;
      }
    }
  }
`;
