import styled from 'styled-components';

export const Container = styled.div`
  min-height: 36vh;

  margin: auto;
  max-width: 1424px;
  width: 100%;
  position: relative;
  z-index: 2;

  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;

export const Body = styled.div`
  margin: 46px 0 100px;

  @media (max-width: 768px) {
    margin-top: 32px;
  }
`;

export const Header = styled.header`
  margin-bottom: 54px;

  @media (max-width: 768px) {
    margin-bottom: 22px;
  }
`;

export const Title = styled.h1`
  color: #ffffff;
  font-size: 4rem;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 22px;

  @media (max-width: 768px) {
    font-size: 2rem;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 8px;
  }
`;

export const Description = styled.p`
  color: var(--neutralLight40);
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 24px;
  width: 50%;

  @media (max-width: 768px) {
    font-size: 1.4rem;
    line-height: 20px;
    width: 100%;
  }
`;

export const List = styled.div``;

export const NotFound = styled.p`
  font-weight: 500;
  font-size: 1.8rem;
  color: var(--neutralLight40);
`;
