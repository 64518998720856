import { useContext, useState } from 'react';
import BottomTabNavigation from './components/BottomTabNavigation';
import SidebarMenu from './components/SidebarMenu';
import HomeIcon from '../../assets/svgs/home.svg';
import RingIcon from '../../assets/svgs/category-ring.svg';
import BroadcastIcon from '../../assets/svgs/broadcast.svg';
import UserIcon from '../../assets/svgs/user.svg';
import { LanguageContext } from '../../contexts/LanguageContext';
import * as Style from './styles';

const MobileNavigation = () => {
  const { dictionary } = useContext(LanguageContext);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState('');

  const navigationTabOptions = [
    { name: 'Home', value: 'Home', path: '/home', icon: HomeIcon },
    {
      name: dictionary.COMMON.CHANNELS,
      value: 'Canais',
      path: '/channels-mobile',
      icon: RingIcon,
    },
    // { name: 'Lives', value: 'Lives', path: '/lives', icon: BroadcastIcon },
    {
      name: dictionary.COMMON.PROFILE,
      value: 'Perfil',
      path: '',
      icon: UserIcon,
    },
  ];
  return (
    <Style.Container>
      <BottomTabNavigation
        tabs={navigationTabOptions}
        setIsSidebarOpen={setIsSidebarOpen}
        setSelectedTab={setSelectedTab}
      />
      <SidebarMenu
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        selectedTab={selectedTab}
      />
    </Style.Container>
  );
};

export default MobileNavigation;
