import styled from 'styled-components';

export const Container = styled.div`
  img {
    transition: filter 0.2s linear;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      img {
        filter: brightness(1.4);
      }
    }
  }

  overflow: hidden;
  width: 224px;
  height: 124px;
  border-radius: 24px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 204px;
    height: 108px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid rgba(255, 255, 255, 0.7);
    box-sizing: border-box;
    filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.51));
    img {
      width: 110%;
    }
  }
`;

export const CategoryThumbnail = styled.img`
  width: 100%;
  object-position: center;
  object-fit: cover;
`;
