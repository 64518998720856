import React from 'react';
import * as Style from './styles';
import RecommendedCourses from './components/RecommendedCourses';
import NewCourses from './components/NewCourses';
import MostViewedCourses from './components/MostViewedCourses';
import OngoingCoursesHome from './components/OngoingCoursesHome';

const Home: React.FC = () => {
  return (
    <Style.HomeContainer>
      <Style.HomeBody>
        <OngoingCoursesHome />
        <RecommendedCourses />
        <NewCourses />
        <MostViewedCourses />
      </Style.HomeBody>
    </Style.HomeContainer>
  );
};

export default Home;
