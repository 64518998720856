import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

interface LabelProps {
  checked?: boolean;
}

const LinkStyle = css`
  display: flex;
  align-items: center;
  font-family: 'Nunito Sans', 'Roboto', sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--neutralLight40);
  line-height: 19px;
  text-decoration: none;
  text-transform: uppercase;

  & + * {
    margin-top: 18px;
    padding-top: 18px;
    border-top: 1px solid var(--neutralLight40);
  }

  svg {
    margin-right: 10px;
    path {
      stroke: var(--neutralLight40);
    }
  }
`;

export const Container = styled.nav`
  background-color: #0c0c33;
  box-shadow: 0px 24px 64px rgba(45, 48, 58, 0.13);
  border: 1px solid #87878766;
  border-radius: 8px;
  position: absolute;
  right: 0;
  top: calc(100% + 25px);
  padding: 20px 38px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  width: 265px;

  &::before {
    content: '';
    border-style: solid;
    border-color: #0c0c33 transparent;
    border-width: 0px 10px 15px 10px;
    position: absolute;
    bottom: 100%;
    right: 0;
    transform: translateX(-50%);
  }
`;

export const OptionLink = styled(Link)`
  ${LinkStyle}
`;

export const LogoutLink = styled.a`
  ${LinkStyle}
  cursor: pointer;
`;

export const LanguageLink = styled.div`
  ${LinkStyle}
  flex-direction: column;
  align-items: flex-start;

  span {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
  }
`;

export const LinkText = styled.span`
  position: relative;
  display: flex;
  align-items: center;

  &:after {
    content: '';
    width: 0;
    opacity: 0;
    height: 2px;
    bottom: -6px;
    left: 0;
    position: absolute;
    transition: all 0.3s ease-in-out;
    background: var(--purple);
  }
  &:hover {
    &:after {
      width: 100%;
      opacity: 1;
    }
  }
`;

export const Label = styled.label<LabelProps>`
  width: 15px;
  height: 15px;
  margin-right: 10px;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  ${({ checked }) =>
    checked &&
    css`
      border: 2px solid var(--lightBlue);
    `}

  input {
    visibility: hidden;
    width: 4px;
  }
`;
