import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import GlobalStyle from './styles/global';
import './styles/slick.css';
import { LanguageProvider } from './contexts/LanguageContext';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <LanguageProvider>
        <App />
      </LanguageProvider>
    </Router>
    <GlobalStyle />
  </React.StrictMode>,
  document.getElementById('root'),
);
