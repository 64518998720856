import React from 'react';
import { BsFullscreen } from 'react-icons/bs';
import 'scorm-again';
import { useEffect } from 'react';
import Content from '../../models/content';

import { ButtonFullScreen, IframeScormContainer } from './style';

declare let window: any;

interface IPlayScorm {
  selectedContent: Content;
  finishCourseOrTrail: () => Promise<void>;
  startCourseOrTrail: () => Promise<void>;
  goToNextContent?: () => void;
}

const PlayScorm: React.FC<IPlayScorm> = ({
  selectedContent,
  goToNextContent,
  finishCourseOrTrail,
  startCourseOrTrail,
}) => {
  useEffect(() => {
    if (selectedContent.scorm_version === '2004') {
      window.API_1484_11 = new window.Scorm2004API({});

      window.API_1484_11.on('Initialize', async () => {
        await startCourseOrTrail();
      });

      let completionStatus = '';
      let successStatus = '';
      window.API_1484_11.on(
        'SetValue.cmi.*',
        async (CMIElement: string, value: string) => {
          if (CMIElement === 'cmi.completion_status' && value === 'completed') {
            completionStatus = value;
            if (successStatus === 'passed') {
              try {
                await finishCourseOrTrail();
              } catch (error) {}
              if (goToNextContent) {
                goToNextContent();
              }
            }
          }
          if (CMIElement === 'cmi.success_status' && value === 'passed') {
            successStatus = value;
            if (completionStatus === 'completed') {
              try {
                await finishCourseOrTrail();
              } catch (error) {}
              if (goToNextContent) {
                goToNextContent();
              }
            }
          }
        },
      );
    } else {
      window.API = new window.Scorm12API({});

      window.API.on('LMSInitialize', async () => {
        await startCourseOrTrail();
      });

      window.API.on(
        'LMSSetValue.cmi.core.lesson_status',
        async (_: any, value: string) => {
          if (value === 'completed') {
            try {
              await finishCourseOrTrail();
            } catch (error) {}

            if (goToNextContent) {
              goToNextContent();
            }
          }
        },
      );
    }
  }, [
    finishCourseOrTrail,
    goToNextContent,
    startCourseOrTrail,
    selectedContent,
  ]);

  useEffect(() => {
    return () => {
      if (selectedContent.scorm_version === '2004') {
        window.API_1484_11.clear('Initialize');
        window.API_1484_11.clear('SetValue.cmi.*');
        window.API_1484_11 = undefined;
      } else {
        window.API.clear('LMSInitialize');
        window.API.clear('LMSSetValue.cmi.core.lesson_status');
        window.API = undefined;
      }
    };
  }, [selectedContent]);

  const handleFullScreen = () => {
    if (document.fullscreenEnabled) {
      const iframe = document.getElementById('iframe-scorm');

      if (iframe) {
        iframe.style.overflow = 'hidden';
        if (iframe.requestFullscreen) {
          iframe.requestFullscreen();
        }
      }
    }
  };

  return (
    <IframeScormContainer>
      <iframe
        id="iframe-scorm"
        title="scorm-content"
        src={selectedContent.contentUrl}
        allowFullScreen
      ></iframe>

      <ButtonFullScreen onClick={handleFullScreen}>
        <BsFullscreen />
        Tela Cheia
      </ButtonFullScreen>
    </IframeScormContainer>
  );
};

export { PlayScorm };
