import styled from 'styled-components';
import * as CourseCardStyle from '../../components/CourseCard/styles';
import * as BreadcrumbStyle from '../../components/Breadcrumb/styles';

export const Container = styled.div`
  min-height: 36vh;

  margin: auto;
  max-width: 1424px;
  width: 100%;

  @media (max-width: 768px) {
    padding: 0;

    ${BreadcrumbStyle.Container} {
      margin: 0 16px;
      width: auto;
    }
  }
`;

export const Body = styled.div`
  margin: 46px 0 180px;

  @media (max-width: 768px) {
    margin: 32px 0 86px;
  }
`;

export const Title = styled.h1`
  margin-bottom: 85px;
  color: var(--neutralDark900);
  font-size: 4rem;
  font-weight: 700;
  line-height: 32px;

  @media (max-width: 768px) {
    padding: 0 16px;
    font-size: 2rem;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 32px;
  }
`;

export const Highlight = styled.div`
  background-color: white;
  padding: 46px 0 64px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    width: 100vw;
    height: calc(100% + 2px);
    z-index: -1;
    border-bottom: 2px solid var(--gray15);
  }

  @media (max-width: 768px) {
    padding: 16px;
  }
`;

export const HighlightLive = styled.div`
  & + & {
    padding-top: 46px;
  }
`;

export const HighlightHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const HighlightTitle = styled.h2`
  color: var(--purple);
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 22px;

  @media (max-width: 768px) {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 16px;
    letter-spacing: 0.15px;
  }
`;

export const HighlightInfo = styled.div`
  color: var(--gray);
  font-size: 1.4rem;
  font-weight: 400;
  display: flex;
  align-items: center;

  svg {
    margin-right: 6px;
    flex-shrink: 0;
  }

  @media (max-width: 768px) {
    font-size: 1.2rem;
    letter-spacing: 0.4px;
  }
`;

export const HighlightContent = styled.div`
  width: 100%;
  height: 42vw;
  max-height: 693px;
  background-color: var(--black);
  position: relative;

  iframe {
    height: 100%;
    width: 100%;
  }
`;

export const LiveTag = styled.span`
  background-color: #ff0000;
  color: white;
  padding: 2px 12px;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 24px;
  border-radius: 31px;
  position: absolute;
  top: 32px;
  right: 32px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    top: 16px;
    right: 16px;
  }
`;

export const LivesListHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LivesListContainer = styled.div`
  position: relative;
  padding: 64px 0;

  & + & {
    padding-bottom: 0;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      height: 100%;
      z-index: -1;
      border-top: 2px solid var(--gray15);
    }
  }

  @media (max-width: 768px) {
    padding: 56px 0;
  }
`;

export const LivesListTitle = styled.h2`
  color: var(--neutralDark900);
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 40px;
  margin-bottom: 36px;

  @media (max-width: 768px) {
    font-size: 1.6rem;
    line-height: 24px;
    margin-bottom: 32px;
    padding: 0 16px;
  }
`;

export const SliderArrows = styled.div`
  display: flex;
`;

export const SliderArrow = styled.div`
  cursor: pointer;

  & + & {
    margin-left: 35px;
  }

  svg {
    color: var(--blue);
    width: 33px;
    height: 25px;
  }

  &.disabled {
    pointer-events: none;
    svg {
      opacity: 0.35;
    }
  }
`;

export const LivesListScroll = styled.div`
  ${CourseCardStyle.Container} {
    max-width: 347px;
  }

  @media (max-width: 768px) {
    display: flex;
    justify-content: flex-start;
    overflow-x: auto;
    flex-wrap: nowrap;
    gap: 8px;
    padding-bottom: 16px;
    padding-left: 16px;

    & > div:last-child {
      margin-right: 16px;
    }
  }
`;

export const LivesListPaginated = styled.div`
  display: grid;
  gap: 30px 13px;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));

  @media (max-width: 768px) {
    justify-items: center;
    padding: 0 16px;
  }
`;

export const NotFound = styled.p`
  font-weight: bold;
  font-size: 1.4rem;

  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;
