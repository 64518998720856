import styled from 'styled-components';

interface ContainerProps {
  height?: number;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  flex: 1 0 calc(40% - 40px);
  display: flex;
  flex-direction: column;
  height: ${({ height }) => (height ? height + 'px' : '500px')};
`;

export const Line = styled.div`
  width: 100%;
  height: 2px;
  background: none;
  border-radius: 100px;
  margin-top: 8px;
  margin-bottom: auto; ;
`;

export const TrailCourses = styled.div`
  margin-top: 24px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding-right: 8px;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 115px;
  }
  &::-webkit-scrollbar-thumb {
    background: #1072b9;
    outline: 0px;
    border-radius: 16px;
  }
`;
