import styled from 'styled-components';
import StarsBackground from '../../../../assets/stars-background.png';

interface CategoryProps {
  color: string;
}

export const Container = styled.div``;

export const Header = styled.header`
  max-width: 1420px;
  width: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: start;

  margin: auto;
  padding: 46px 0 86px;

  @media (max-width: 768px) {
    padding: 32px 0 48px;
  }
`;

export const PageTitle = styled.h2`
  font-size: 4rem;
  font-weight: 700;
  line-height: 32px;
  color: #ffffff;

  @media (max-width: 768px) {
    font-size: 2rem;
    font-weight: 500;
    line-height: 24px;
  }
`;

export const CoursesBody = styled.div`
  max-width: 1420px;
  height: fit-content;

  display: flex;
  align-items: stretch;
  justify-content: center;

  margin: 0 auto 64px;
`;

export const Categories = styled.div`
  min-width: 270px;
  width: auto;
  flex-shrink: 0;
  background-color: var(--darkPurple);
  padding: 32px 50px 180px 30px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.13);
  border-radius: 0px 4px 0px 0px;
  z-index: 2;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: calc(-100vw + 5px);
    height: 100%;
    width: 100vw;
  }
`;

export const CategoriesTitle = styled.h4`
  font-size: 2.1rem;
  font-weight: 300;
  line-height: 22px;
  color: #ffffff;
`;

export const CategoriesNav = styled.nav``;

export const CategoriesList = styled.ul``;

export const Category = styled.li<CategoryProps>`
  display: flex;
  align-items: center;
  margin-top: 45px;
  font-size: 1.8rem;
  line-height: 22px;
  font-weight: 400;
  text-decoration: none;
  color: var(--lightBlue);
  cursor: pointer;
  position: relative;

  img {
    margin-right: 10px;
  }

  svg {
    margin-right: 15px;

    path {
      fill: ${({ color }) => (color ? color : 'var(--black)')};
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: #ffffff;
      font-weight: 700;

      &:after {
        content: '';
        background-color: red;
        position: absolute;
        left: -30px;
        top: 0;
        height: 100%;
        width: 8px;
        border-radius: 8px;
        background-color: var(--purple);
        z-index: 1;
      }
    }
  }
`;

export const CourseListContainer = styled.div`
  padding-left: 16px;
  width: 100%;

  @media (max-width: 768px) {
    padding-left: 0;
    margin-bottom: 87px;
  }
`;

export const CourseList = styled.div`
  display: grid;
  gap: 30px 16px;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  position: relative;

  &:before {
    content: '';
    background-image: url(${StarsBackground});
    background-position: center;
    background-repeat: repeat;
    position: absolute;
    left: 0;
    top: 600px;
    width: 100%;
  }

  @media (max-width: 768px) {
    justify-items: center;
  }
`;

export const Filter = styled.div`
  width: 190px;
  margin-left: auto;

  div[class$='-container'] {
    div {
      &[class$='-control'] {
        cursor: pointer;
        border-radius: 30px;
        font-size: 1.4rem;
        font-weight: 400;
        border-color: var(--lightBlue);
        background-color: transparent;

        span[class$='-indicatorSeparator'] {
          display: none;
        }
      }
      &[class$='-singleValue'] {
        font-family: 'Nunito Sans', 'Roboto', sans-serif;
        font-size: 1.4rem;
        font-weight: 600;
        padding-left: 14px;
        color: var(--lightBlue);
        line-height: 19px;
      }
      &[class$='-option'] {
        font-family: 'Nunito Sans', 'Roboto', sans-serif;
        font-size: 1.4rem;
        font-weight: 600;
        color: var(--purple);
        line-height: 19px;
      }
      &[class$='-menu'] {
        margin-top: 5px;
        background-color: #f6f6f6;
        box-shadow: 0px 24px 64px rgb(45 48 58 / 13%);
        border: 1px solid #87878766;
        border-radius: 8px;
      }
      &[class$='-IndicatorsContainer'] {
        width: 50px;
      }
      &[class$='-indicatorContainer'] {
        color: var(--lightBlue);
      }
    }
  }
`;

export const NotFound = styled.p`
  font-weight: bold;
  font-size: 1.4rem;
`;
