/* DESKTOP */
export const bannerSliderSettings = {
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: '90px',
  arrows: true,
  autoplaySpeed: 2000,
};

export const channelSliderSettings = {
  infinite: true,
  speed: 1000,
  slidesToShow: 4,
  slidesToScroll: 4,
  centerMode: true,
  centerPadding: '90px',
  arrows: true,
};

export const livesSlideSettings = {
  infinite: false,
  speed: 1000,
  slidesToShow: 4,
  slidesToScroll: 4,
  centerMode: false,
  centerPadding: '90px',
  arrows: false,
};

/* MOBILE */

export const bannerMobileSliderSettings = {
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: '16px',
  arrows: false,
  autoplaySpeed: 2000,
};

export const channelMobileSliderSettings = {
  infinite: true,
  speed: 1000,
  variableWidth: true,
  slidesToScroll: 1,
  centerMode: false,
  arrows: false,
};
