import styled, { css } from 'styled-components';

interface LabelProps {
  checked?: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  margin-left: 16px;
  position: relative;
`;

export const Label = styled.label<LabelProps>`
  cursor: pointer;

  font-family: 'Nunito Sans', 'Roboto', sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 19px;
  color: #fff;

  display: flex;
  align-items: center;

  & + label {
    margin-left: 16px;
  }

  input {
    visibility: hidden;
    width: 4px;
  }

  img {
    width: 32px;
    border-radius: 50%;
    border: 2px solid transparent;
    transition: all 0.2s ease;

    @media (max-width: 768px) {
      width: 24px;
    }

    ${({ checked }) =>
      checked &&
      css`
        border: 2px solid #fff;
      `}
  }
`;
