import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  AiOutlineEye,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
} from 'react-icons/ai';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';
import { toast } from 'react-toastify';
import Breadcrumb from '../../components/Breadcrumb';
import Loading from '../../components/Loading';
import Pagination from '../../components/Pagination';
import { NotMobile, Mobile } from '../../components/Responsive';
import { livesSlideSettings } from '../../config/sliders';
import { LanguageContext } from '../../contexts/LanguageContext';
import Live from '../../models/lives';
import {
  getCurrentLives as getCurrentLivesService,
  getNextLives as getNextLivesService,
  getPreviousLives as getPreviousLivesService,
} from '../../services/lives';
import LiveCard from './components/LiveCard';
import * as Style from './styles';

const Lives = () => {
  const { dictionary } = useContext(LanguageContext);
  const [isLoadingCurrentLive, setIsLoadingCurrentLive] = useState(true);
  const [isLoadingNextLives, setIsLoadingNextLives] = useState(true);
  const [isLoadingPreviousLives, setIsLoadingPreviousLives] = useState(true);
  const [currentLives, setCurrentLives] = useState<Live[]>([]);
  const [nextLives, setNextLives] = useState<Live[]>([]);
  const [prevArrowIsDisabled, setPrevArrowIsDisabled] = useState(true);
  const [nextArrowIsDisabled, setNextArrowIsDisabled] = useState(false);

  const [previousLives, setPreviousLives] = useState<Live[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);

  const MobileScreen = useMediaQuery({ maxWidth: 768 });
  const SmallerScreens = useMediaQuery({ maxWidth: 1049 });
  const SmallScreens = useMediaQuery({ maxWidth: 1399 });
  const MediumScreens = useMediaQuery({ maxWidth: 1564 });

  const [numberOfLivesPerSlide, setNumberOfLivesPerSlide] = useState(4);

  const coursesPerPage = MobileScreen ? 3 : 8;

  const crumbs = [
    { name: 'HOME', path: '/home' },
    { name: 'Lives', path: '' },
  ];

  const sliderRef: any = useRef(null);

  const relativeStartTime = (live: Live) => {
    const formattedTime = new Date(live.start);
    const now = new Date();

    const timeDifferenceInHours = Math.ceil(
      Math.abs(formattedTime.getTime() - now.getTime()) / 36e5,
    );

    return timeDifferenceInHours;
  };

  const liveUrl = (live: Live) => {
    const playerHash = process.env.REACT_APP_PLAYER_HASH;
    const playerBaseUrl = 'https://fast.player.liquidplatform.com/pApiv2/embed';
    if (live.transmission_id) {
      return `${playerBaseUrl}/${playerHash}/live/${live.transmission_id}`;
    }

    return `${playerBaseUrl}/${playerHash}`;
  };

  useEffect(() => {
    if (SmallerScreens) {
      setNumberOfLivesPerSlide(1.5);
      return;
    }

    if (SmallScreens && !SmallerScreens) {
      setNumberOfLivesPerSlide(2.5);
      return;
    }

    if (MediumScreens && !SmallScreens) {
      setNumberOfLivesPerSlide(3.5);
      return;
    }
  }, [MediumScreens, SmallScreens, SmallerScreens]);

  const onSlideArrowClick = (oldIndex: number, newIndex: number) => {
    const lastIndex = nextLives.length - numberOfLivesPerSlide;

    if (newIndex === 0) {
      setPrevArrowIsDisabled(true);
      setNextArrowIsDisabled(false);
    } else if (newIndex === lastIndex) {
      setNextArrowIsDisabled(true);
      setPrevArrowIsDisabled(false);
    } else {
      setPrevArrowIsDisabled(false);
      setNextArrowIsDisabled(false);
    }
  };

  const getCurrentLives = useCallback(async () => {
    try {
      setIsLoadingCurrentLive(true);
      const lives = await getCurrentLivesService();
      setCurrentLives(lives);
      setIsLoadingCurrentLive(false);
    } catch (error) {
      toast.error(dictionary.ERROR.LOAD_CONTENTS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNextLives = useCallback(async () => {
    try {
      setIsLoadingNextLives(true);
      const lives = await getNextLivesService();
      setNextLives(lives);
      setIsLoadingNextLives(false);
    } catch (error) {
      toast.error(dictionary.ERROR.LOAD_CONTENTS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPreviousLives = useCallback(async () => {
    try {
      const lives = await getPreviousLivesService();
      setTotalPages(Math.ceil(lives.length / coursesPerPage));
    } catch (error) {
      toast.error(dictionary.ERROR.LOAD_CONTENTS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPaginatedPreviousLives = useCallback(async () => {
    try {
      setPreviousLives([]);
      setIsLoadingPreviousLives(true);
      const lives = await getPreviousLivesService({
        limit: coursesPerPage,
        offset: page > 1 ? (page - 1) * coursesPerPage : undefined,
      });
      setPreviousLives(lives);
      setIsLoadingPreviousLives(false);
    } catch (error) {
      toast.error(dictionary.ERROR.LOAD_CONTENTS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    getCurrentLives();
    getNextLives();
    getPreviousLives();
  }, [getCurrentLives, getNextLives, getPreviousLives]);

  useEffect(() => {
    getPaginatedPreviousLives();
  }, [getPaginatedPreviousLives]);

  return (
    <Style.Container>
      <Breadcrumb crumbs={crumbs} />
      <Style.Body>
        <Style.Title>Lives</Style.Title>
        <Style.Highlight>
          {currentLives && currentLives.length ? (
            currentLives.map(live => (
              <Style.HighlightLive key={live.live_id}>
                <Style.HighlightHeader>
                  <Style.HighlightTitle>{live.name}</Style.HighlightTitle>
                  <Style.HighlightInfo>
                    <AiOutlineEye /> {dictionary.LIVES.WATCHING_NOW} •{' '}
                    {dictionary.LIVES.STREAMING_STARTED}{' '}
                    {relativeStartTime(live)} {dictionary.COMMON.HOUR}
                    {relativeStartTime(live) === 1 ? '' : 's'}{' '}
                    {dictionary.LIVES.AGO}
                  </Style.HighlightInfo>
                </Style.HighlightHeader>
                <Style.HighlightContent>
                  <Style.LiveTag>{dictionary.LIVES.LIVE}</Style.LiveTag>
                  <iframe allowFullScreen={true} src={liveUrl(live)}></iframe>
                </Style.HighlightContent>
              </Style.HighlightLive>
            ))
          ) : isLoadingCurrentLive ? (
            <Loading />
          ) : (
            <Style.NotFound>{dictionary.LIVES.NO_ACTIVE_LIVE}</Style.NotFound>
          )}
        </Style.Highlight>
        <Style.LivesListContainer>
          <Style.LivesListHeader>
            <Style.LivesListTitle>
              {dictionary.LIVES.NEXT_LIVES}
            </Style.LivesListTitle>
            <NotMobile>
              <Style.SliderArrows>
                <Style.SliderArrow
                  className={prevArrowIsDisabled ? 'disabled' : ''}
                  onClick={() => sliderRef.current.slickPrev()}
                >
                  <AiOutlineArrowLeft />
                </Style.SliderArrow>
                <Style.SliderArrow
                  className={
                    nextArrowIsDisabled ||
                    nextLives.length <= numberOfLivesPerSlide
                      ? 'disabled'
                      : ''
                  }
                  onClick={() => sliderRef.current.slickNext()}
                >
                  <AiOutlineArrowRight />
                </Style.SliderArrow>
              </Style.SliderArrows>
            </NotMobile>
          </Style.LivesListHeader>
          {nextLives && nextLives.length ? (
            <Style.LivesListScroll>
              <NotMobile>
                <Slider
                  {...livesSlideSettings}
                  slidesToShow={numberOfLivesPerSlide}
                  slidesToScroll={numberOfLivesPerSlide}
                  ref={sliderRef}
                  beforeChange={(oldIndex, nextIndex) =>
                    onSlideArrowClick(oldIndex, nextIndex)
                  }
                >
                  {nextLives.map(live => (
                    <LiveCard key={live.live_id} live={live} />
                  ))}
                </Slider>
              </NotMobile>
              <Mobile>
                {nextLives.map(live => (
                  <LiveCard key={live.live_id} live={live} />
                ))}
              </Mobile>
            </Style.LivesListScroll>
          ) : isLoadingNextLives ? (
            <Loading />
          ) : (
            <Style.NotFound>{dictionary.COMMON.NOT_FOUND}</Style.NotFound>
          )}
        </Style.LivesListContainer>
        <Style.LivesListContainer>
          <Style.LivesListHeader>
            <Style.LivesListTitle>
              {dictionary.LIVES.PREVIOUS_LIVES}
            </Style.LivesListTitle>
          </Style.LivesListHeader>
          {previousLives && previousLives.length ? (
            <Style.LivesListPaginated>
              {previousLives.map(live => (
                <LiveCard key={live.live_id} live={live} />
              ))}
            </Style.LivesListPaginated>
          ) : isLoadingPreviousLives ? (
            <Loading />
          ) : (
            <Style.NotFound>{dictionary.COMMON.NOT_FOUND}</Style.NotFound>
          )}
          {totalPages > 1 && (
            <Pagination totalPages={totalPages} setPage={setPage} />
          )}
        </Style.LivesListContainer>
      </Style.Body>
    </Style.Container>
  );
};

export default Lives;
