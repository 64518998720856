import styled from 'styled-components';

export const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  max-height: 800px;
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  top: 50px;
  transition: all 0.2s linear;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-height: 600px) {
    max-height: 100vh;
  }
`;

export const Modal = styled.div`
  position: relative;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 48px;
  width: 90%;
  max-width: 900px;
  height: 75%;
  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch !important;
`;

export const Body = styled.div`
  margin-bottom: 20px;
`;

export const Button = styled.button`
  display: block;
  margin: auto;
  background-color: var(--purple);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px;
  font-size: 1.4rem;
  width: 100px;
  cursor: pointer;
`;
