import styled from 'styled-components';

export const Container = styled.div``;

export const MaterialsList = styled.ul``;

export const MaterialItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--neutralLight40);
  border-radius: 8px;
  padding: 10px;

  button {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: white;
    background-color: var(--blue);
    border: none;
    border-radius: 18px;
    padding: 8px 18px;
    font-size: 1.4rem;
    font-weight: 700;

    svg {
      margin-right: 8px;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: var(--purple);
      }
    }
  }

  & + & {
    margin-top: 10px;
  }
`;

export const NotFound = styled.p`
  font-weight: 500;
  font-size: 1.8rem;
  color: var(--neutralLight40);
`;
