import { useContext, useMemo } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Swal from 'sweetalert2';
import { AiOutlineSend } from 'react-icons/ai';

import Breadcrumb from '../../components/Breadcrumb';

import { LanguageContext } from '../../contexts/LanguageContext';
import UserContext from '../../contexts/UserContext';

import * as Style from './styles';
import { sendEmail } from '../../services/contact';

const Contact = () => {
  const { dictionary } = useContext(LanguageContext);
  const { user } = useContext(UserContext);

  const validationSchema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().required(),
    subject: yup.string().trim().required(),
    message: yup.string().trim().required(),
  });

  const crumbs = [
    { name: 'HOME', path: '/home' },
    { name: dictionary.CONTACT.CONTACT_US, path: '' },
  ];

  const formik = useFormik({
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      name: user.name,
      email: user.email || user.name,
      subject: '',
      message: '',
    },
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        await sendEmail(values.subject.trim(), values.message.trim());
        resetForm();
        Swal.fire({
          title: dictionary.MODAL.SUCCESS,
          text: dictionary.MODAL.EMAIL.SUCCESS,
        });
      } catch (error) {
        Swal.fire({
          title: dictionary.MODAL.ERROR,
          text: dictionary.MODAL.EMAIL.ERROR,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });

  const {
    isValid,
    handleSubmit,
    values,
    handleBlur,
    handleChange,
    errors,
    initialValues,
    touched,
    isSubmitting,
  } = formik;

  const canSubmit = useMemo(() => {
    if (Object.keys(errors).length > 0) {
      return false;
    }
    if (
      values.subject === initialValues.subject ||
      values.message === initialValues.message
    ) {
      return false;
    }
    if (!isValid) {
      return false;
    }

    return true;
  }, [errors, isValid, values, initialValues]);

  return (
    <Style.Container>
      <Breadcrumb crumbs={crumbs} />
      <Style.Body>
        <Style.Header>
          <Style.Title>{dictionary.CONTACT.CONTACT_US}</Style.Title>
          <Style.Description>
            {dictionary.CONTACT.DESCRIPTION}
          </Style.Description>
        </Style.Header>
        <form onSubmit={handleSubmit}>
          <label htmlFor="name" className="disabled">
            {dictionary.CONTACT.NAME}
          </label>
          <input
            id="name"
            type="text"
            name="name"
            value={values.name}
            disabled
          />
          <Style.ErrorMessage></Style.ErrorMessage>

          <label htmlFor="email" className="disabled">
            E-mail
          </label>
          <input
            id="email"
            type="text"
            name="email"
            value={values.email}
            disabled
          />
          <Style.ErrorMessage></Style.ErrorMessage>

          <label htmlFor="subject">{dictionary.CONTACT.SUBJECT}</label>
          <input
            id="subject"
            type="text"
            name="subject"
            value={values.subject}
            onBlur={handleBlur('subject')}
            onChange={handleChange('subject')}
          />
          <Style.ErrorMessage>
            {!!errors.subject &&
              touched.subject &&
              dictionary.CONTACT.ERROR.REQUIRED}
          </Style.ErrorMessage>

          <label htmlFor="message">{dictionary.CONTACT.MESSAGE}</label>
          <textarea
            id="message"
            name="message"
            value={values.message}
            onBlur={handleBlur('message')}
            onChange={handleChange('message')}
          ></textarea>
          <Style.ErrorMessage>
            {!!errors.message &&
              touched.message &&
              dictionary.CONTACT.ERROR.REQUIRED}
          </Style.ErrorMessage>
          <button type="submit" disabled={!canSubmit || isSubmitting}>
            <AiOutlineSend />
            {dictionary.CONTACT.CTA}
          </button>
        </form>
      </Style.Body>
    </Style.Container>
  );
};

export default Contact;
