import React, { useEffect, useState } from 'react';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import Swal from 'sweetalert2';
import DefaultButton from '../../../../../../components/Buttons/DefaultButton';
import {
  getTrailContentReview,
  reviewTrailContent,
  updateTrailContentReview,
} from '../../../../../../services/trail-review';
import * as Style from './styles';

interface TrailContentRateProps {
  trailId: string;
  stars: number;
}

const TrailContentRate: React.FC<TrailContentRateProps> = ({
  trailId,
  stars,
}) => {
  const [defaultStars, setDefaultStars] = useState(stars);
  const [starsValue, setStarsValue] = useState(stars);
  const starsMaxQuantity = 5;

  useEffect(() => {
    (async () => {
      try {
        if (starsValue === 0) return;
        const data = await getTrailContentReview(trailId);
        setDefaultStars(data.stars);
      } catch (error) {}
    })();
  }, [trailId]);

  const onStarClick = async () => {
    try {
      if (defaultStars) {
        await updateTrailContentReview(trailId, starsValue);
      } else {
        await reviewTrailContent(trailId, starsValue);
      }
      setDefaultStars(starsValue);
      Swal.fire({
        title: 'Sucesso!',
        text: 'Conteúdo avaliado com sucesso!',
      });
    } catch (error) {
      Swal.fire({
        title: 'Erro!',
        text: 'Houve um erro ao avaliar o conteúdo!',
      });
    }
  };

  const changeStarQuantity = (newQuantity: number) => {
    setStarsValue(newQuantity);
  };

  return (
    <Style.RateContainer>
      <Style.RateStars>
        {Array(starsMaxQuantity)
          .fill('')
          .map((star, index) => (
            <DefaultButton
              key={index}
              onMouseOver={() => changeStarQuantity(index + 1)}
              onMouseOut={() => changeStarQuantity(defaultStars)}
              onClick={onStarClick}
            >
              {index + 1 <= starsValue ? (
                <AiFillStar color="var(--yellow)" size={21} />
              ) : (
                <AiOutlineStar color="var(--yellow)" size={21} />
              )}
            </DefaultButton>
          ))}
      </Style.RateStars>
    </Style.RateContainer>
  );
};

export default TrailContentRate;
