import React, { useState, useEffect, useContext } from 'react';
import CourseCardSkeleton from '../../../../components/CourseCardSkeleton';
import Pagination from '../../../../components/Pagination';
import TrailCard from '../../../../components/TrailCard';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import OngoingTrail from '../../../../models/from-api/ongoing-trail';
import OngoingCourse from '../../../../models/from-api/ongoing-course';

import { getOngoingCourse } from '../../../../services/course';
import { getOngoingTrails } from '../../../../services/trail';

import * as Style from './styles';
import CardCourseOngoing from '../../../../components/CardCourseOngoing';
import { Mobile, NotMobile } from '../../../../components/Responsive';
import { useHistory, useLocation } from 'react-router';
import { ReactComponent as Sliders } from '../../../../assets/svgs/Sliders.svg';
import { FiSearch } from 'react-icons/fi';
import { isInWebview } from '../../../../helpers/reactNativeWebView';

const OngoingCourses: React.FC = () => {
  const { dictionary } = useContext(LanguageContext);
  const [contents, setContents] = useState([] as OngoingTrail[]);
  const [contentsCourse, setContentsCourse] = useState([] as OngoingCourse[]);
  const [shouldShowSkeleton, setShouldShowSkeleton] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [count, setCount] = useState(0);
  const [totalShowing, setTotalShowing] = useState(0);
  const INITIAL_VALUE_SHOWING = 6;
  const VALUE_TO_SHOW = 3;
  const history = useHistory();
  const location = useLocation();
  const contentsPerPage = history.location.pathname.includes('/home') ? 4 : 8;
  const profileOngoing = history.location.pathname === '/my-courses/ongoing';
  const filteredContents = contents.filter(
    content =>
      content.name &&
      content.name
        .toLowerCase()
        .trim()
        .includes(searchValue.toLowerCase().trim()),
  );

  const filteredContentsCourse = contentsCourse.filter(
    content =>
      content.name &&
      content.name
        .toLowerCase()
        .trim()
        .includes(searchValue.toLowerCase().trim()),
  );

  useEffect(() => {
    (async () => {
      const contentsTrail = await getOngoingTrails();
      const contentsCourse = await getOngoingCourse();
      const allContents = [...contentsTrail, ...contentsCourse];

      if (allContents && allContents.length) {
        setTotalPages(Math.ceil(allContents.length / contentsPerPage));
      } else {
        setShouldShowSkeleton(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setContents([]);

      const currentContents = await getOngoingTrails({
        limit: contentsPerPage,
        offset: page > 1 ? (page - 1) * contentsPerPage : undefined,
      });

      if (currentContents && currentContents.length) {
        setContents(currentContents);
      } else {
        setShouldShowSkeleton(false);
      }
    })();
  }, [page]);

  useEffect(() => {
    if (totalShowing <= contents.length) {
      return setTotalShowing(INITIAL_VALUE_SHOWING + count * VALUE_TO_SHOW);
    } else {
      return setTotalShowing(contents.length);
    }
  }, [count]);

  useEffect(() => {
    (async () => {
      setContentsCourse([]);

      const currentContents = await getOngoingCourse({
        limit: contentsPerPage,
        offset: page > 1 ? (page - 1) * contentsPerPage : undefined,
      });

      if (currentContents && currentContents.length) {
        setContentsCourse(currentContents);
      }
    })();
  }, [page]);

  return (
    <Style.Container>
      {isInWebview && location.pathname !== '/home' ? (
        <>
          <Style.TitleWebView>
            {profileOngoing
              ? dictionary.MY_COURSES.ONGOING_COURSES
              : dictionary.HOME.ONGOING_COURSES}
            <br />
            <span>({contents.length}) Vídeos</span>
          </Style.TitleWebView>
          <Style.StyledInput>
            <span className="iconSliders">
              <Sliders />
            </span>

            <input
              type="text"
              name="search"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              placeholder={dictionary.MY_COURSES.SEARCH_IN_FAVORITES}
              required
            />
            <FiSearch size={20} />
          </Style.StyledInput>
        </>
      ) : null}

      <Style.Header>
        <Style.Title>
          {profileOngoing
            ? dictionary.MY_COURSES.ONGOING_COURSES
            : dictionary.HOME.ONGOING_COURSES}
        </Style.Title>
        <Style.ViewMoreButton
          onClick={() => history.push(`/my-courses/ongoing`)}
        >
          <NotMobile>{dictionary.HOME.SEE_ALL_CONTENTS}</NotMobile>
          {!isInWebview && <Mobile>{dictionary.HOME.SEE_ALL}</Mobile>}
        </Style.ViewMoreButton>
      </Style.Header>

      <Style.ContentList vertical isProfileOngoingCourses={profileOngoing}>
        {filteredContents && filteredContents.length ? (
          filteredContents.map(content => (
            <TrailCard key={content.trail_id} trail={content} />
          ))
        ) : shouldShowSkeleton ? (
          Array(`${contentsPerPage}`)
            .fill('')
            .map((i, index) => <CourseCardSkeleton key={index} />)
        ) : (
          <Style.NotFound>{dictionary.COMMON.NOT_FOUND}</Style.NotFound>
        )}
        {filteredContentsCourse && filteredContentsCourse.length
          ? filteredContentsCourse.map(content => (
              <CardCourseOngoing key={content.content_id} course={content} />
            ))
          : null}
      </Style.ContentList>

      {!searchValue.length && (
        <Style.ButtonWrapper>
          {totalShowing < contents.length && (
            <Style.ShowMoreButton onClick={() => setCount(count + 1)}>
              {dictionary.MY_COURSES.VIEW_MORE}
            </Style.ShowMoreButton>
          )}
        </Style.ButtonWrapper>
      )}

      {totalPages > 1 && (
        <Pagination totalPages={totalPages} setPage={setPage} />
      )}
    </Style.Container>
  );
};

export default OngoingCourses;
