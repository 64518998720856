import styled from 'styled-components';

export const Container = styled.div`
  max-width: 344px;
  width: 100%;
  min-height: 287px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid var(--neutralLight40);
  flex-shrink: 0;
  position: relative;
  /* cursor: pointer; */
  display: flex;
  flex-direction: column;

  /* @media (hover: hover) and (pointer: fine) {
    &:hover {
      border: solid 1px var(--lightBlue);
      box-shadow: 0px 18px 32px -14px #b3bac5;
    }
  } */
`;

export const ImageContainer = styled.div`
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  height: 193px;
  flex: 0 0 auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }
`;
export const Body = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

export const UpperContent = styled.div``;

export const Category = styled.div``;

export const Title = styled.div`
  color: var(--neutralDark900);
  font-size: 2rem;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 16px;
`;

export const LowerContent = styled.div`
  display: flex;
  margin-top: auto;
`;

export const LiveInfo = styled.div`
  color: var(--neutralMid400);
  font-size: 1.4rem;
  font-weight: 400;
  display: flex;
  align-items: center;

  svg {
    color: var(--neutralDark900);

    height: 19px;
    width: 19px;
    margin-right: 6px;
  }

  & + & {
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    margin-left: 16px;
    padding-left: 16px;
  }
`;
