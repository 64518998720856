import styled from 'styled-components';

export const Container = styled.div`
  > div {
    height: 343px;
    width: 100%;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
      min-width: 344px;
    }

    div:nth-child(1) {
      height: 24px;
      width: 30%;
      margin-top: auto;
    }
    div:nth-child(2) {
      height: 48px;
      width: 90%;
      margin-top: 16px;
    }
    div:nth-child(3) {
      height: 32px;
      width: 100%;
      margin-top: 16px;
    }
  }
`;
