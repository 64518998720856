import styled from 'styled-components';

export const Container = styled.div`
  margin: auto;
  max-width: 1424px;
  width: 100%;
  position: relative;
  z-index: 2;

  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;

export const Body = styled.div`
  margin: 46px 0 100px;

  form {
    display: flex;
    flex-direction: column;
    max-width: 910px;
    margin: auto;

    input,
    label,
    textarea {
      font-family: 'Nunito Sans', 'Roboto', sans-serif;
    }

    input,
    textarea {
      font-size: 1.4rem;
      border: 1px solid var(--neutralDark500);
      border-radius: 8px;
      padding: 10px 16px;
      background: rgba(49, 52, 139, 0.08);
    }

    label {
      color: #ffffff;
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 19px;
      margin-bottom: 8px;

      &.disabled {
        color: var(--gray);
      }
    }

    textarea {
      resize: none;
      height: 270px;
      color: #ffffff;
    }

    #email {
      max-width: 675px;
    }

    #subject {
      max-width: 550px;
      color: #ffffff;
    }

    button {
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;
      margin: 38px 0 0 auto;
      padding: 8px 18px;
      background-color: var(--blue);
      color: black;
      border-radius: 18px;
      border: none;
      font-size: 1.4rem;
      font-weight: 700;
      cursor: pointer;

      svg {
        height: 13px;
        width: 13px;
        margin-right: 10px;
      }

      &[disabled] {
        background-color: var(--gray);
        cursor: default;
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          &:not([disabled]) {
            background-color: var(--purple);
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    margin-top: 32px;
  }
`;

export const Header = styled.header`
  margin-bottom: 86px;

  @media (max-width: 768px) {
    margin-bottom: 58px;
  }
`;

export const Title = styled.h1`
  color: #ffffff;
  font-size: 4rem;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 22px;

  @media (max-width: 768px) {
    font-size: 2rem;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 8px;
  }
`;

export const Description = styled.p`
  color: var(--neutralLight40);
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 24px;
  width: 50%;

  @media (max-width: 768px) {
    font-size: 1.4rem;
    line-height: 20px;
    width: 100%;
  }
`;

export const ErrorMessage = styled.div`
  margin-top: 8px;
  margin-bottom: 20px;
  color: var(--red);
  font-size: 1.4rem;
`;
