import { CertificateProps } from '../../services/certificate';
import Template from '../templates';
import { pdf } from '@react-pdf/renderer';

const generateCertificate = (CertificateData: CertificateProps) => {
  const template = <Template data={CertificateData} />;

  return pdf(template).toBlob();
};

export { generateCertificate };
