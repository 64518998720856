import { ReactNode } from 'react';

import { Container } from './styles';

interface StaticAlertProps {
  children: ReactNode;
}

function StaticAlert({ children }: StaticAlertProps) {
  return <Container>{children}</Container>;
}

export default StaticAlert;
