import styled from 'styled-components';

interface BookmarkButtonProps {
  isAddedToList: boolean;
}

export const Container = styled.div`
  margin-bottom: 95px;

  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
`;

export const TopContent = styled.div`
  color: var(--neutralLight40);
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  position: relative;
  z-index: 2;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const CourseAuthor = styled.div`
  display: flex;
  align-items: center;
  margin-right: 60px;

  svg {
    flex-shrink: 0;
    margin-right: 10px;
    fill: var(--neutralDark500);
  }

  @media (max-width: 768px) {
    margin-right: auto;

    svg {
      width: 13px;
      height: 13px;
      fill: var(--neutralDark500);
    }
  }
`;

export const CourseInfo = styled.div`
  display: flex;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const CourseTime = styled.div`
  display: flex;
  align-items: center;

  svg {
    flex-shrink: 0;
    margin-right: 10px;
    fill: var(--neutralDark500);
  }

  @media (max-width: 768px) {
    svg {
      width: 13px;
      height: 13px;
      fill: var(--neutralDark500);
    }
  }
`;

export const BookmarkButton = styled.div<BookmarkButtonProps>`
  border: none;
  cursor: pointer;
  outline: none;
  background: transparent;
  padding: 12px 16px;
  border-radius: 4px;
  color: ${({ isAddedToList }) =>
    isAddedToList ? 'var(--purple)' : 'var(--neutralLight40)'};
  background: ${({ isAddedToList }) =>
    isAddedToList ? 'var(--neutralLight40)' : 'transparent'};
  font-size: 1.4rem;
  font-weight: 500;
  border: ${({ isAddedToList }) =>
    isAddedToList
      ? '1px solid var(--purple)'
      : '1px solid var(--neutralLight40)'};

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-right: 8px;
    fill: ${({ isAddedToList }) =>
      isAddedToList ? 'var(--purple)' : 'var(--neutralLight40)'};
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: var(--neutralLight40);
      color: var(--purple);

      svg path {
        color: white;
        fill: var(--purple);
      }
    }
  }

  @media (max-width: 768px) {
    z-index: 999999;
    position: absolute;
    top: 16px;
    right: 16px;
    padding: 6px;
    border: none;
    background: ${({ isAddedToList }) =>
      isAddedToList ? 'var(--purple)' : '#e5e8eb'};

    svg {
      margin: 0;
    }
  }
`;

export const PlayerContainer = styled.div`
  position: relative;
  iframe {
    width: 100%;
    height: 640px;
    box-sizing: initial;

    @media (max-width: 768px) {
      height: 215px;
    }
  }
`;

export const UnavailableVideo = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 550px) {
    margin: 0 auto;
  }

  &,
  iframe {
    outline: none;
    border: none;
    width: 100%;
    height: 640px;

    @media (max-width: 1300px) {
      height: 394px;
    }

    @media (max-width: 1200px) {
      height: 450px;
    }

    @media (max-width: 800px) {
      height: 300px;
    }

    @media (max-width: 550px) {
      height: 200px;
    }
  }
`;

export const Icon = styled.div`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: calc(50% - 10px);
    left: 0;
    border-top: 15px solid #fff;
    width: 100%;
    transform: rotate(45deg);
  }
`;

export const CourseInfoContainer = styled.div`
  padding: 32px 22px 56px;
  background-color: rgba(12, 12, 51, 0.8);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  @media (max-width: 768px) {
    padding: 24px;
  }
`;

export const CourseSocialStats = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h1`
  margin: 32px 0 20px;
  color: #ffffff;
  font-size: 2.4rem;
  line-height: 32px;
  font-weight: 500;

  @media (max-width: 768px) {
    margin: 24px 0;
    font-size: 2rem;
    line-height: 24px;
    font-weight: 500;
  }
`;

export const TabsContainer = styled.div`
  border-bottom: 1px solid var(--gray50);
  margin-bottom: 22px;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;

  @media (max-width: 768px) {
    justify-content: space-around;
  }
`;

export const TabButton = styled.button`
  border: none;
  outline: none;
  padding: 5px 6px;
  cursor: pointer;
  background-color: transparent;
  color: #ffffff;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 24px;
  position: relative;
  display: flex;
  align-items: center;

  & + & {
    margin-left: 30px;

    @media (max-width: 768px) {
      margin-left: 0px;
    }
  }

  &.active {
    color: var(--blue);
    border-bottom: 4px solid var(--blue);
  }

  &.modules-tab-link {
    display: none;
  }

  &:after {
    content: '';
    width: 0;
    opacity: 0;
    height: 4px;
    bottom: -6px;
    left: 0;
    position: absolute;
    transition: all 0.3s ease-in-out;
    background: var(--purple);
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      &:not(.active) {
        color: var(--purple);
        &:after {
          width: 100%;
          opacity: 1;
        }
      }
    }
  }

  @media (max-width: 768px) {
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.5px;

    &.active {
      border-bottom-width: 2px;
    }
  }
`;

export const TabContents = styled.div`
  @media (max-width: 768px) {
    font-size: 1.4rem;
    line-height: 20px;
  }
`;

export const TabContent = styled.div`
  font-size: 1.6rem;
  line-height: 24px;
  font-weight: 400;
  color: var(--neutralLight40);
`;
