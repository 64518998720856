import { useContext } from 'react';
import DefaultButton from '../Buttons/DefaultButton';
import { AiOutlineStar } from 'react-icons/ai';
import Swal from 'sweetalert2';
import { LanguageContext } from '../../contexts/LanguageContext';
import * as Style from './styles';

const RatingDisabled = () => {
  const { dictionary } = useContext(LanguageContext);

  const onStarClick = () => {
    Swal.fire({
      title: dictionary.MODAL.ERROR,
      text: dictionary.MODAL.RATE.WARN,
    });
  };

  return (
    <Style.RateContainer>
      <Style.RateStars>
        {Array(5)
          .fill('')
          .map((star, index) => (
            <DefaultButton key={index} onClick={onStarClick}>
              <AiOutlineStar color="var(--gray)" size={21} />
            </DefaultButton>
          ))}
      </Style.RateStars>
    </Style.RateContainer>
  );
};

export default RatingDisabled;
