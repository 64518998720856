import React, { useContext, useEffect, useState } from 'react';
import RedCircle from '../../../../assets/svgs/red.svg';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import CourseCard from '../../../../components/CourseCard';
import CourseCardSkeleton from '../../../../components/CourseCardSkeleton';
import Pagination from '../../../../components/Pagination';
import { NotMobile } from '../../../../components/Responsive';

import { getContentCards } from '../../../../services/content';
import ContentCard from '../../../../models/content-card';

import { CategoriesContext } from '../../../../contexts/CategoriesContext';
import { LanguageContext } from '../../../../contexts/LanguageContext';

import { ReactComponent as Ring } from '../../../../assets/svgs/category-ring.svg';

import * as Style from './styles';

const AvailableCourses: React.FC = () => {
  const history = useHistory();
  const { dictionary, language } = useContext(LanguageContext);

  const filterOptions = [
    { label: dictionary.FILTER.ALL, value: {} },
    { label: dictionary.FILTER.MOST_RECENT, value: { most_recent: true } },
    { label: dictionary.FILTER.OLDEST, value: { most_recent: false } },
    { label: dictionary.FILTER.MOST_VIEWED, value: { most_viewed: true } },
  ];
  const { categories } = useContext(CategoriesContext);
  const [availableContents, setAvailableCourses] = useState(
    [] as ContentCard[],
  );
  const [selectedFilter, setSelectedFilter] = useState(filterOptions[0]);
  const [shouldShowSkeleton, setShouldShowSkeleton] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const coursesPerPage = 12;

  const goToChannel = (id: string) => {
    history.push(`/channels/${id}`);
  };

  useEffect(() => {
    (async () => {
      try {
        const contents = await getContentCards();

        if (contents && contents.length) {
          setTotalPages(Math.ceil(contents.length / coursesPerPage));
        } else {
          setShouldShowSkeleton(false);
        }
      } catch (e) {
        toast.error(dictionary.ERROR.LOAD_CONTENTS);
        setShouldShowSkeleton(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setAvailableCourses([]);
        setShouldShowSkeleton(true);
        const currentCourses = await getContentCards({
          ...selectedFilter.value,
          limit: coursesPerPage,
          offset: page > 1 ? (page - 1) * coursesPerPage : undefined,
        });

        if (currentCourses && currentCourses.length) {
          setAvailableCourses(currentCourses);
        } else {
          setShouldShowSkeleton(false);
        }
      } catch (e) {
        toast.error(dictionary.ERROR.LOAD_CONTENTS);
        setShouldShowSkeleton(false);
      }
    })();
  }, [selectedFilter, page]);

  useEffect(() => {
    setSelectedFilter(filterOptions[0]);
  }, [language]);

  return (
    <Style.Container>
      <Style.Header>
        <Style.PageTitle>{dictionary.COURSE.ALL_CONTENTS}</Style.PageTitle>

        <Style.Filter>
          <Select
            options={filterOptions}
            isSearchable={false}
            value={selectedFilter}
            onChange={option => option && setSelectedFilter(option)}
          />
        </Style.Filter>
      </Style.Header>

      <Style.CoursesBody>
        <NotMobile>
          <Style.Categories>
            <Style.CategoriesTitle>
              {dictionary.COMMON.CATEGORIES}
            </Style.CategoriesTitle>
            <Style.CategoriesNav>
              <Style.CategoriesList>
                {categories.length ? (
                  <>
                    {categories.map(category => (
                      <Style.Category
                        key={category.id}
                        onClick={() => goToChannel(category.id)}
                        color={category.info.color}
                      >
                        <Ring />
                        {category.title}
                      </Style.Category>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </Style.CategoriesList>
            </Style.CategoriesNav>
          </Style.Categories>
        </NotMobile>
        <Style.CourseListContainer>
          <Style.CourseList>
            {availableContents && availableContents.length ? (
              availableContents.map(content => (
                <CourseCard content={content} key={content.id} />
              ))
            ) : shouldShowSkeleton ? (
              Array(12)
                .fill('')
                .map((i, index) => <CourseCardSkeleton key={index} />)
            ) : (
              <Style.NotFound>{dictionary.COMMON.NOT_FOUND}</Style.NotFound>
            )}
          </Style.CourseList>
          {totalPages > 1 && (
            <Pagination totalPages={totalPages} setPage={setPage} />
          )}
        </Style.CourseListContainer>
      </Style.CoursesBody>
    </Style.Container>
  );
};

export default AvailableCourses;
