import { useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { AllAnnotation } from '../../../../../../models/all-annotation';
import ContentCard from '../ContentCard';
import Annotation from '../Annotation';
import * as Style from './styles';
import { Mobile, NotMobile } from '../../../../../../components/Responsive';

interface AnnotationCardProps {
  course: AllAnnotation;
  refreshAnnotations: () => void;
}

const AnnotationCard: React.FC<AnnotationCardProps> = ({
  course,
  refreshAnnotations,
}) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <Style.Container isActive={isActive}>
      <Style.Header isActive={isActive} onClick={() => setIsActive(!isActive)}>
        <NotMobile>
          <BsChevronDown size={18} />
        </NotMobile>
        <Style.Info>
          <Style.Category>{course.category}</Style.Category>
          <Style.Title>{course.title}</Style.Title>
        </Style.Info>
        <Mobile>
          <BsChevronDown size={18} color={'var(--lightBlue)'} />
        </Mobile>
      </Style.Header>

      {isActive &&
        (course.annotations.length && !course.contents.length ? (
          <Style.AnnotationList>
            {course.annotations.map(item => (
              <Annotation
                annotation={{ ...(item || {}) }}
                key={item.annotation_id}
                refreshAnnotations={refreshAnnotations}
                trailId={course.trailId}
              />
            ))}
          </Style.AnnotationList>
        ) : (
          <Style.List>
            {course.contents.map(item => (
              <ContentCard
                content={item}
                trailId={course.trailId}
                key={item.content_id}
                refreshAnnotations={refreshAnnotations}
              />
            ))}
          </Style.List>
        ))}
    </Style.Container>
  );
};

export default AnnotationCard;
