import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import { toast } from 'react-toastify';
import { AiOutlinePlayCircle } from 'react-icons/ai';
import { BsBookmark } from 'react-icons/bs';
import { AiOutlineClockCircle, AiOutlineUser } from 'react-icons/ai';

import { secondsToTimeText } from '../../../../helpers/time';

import {
  addContentBookmark,
  removeContentBookmark,
} from '../../../../services/bookmark';
import Content from '../../../../models/content';
import ExtraMaterial from '../../../../models/extra-material';

import { updateContentProgress as updateContentProgressService } from '../../../../services/content';

import PlayerSambaVideos from './components/PlayerSambaVideos';
import CourseRate from './components/CourseRate';
import CourseLike from './components/CourseLike';
import Annotations from './components/Annotations';
import RatingDisabled from '../../../../components/RatingDisabled';
import ExtraMaterials from './components/ExtraMaterials';
import { Mobile, NotMobile } from '../../../../components/Responsive';
import { PlayScorm } from '../../../../components/PlayScorm';

import { LanguageContext } from '../../../../contexts/LanguageContext';
import * as Style from './styles';

interface CourseContentProps {
  content: Content;
  courseHasStarted: boolean;
  getEventListeners: (player: PlayerEventListener) => void;
  progress: number;
  startCourse: () => Promise<void>;
  finishCourse: () => Promise<void>;
}

export interface PlayerEventListener {
  event: string;
  eventParam?: any;
  duration?: any;
}

const CourseContent: React.FC<CourseContentProps> = ({
  content,
  courseHasStarted,
  getEventListeners,
  progress,
  startCourse,
  finishCourse,
}) => {
  const { dictionary } = useContext(LanguageContext);
  const [currentCourse, setCurrentCourse] = useState(content);
  const [isAddedToList, setIsAddedToList] = useState(false);
  const [tabToBeShown, setTabToBeShown] = useState('overview-tab');
  const [shouldShowPlayer, setShouldShowPlayer] = useState(false);
  const [lastWatchedTime, setLastWatchedTime] = useState<number>(0);

  useEffect(() => {
    setCurrentCourse(content);
    setIsAddedToList(content.addedToList || false);
  }, [content]);

  const displayTab = (tabToBeDisplayed: string) => {
    setTabToBeShown(tabToBeDisplayed);
  };

  const addToList = async () => {
    if (content) {
      try {
        if (isAddedToList) {
          await removeContentBookmark(currentCourse.id);
        } else {
          await addContentBookmark(currentCourse.id);
        }
        setIsAddedToList(!isAddedToList);
      } catch (e) {
        toast.error(dictionary.ERROR.BOOKMARK);
      }
    }
  };

  const resumeTime = useMemo(() => {
    if (!currentCourse) {
      return false;
    }
    if (currentCourse.contentView) {
      setLastWatchedTime(currentCourse.contentView);
      return currentCourse.contentView;
    }

    return false;
  }, [currentCourse]);

  const updateContentProgress = useCallback(
    async (time: number | null) => {
      await updateContentProgressService(content.id, time);
    },
    [content.id],
  );

  useEffect(() => {
    setShouldShowPlayer(false);

    process.nextTick(() => {
      const localContentUrl = content.contentUrl;
      const validUrlStart =
        'https://fast.player.liquidplatform.com/pApiv2/embed/';
      const isValidUrl =
        localContentUrl && localContentUrl.startsWith(validUrlStart);
      const isValidUrlScorm =
        localContentUrl && localContentUrl.includes('/scorm/alpargatas');

      if (isValidUrl || isValidUrlScorm) {
        setShouldShowPlayer(true);
      }
    });
  }, [content]);

  useEffect(() => {
    if (progress >= lastWatchedTime + 60) {
      setLastWatchedTime(progress);
      updateContentProgress(progress);
      return;
    }
    if (progress < lastWatchedTime) {
      if (progress > 1) {
        setLastWatchedTime(progress);
        updateContentProgress(progress);
        return;
      }
      setLastWatchedTime(0);
      updateContentProgress(null);
    }
    content.contentView = progress;
  }, [content, lastWatchedTime, progress, updateContentProgress]);

  return (
    <Style.Container>
      <Style.TopContent>
        <Style.CourseInfo>
          <Style.CourseAuthor>
            <AiOutlineUser size={20} color={'#061938'} />
            {dictionary.COMMON.THEME}: {currentCourse.authorName}
          </Style.CourseAuthor>
          <Style.CourseTime>
            <AiOutlineClockCircle size={20} color={'#061938'} />
            {dictionary.COMMON.DURATION}:{' '}
            {secondsToTimeText(currentCourse.duration)}
          </Style.CourseTime>
        </Style.CourseInfo>
        <NotMobile>
          <Style.BookmarkButton
            onClick={addToList}
            isAddedToList={isAddedToList}
          >
            <BsBookmark
              size={20}
              color={`${isAddedToList ? '#FFF' : 'var(--purple)'}`}
            />
            {isAddedToList
              ? dictionary.COMMON.BOOKMARKED
              : dictionary.COMMON.BOOKMARK}
          </Style.BookmarkButton>
        </NotMobile>
      </Style.TopContent>
      <Style.PlayerContainer>
        {shouldShowPlayer ? (
          <>
            {content.type.toUpperCase() === 'SCORM' ? (
              <PlayScorm
                selectedContent={content}
                startCourseOrTrail={startCourse}
                finishCourseOrTrail={finishCourse}
              />
            ) : (
              <PlayerSambaVideos
                contentUrl={content.contentUrl}
                getEventListeners={getEventListeners}
                resume={resumeTime || false}
              />
            )}
            <Mobile>
              <Style.BookmarkButton
                onClick={addToList}
                isAddedToList={isAddedToList}
              >
                <BsBookmark
                  size={20}
                  color={`${isAddedToList ? '#FFF' : 'var(--purple)'}`}
                />
              </Style.BookmarkButton>
            </Mobile>
          </>
        ) : (
          <Style.UnavailableVideo>
            <Style.Icon>
              <AiOutlinePlayCircle color="#FFF" size={200} />
            </Style.Icon>
          </Style.UnavailableVideo>
        )}
      </Style.PlayerContainer>
      <Style.CourseInfoContainer>
        <Style.CourseSocialStats>
          {courseHasStarted ? (
            <CourseRate
              courseId={currentCourse.id}
              stars={currentCourse.stars}
            />
          ) : (
            <RatingDisabled />
          )}
          <CourseLike
            courseId={currentCourse.id}
            numberOfLikes={currentCourse.numberOfLikes}
            userLiked={currentCourse.userLiked}
          />
        </Style.CourseSocialStats>
        <Style.Title>{currentCourse.title}</Style.Title>
        <Style.TabsContainer>
          <Style.TabButton
            type="button"
            className={`
                overview-tab-link
                ${tabToBeShown === 'overview-tab' ? 'active' : ''}
              `}
            onClick={() => {
              displayTab('overview-tab');
            }}
          >
            {dictionary.COURSE.OVERVIEW}
          </Style.TabButton>

          <Style.TabButton
            type="button"
            className={`
                support-material-tab-link
                ${tabToBeShown === 'support-material-tab' ? 'active' : ''}
              `}
            onClick={() => {
              displayTab('support-material-tab');
            }}
          >
            {dictionary.COURSE.EXTRA_MATERIALS.EXTRA_MATERIALS}
          </Style.TabButton>

          <Style.TabButton
            type="button"
            className={`
                    annotations-tab-link
                    ${tabToBeShown === 'annotations-tab' ? 'active' : ''}
                  `}
            onClick={() => {
              displayTab('annotations-tab');
            }}
          >
            {dictionary.COMMON.ANNOTATIONS}
          </Style.TabButton>
        </Style.TabsContainer>
        <Style.TabContents>
          {(() => {
            switch (tabToBeShown) {
              case 'overview-tab':
                return (
                  <Style.TabContent>
                    {currentCourse.description || ''}
                  </Style.TabContent>
                );

              case 'support-material-tab':
                return (
                  <Style.TabContent>
                    <ExtraMaterials
                      extraMaterials={currentCourse.extraMaterials}
                    />
                  </Style.TabContent>
                );

              case 'annotations-tab':
                return (
                  <Style.TabContent>
                    <Annotations contentId={currentCourse.id} />
                  </Style.TabContent>
                );
            }
          })()}
        </Style.TabContents>
      </Style.CourseInfoContainer>
    </Style.Container>
  );
};

export default CourseContent;
